import axios from "axios"
import { defaultHeaders } from "./Constants"

export function post(params, callBack) {
  let defaultHeader = defaultHeaders()
  if (!typeof params === "object") {
    defaultHeader["Content-Type"] = "multipart/form-data"
  }
  let config = { headers: defaultHeader }
  let url = process.env.REACT_APP_API + "baixasProjetos"
  axios
    .post(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getField(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "baixasProjetos/create"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function searchBaixa(searchText, field, onStart, callBack) {
  let config = {
    headers: defaultHeaders(),
    data: { a: "a" }
  }
  let url =
    process.env.REACT_APP_API +
    `search/baixasProjetos?text=${searchText}&fields=${field}`
  if (onStart) {
    onStart()
  }
  return axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function destroy(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "baixasProjetos/" + id
  axios
    .delete(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getBaixas(page, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url =
    process.env.REACT_APP_API +
    `baixasProjetos${page ? `?page=${page}` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function searchBaixas(searchText, field, onStart, callBack, pagina) {
  let config = {
    headers: defaultHeaders(),
    data: { a: "a" }
  }
  let url =
    process.env.REACT_APP_API +
    `search/baixasProjetos?text=${searchText}&fields=${field}`
  if (pagina != null) {
    url += `&page=${pagina}`
  }
  if (onStart) {
    onStart()
  }
  return axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function imprimirDamBaixas(id, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `baixasProjetos/imprimir/${id}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function removeBaixas(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "baixasProjetos/" + id
  axios
    .delete(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function putBaixas(id, params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "baixasProjetos/" + id
  axios
    .put(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function editBaixas(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `baixasProjetos/${id}/edit`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}