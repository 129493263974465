import React from "react"

import { search as searchContribuinte } from "../../../util/contribuinte"
import { showNotification, showWaiting, hideWaiting, mapErrors } from "../../../util/Constants"
import housecall from "housecall"
import Button from "../../../components/CustomButtons/Button"
import { withStyles } from "@material-ui/core"
import contribuinteStyle from "../../../assets/css/layout/contribuinteStyle"
import Grid from "@material-ui/core/Grid"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import SelectCustom from "../../../components/SelectCustom"
import LoadingContent from "../../../components/LoadingContent"
import { getTaxas, getEnquadramentos, relatorioAlvarasContribuintes } from "../../../util/alvara"

class RelatorioAlvarasContribuintes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: {},
            options: true,
            taxa_id: "",
            documento_id: "",
            status_id: "",
            enquadramento_id: "",
            taxas: [],
            documentos: [{ value: "cpf", label: "CPF" }, { value: "cnpj", label: "CNPJ" }],
            status: [{ value: "ativo", label: "Ativo" }, { value: "inativo", label: "Inativo" }],
            enquadramentos: []
        }
        this.handleChange = this.handleChange.bind(this)
        this.loadOptions = this.loadOptions.bind(this)
        this.taxas = this.taxas.bind(this)
        this.enquadramentos = this.enquadramentos.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.queue = housecall({ concurrency: 1, cooldown: 0 })
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        let value = event.target.value
        errors[name] = undefined
        if (name === "options") {
            value = event.target.checked
        }

        this.setState(state => ({
            [name]: value,
            errors: { ...state.errors, [name]: undefined }
        }))
    }

    loadOptions(inputValue, callback) {
        searchContribuinte(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                    callback([])
                } else {
                    let contribuintes = data.data.map(contribuinte => {
                        return {
                            value: contribuinte.id,
                            label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                                ""} - ${contribuinte.cpf_cnpj || ""}`
                        }
                    })

                    this.setState({
                        contribuintes: data.data
                    }, () => {

                    })
                    callback(contribuintes)
                }
            }
        )
    }

    componentWillMount() {
        this.taxas()
    }

    taxas() {
        this.toggleLoading()
        let response = (error, data) => {
            this.toggleLoading()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
                this.setState({
                    errors: mapErrors(error)
                })
            } else {
                this.enquadramentos()
                console.log("data rec ", data)
                let tax = data.map(taxa => {
                    return {
                        value: taxa.id,
                        label: `Nº : ${taxa.id} - ${taxa.descricao}`
                    }
                })
                this.setState({
                    taxas: tax
                }, () => { console.log("taxa: ", this.state.taxas) })
            }
        }
        getTaxas(1, response)
    }

    enquadramentos() {
        this.toggleLoading()
        let response = (error, data) => {
            this.toggleLoading()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
                this.setState({
                    errors: mapErrors(error)
                })
            } else {
                console.log("ENQUADRAMENTOS: ", data)
                let enq = data.map(enquadramento => {
                    return {
                        value: enquadramento.id,
                        label: enquadramento.nome
                    }
                })
                this.setState({
                    enquadramentos: enq
                }, () => { console.log("enquadramento: ", this.state.enquadramentos) })
            }
        }
        getEnquadramentos(response)
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    handleSave(event) {
        event.preventDefault()
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
            } else {
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        relatorioAlvarasContribuintes(
            this.state.taxa_id ? this.state.taxa_id.value : undefined, 
            this.state.documento_id ? this.state.documento_id.value : undefined, 
            this.state.status_id ? this.state.status_id.value : undefined, 
            this.state.enquadramento_id ? this.state.enquadramento_id.value : undefined, 
            response
        )
    }

    render() {
        const { classes } = this.props
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Código do contribuinte" },
            { value: "cpf_cnpj", label: "Documento" },
            { value: "endereco", label: "Endereço" },
            { value: "nome_fantasia", label: "Nome Fantasia" },
            { value: "nome_razao_social", label: "Razão Social" }
        ]
        return (
            <div className={classes.container}>
                <LoadingContent isLoading={this.state.isLoading}>
                    <Card>
                        <CardHeader color="primary">
                            <Grid container direction="row">
                                <Grid item lg={10} xs={12}>
                                    <h2 className={classes.cardTitleWhite}>Relatório de Contribuinte de Alvará</h2>
                                </Grid>
                            </Grid>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <SelectCustom
                                        name="taxa_id"
                                        disabled={!this.state.options}
                                        isMulti={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.handleChange}
                                        error={this.state.errors.taxa_id}
                                        helperText={this.state.errors.taxa_id}
                                        options={this.state.taxas}
                                        placeholder="Taxas"
                                        message="Pesquise a Taxa"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <SelectCustom
                                        name="documento_id"
                                        disabled={!this.state.options}
                                        isMulti={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.handleChange}
                                        error={this.state.errors.documento_id}
                                        helperText={this.state.errors.documento_id}
                                        options={this.state.documentos}
                                        placeholder="Documento *"
                                        message="Pesquise por documento"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <SelectCustom
                                        name="status_id"
                                        disabled={!this.state.options}
                                        isMulti={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.handleChange}
                                        error={this.state.errors.status_id}
                                        helperText={this.state.errors.status_id}
                                        options={this.state.status}
                                        placeholder="Situação *"
                                        message="Escolher situação"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <SelectCustom
                                        name="enquadramento_id"
                                        disabled={!this.state.options}
                                        isMulti={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.handleChange}
                                        error={this.state.errors.enquadramento_id}
                                        helperText={this.state.errors.enquadramento_id}
                                        options={this.state.enquadramentos}
                                        placeholder="Enquadramento"
                                        message="Escolher enquadramento"
                                    />
                                </Grid>
                            </Grid>
                        </CardBody>
                        <CardFooter>
                            <Grid container justify="center">
                                <Grid item lg={2}>
                                    <Button block color="info" round onClick={this.handleSave}>
                                        Gerar
                                </Button>
                                </Grid>
                            </Grid>
                        </CardFooter>
                    </Card>
                </LoadingContent>
            </div>
        )
    }
}

export default withStyles(contribuinteStyle)(RelatorioAlvarasContribuintes)