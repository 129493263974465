import React from "react"
import { withStyles, Paper, MenuItem } from "@material-ui/core"
import { Link, withRouter } from "react-router-dom"
import logradouroStyle from "../../assets/css/layout/logradouroStyle"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import Grid from "@material-ui/core/Grid"
import Table from "../../components/Table/Table"
import TablePagination from "@material-ui/core/TablePagination"
import Search from "../../components/Search"
import Lens from "@material-ui/icons/Lens"
import Edit from "@material-ui/icons/Edit"
import Close from "@material-ui/icons/Close"
import LoadingContent from "../../components/LoadingContent"

import { imprimirDams } from "../../util/dam"
import { getBaixas as get, searchBaixas as search, imprimirDamBaixas as imprimirDam, removeBaixas, putBaixas as put } from "../../util/ocupacao"

import TextField from "@material-ui/core/TextField"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "../../components/CustomButtons/Button"
import {
    showNotification,
    showWaiting,
    hideWaiting
} from "../../util/Constants"
import Print from "@material-ui/icons/Print"
import housecall from "housecall"
import AdBlockDetect from "react-ad-block-detect"
import Dialog from "@material-ui/core/Dialog"
import Typography from "@material-ui/core/Typography"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import InputMask from "react-input-mask"
import numeral from "../../util/numeral"
import moment from "moment"
import AddIcon from "@material-ui/icons/Add"

class BaixaOcupacao extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageCount: 0,
            perPage: 0,
            page: 0,
            dams: [],
            searchText: "",
            showAlterarVencimentoModal: false,
            showModalHistorico: false,
            errors: {},
            modalRemover: false,
            id: 0,
            endereco: "",
            id_tipos_alvara: "",
            tipos_alvara: [],
            ano: "",
            mask: "9999/99",
            control: false
        }
        this.getData = this.getData.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
        this.filter = this.filter.bind(this)
        this.queue = housecall({ concurrency: 1, cooldown: 0 })
        this.setLoadingFalse = this.setLoadingFalse.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleCloseHistorico = this.handleCloseHistorico.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.editDam = this.editDam.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.openRemover = this.openRemover.bind(this)
        this.closeRemover = this.closeRemover.bind(this)
        this.deleteBaixa = this.deleteBaixa.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.handleChangeFilters = this.handleChangeFilters.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
    }

    openRemover(key) {
        this.setState({
            modalRemover: true,
            id: this.state.dams[key].id
        })
    }

    closeRemover() {
        this.setState({
            modalRemover: false
        })
    }

    async deleteBaixa() {
        showWaiting()
        let response = (error) => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    `Ocorreram erros! ${error.response.data}`,
                    "danger"
                )
            } else {
                showNotification(
                    "top",
                    "center",
                    "Baixa removido com sucesso!",
                    "success"
                )
                this.closeRemover()
            }
        }
        await removeBaixas(this.state.id, response)
        this.getData()
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        errors[name] = undefined
        this.setState(
            {
                [name]: event.target.value,
                errors: errors
            },
            () => {
                if (this.state.id_tipos_alvara && name === "id_tipos_alvara") {
                    let tipoAlvara = this.getTaxaById(this.state.id_tipos_alvara)
                    this.setState({
                        taxas: tipoAlvara.taxas
                    })
                }
            }
        )
    }

    editDam() {
        showWaiting()
        let params = {
            historico: this.state.historico_dam_selected,
            observacao: this.state.observacao_dam
        }
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
            } else {
                showNotification(
                    "top",
                    "center",
                    "Histórico atualizado com sucesso!",
                    "success"
                )
                this.handleCloseHistorico()
                this.getData()
            }
        }
        put(this.state.id_dam, params, response)
    }

    handleEdit(key) {
        this.props.history.push("/ocupacao/baixasocupacao/edit/" + (this.state.dams)[key].id)
    }

    handleCloseHistorico() {
        this.setState({
            showModalHistorico: false
        })
    }

    handleChangeFilters(event) {
        this.setState({ filterField: event.target.value }, () => {
            if (this.state.searchActive) {
                this.filter({ target: { value: this.state.searchText } })
            }
        })
    }

    setLoadingFalse() {
        this.setState({
            isLoading: false
        })
    }

    componentDidMount() {
        this.queue.on("idle", this.setLoadingFalse)
    }

    handleChangePage(event, page) {
        let pagina
        pagina = page + 1
        this.setState({ page: page })
        if (page < 0) {
            return
        }
        if (this.state.searchText.toString().trim() != "") {
            this.filter({ target: { value: this.state.searchText } }, pagina, page)
            return
        }
        this.getData(pagina)
    }

    componentWillMount() {
        this.getData()
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    getData(page) {
        this.setState({ isLoading: true })
        get(page, (error, data) => {
            this.setState({ isLoading: false })
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                this.setState({
                    dams: data.data,
                    pageCount: data.total,
                    perPage: data.per_page,

                })
            }
        })
    }

    handleClose() {
        this.setState({
            showAlterarVencimentoModal: false
        })
    }

    imprimir(event) {
        showWaiting()
        event.preventDefault()
        this.setState({
            isLoadingAlvara: true
        })
        let params = {
            tipo_id: this.state.id_tipos_alvara,
            ano: this.state.ano,
            endereco: this.state.endereco
        }
        let response = (error, data) => {
            this.setState({
                isLoadingAlvara: false
            })
            if (error) {
                hideWaiting()
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
                this.setState({
                    errors: error,
                })
            } else {
                hideWaiting()
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        imprimirDams(params, response)
    }

    imprimirDam(key) {
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                const reader = new FileReader()
                reader.addEventListener("loadend", e => {
                    const text = e.srcElement.result
                    let error = JSON.parse(text)
                    let status = error.status
                    if (status === "vencido") {
                        this.setState({
                            showAlterarVencimentoModal: true,
                            damIndex: key
                        })
                    }
                    showNotification(
                        "top",
                        "center",
                        `Ocorreram erros! ${error.message}`,
                        "danger"
                    )
                })
                reader.readAsText(error.response.data)

            } else {
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        var dam = this.state.dams[key]
        imprimirDam(dam.id, response)
    }

    filter(event, pagina) {
        let searchText = event.target.value
        let newState = {
            isLoading: true,
            searchText: searchText,
            searchActive: searchText !== ""
        }
        if (pagina == null) {
            newState["page"] = 0
        }
        this.setState(newState
            ,
            () => {
                if (this.state.searchActive) {

                    let filtersCheckbox = this.state.filterField || ""

                    search(searchText, filtersCheckbox, undefined, (error, data) => {
                        this.setState({ isLoading: false })
                        if (error) {
                            showNotification(
                                "top",
                                "center",
                                "Ocorreram erros, verifique sua conexão com a internet",
                                "danger"
                            )
                        } else {
                            if (this.state.searchText) {
                                this.setState({
                                    dams: data.data,
                                    pageCount: data.total,
                                    perPage: data.per_page,
                                })
                            }
                        }
                    }, pagina)

                } else {
                    this.getData()
                }
            }
        )
    }

    toggleModal(name, status) {
        this.setState(
            state => ({
                [name]: !state[name]
            }),
            () => {
                if (status === "success") {
                    let damID = this.state.dam_id
                    this.state.dams.every((dam) => {
                        if (dam.id === damID) {
                            return false
                        }
                        return true
                    })
                    this.imprimir()
                }
            }
        )
    }

    getTaxaById(id) {
        return this.state.tipos_alvara.find(item => {
            return id === item.id
        })
    }

    render() {
        const { classes } = this.props
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "ID da Baixa" },
            { value: "dam_id", label: "Código do DAM" },
        ]
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>Baixas de Ocupação</h2>
                            </Grid>
                            <Grid item lg={2} xs={12}>
                                <Link
                                    to="/ocupacao/baixasocupacao/create"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button block color="success" round>
                                        <AddIcon /> Adicionar
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Search
                            label="Pesquise aqui"
                            value={this.state.filterField}
                            onChangeText={this.filter}
                            onChangeFields={this.handleChangeFilters}
                            fields={fields}
                        />
                        <LoadingContent isLoading={this.state.isLoading}>
                            <Paper className={classes.tableWrapper}>
                                <Table
                                    getRowColor={(key, classes) => {
                                        if (this.state.dams[key].lancado_na_divida) {
                                            return classes.dangerTableRow
                                        } else if (this.state.dams[key].pago) {
                                            return classes.successTableRow
                                        } else {
                                            return ""
                                        }
                                    }}
                                    tableHeaderColor="primary"
                                    dams={this.state.dams}
                                    filter={true}
                                    actions={[
                                        {
                                            id: 1,
                                            title: "Editar",
                                            icon: (
                                                <Edit
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: this.handleEdit
                                        },
                                        {
                                            id: 2,
                                            title: "Imprimir",
                                            icon: (
                                                <Print
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: key => this.imprimirDam(key)
                                        },
                                        {
                                            id: 3,
                                            title: "Remover",
                                            icon: (
                                                <Close
                                                    className={
                                                        classes.tableActionButtonIcon +
                                                        " " +
                                                        classes.close
                                                    }
                                                />
                                            ),
                                            onClick: this.openRemover
                                        }
                                    ]}
                                    tableHead={[
                                        "ID da Baixa",
                                        "Código do dam",
                                        "Tipo da Baixa",
                                        "Valor Pago",
                                        "Data do Pagamento",
                                        "Data do Depósito"
                                    ]}
                                    tableData={this.state.dams.map((item, key) => {
                                        return [
                                            item.id + "",
                                            item.dam_id,
                                            item.tipo_de_baixa === "M" ? "Manual" : "Automática",
                                            `R$ ${numeral(parseFloat(item.valor_pago)).format(
                                                "0.0,00"
                                            )}`.slice(0, -2),
                                            item.data_pagamento ? moment(item.data_pagamento).format('DD/MM/YYYY') : '',
                                            item.data_deposito ? moment(item.data_deposito).format('DD/MM/YYYY') : '',
                                            key
                                        ]
                                    })}
                                />
                                {(
                                    <TablePagination
                                        component="div"
                                        handlePageClick={this.handlePageClick}
                                        rowsPerPageOptions={[]}
                                        count={this.state.pageCount}
                                        rowsPerPage={this.state.perPage ? this.state.perPage : 0}
                                        page={this.state.page ? this.state.page : 0}
                                        backIconButtonProps={{
                                            "aria-label": "Previous Page"
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "Next Page"
                                        }}
                                        SelectProps={{
                                            native: true
                                        }}
                                        labelRowsPerPage="Quantidade por página"
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                )}
                            </Paper>
                        </LoadingContent>
                    </CardBody>
                </Card>
                <Dialog
                    open={this.state.showModal || false}
                    onClose={e => this.toggleModal("showModal")}
                    fullWidth
                    maxWidth="lg"
                    aria-labelledby="form-dialog-title"
                >
                    <form >
                        <DialogTitle id="form-dialog-title">
                            Selecione as opções de filtro ou vazio para TODOS
                        </DialogTitle>
                        <LoadingContent isLoading={this.state.isLoadingAlvara}>
                            <DialogContent>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Typography color="textSecondary" variant="caption">
                                            Selecione as opções de filtro ou vazio para TODOS
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} sm={12} md={8} lg={8}>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            required
                                            name="id_tipos_alvara"
                                            label="Tipos de Alvará"
                                            fullWidth
                                            value={this.state.id_tipos_alvara}
                                            onChange={this.handleChange}
                                            error={this.state.errors.id_tipos_alvara}
                                            helperText={this.state.errors.id_tipos_alvara}
                                            margin="normal"
                                            variant="outlined"
                                        >
                                            {this.state.tipos_alvara.map((option, key) => (
                                                <MenuItem key={key} value={option.id}>
                                                    {option.descricao}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                        <InputMask
                                            mask="9999"
                                            value={this.state.ano}
                                            onChange={this.handleChange}
                                        >
                                            {() => (
                                                <TextField
                                                    id="outlined-name"
                                                    required
                                                    label="Ano"
                                                    name="ano"
                                                    helperText={this.state.errors.ano}
                                                    fullWidth
                                                    error={this.state.errors.ano}
                                                    value={this.state.ano}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            )}
                                        </InputMask>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="endereco"
                                        label="Rua"
                                        required
                                        fullWidth
                                        error={this.state.errors.endereco}
                                        helperText={this.state.errors.endereco}
                                        rowsMax="3"
                                        value={this.state.endereco || ""}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                            </DialogContent>
                        </LoadingContent>
                        <DialogActions>
                            <Button
                                onClick={e => this.toggleModal("showModal")}
                                color="danger"
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" color="success" onClick={e => this.imprimir(e)}>
                                Gerar
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
                <AdBlockDetect>
                    <Dialog open={true} aria-labelledby="form-dialog-title">
                        <DialogContent>
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                                direction="row"
                            >
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <Typography variant="h5" gutterBottom>
                                        Por favor, desabilite o ADBlock para geração de baixa.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </AdBlockDetect>

                {/* DIALOG HISTORICO DAM */}
                <Dialog
                    id="body"
                    open={this.state.showModalHistorico}
                    maxWidth="md"
                    fullWidth
                    titleStyle={{ textAlign: "center" }}
                    onClose={this.handleCloseHistorico}
                    aria-labelledby="form-dialog-title"
                    style={{ zIndex: "4" }}
                >
                    <DialogTitle id="form-dialog-title">
                        <Typography variant="h5" align="center">
                            Editar DAM
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" spacing={16} justify="center">
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="outlined-select-currency"
                                    required
                                    name="historico_dam_selected"
                                    label="Histórico"
                                    fullWidth
                                    multiline
                                    rowsMax="3"
                                    error={this.state.errors.historico}
                                    helperText={this.state.errors.historico}
                                    value={this.state.historico_dam_selected}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={16} justify="center">
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="outlined-select-currency"
                                    required
                                    name="observacao_dam"
                                    label="Observação"
                                    fullWidth
                                    multiline
                                    rowsMax="3"
                                    error={this.state.errors.observacao}
                                    helperText={this.state.errors.observacao}
                                    value={this.state.observacao_dam}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseHistorico} color="danger">
                            Cancelar
                        </Button>
                        <Button onClick={this.editDam} color="success">
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* dialog confirmacao */}
                <Dialog
                    open={this.state.modalRemover}
                    onClose={this.closeRemover}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: "3" }}
                >
                    <DialogTitle id="alert-dialog-title">Você tem certeza que deseja remover essa Baixa?</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.closeRemover} color="danger">
                            Cancelar
                        </Button>
                        <Button onClick={this.deleteBaixa} color="success">
                            Remover
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
BaixaOcupacao = withRouter(BaixaOcupacao)
export default withStyles(logradouroStyle)(BaixaOcupacao)
