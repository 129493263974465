import React from "react";
import Warning from "@material-ui/icons/Warning";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
import Button from "../../../components/CustomButtons/Button";
import secaoStyle from "../../../assets/css/layout/secaoStyle";
import { withStyles, MenuItem, Paper } from "@material-ui/core";
import {
  post,
  getField,
  edit,
  put,
  imprimirRefinanciamento,
  imprimirCarne,
  postSimular,
} from "../../../util/refinanciamento";
import {
  showNotification,
  mapErrors,
  showWaiting,
  hideWaiting,
} from "../../../util/Constants";
import { getDividaEmAbertoComFiltros } from "../../../util/dividas";
import LoadingContent from "../../../components/LoadingContent";
import { simpleSearch as searchContribuinte } from "../../../util/contribuinte";
import AsyncSelect from "../../../components/AsyncSelect";
import SearchFields from "../../../components/SearchFields";
import housecall from "housecall";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { contribuinteChanged } from "../../../actions";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import numeral from "../../../util/numeral";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "../../../components/Table/Table";

class RefinanciamentoForm extends React.Component {
  constructor(props) {
    super(props);
    let contribuinte = props.contribuinte;
    let contribuinteState = { contribuintes: [] };
    if (contribuinte) {
      contribuinteState = {
        contribuintes: [props.contribuinte],
        contribuinte_id: {
          value: props.contribuinte.id,
          label: `${props.contribuinte.id} - ${props.contribuinte
            .nome_razao_social || ""} - ${props.contribuinte.cpf_cnpj || ""}`,
          //tipo: contribuinte.tipo_de_documento_id,
        },
      };
    }
    this.state = {
      errors: {},
      data_inicio: new Date(),
      data_fim: new Date(),
      valor_minimo_fisica: "",
      valor_minimo_juridica: "",
      data_primeiro_vencimento: new Date(),
      lei: "",
      modal: false,
      abragencias: [],
      id_abragencias: [],
      id_refis: "",
      refis: [],
      dividas: [],
      id_divida: 0,
      parcela_valor_minimo: 0,
      quantidade_de_parcelas: "",
      historico: "",
      editingItem: 0,
      isLoadingDialog: false,
      showModal: false,
      parcelasRef: [],
      ...contribuinteState,
      search: {},
    };
    this.init = this.init.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.simulacao = this.simulacao.bind(this);
    this.getDividaContribuinte = this.getDividaContribuinte.bind(this);
    this.imprimirRefinanc = this.imprimirRefinanc.bind(this);
    this.imprimirCarneRef = this.imprimirCarneRef.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.queue = housecall({ concurrency: 1, cooldown: 0 });
  }

  componentWillMount() {
    this.init();
  }

  handleClose() {
    this.setState({ modal: false });
  }

  componentDidMount() {
    getField((error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        );
      } else {
        if (data.refis.length > 0) {
          this.setState({
            showWarningModal: true,
            data_primeiro_vencimento: this.state.data_primeiro_vencimento.setDate(
              this.state.data_primeiro_vencimento.getDate() + 5
            ),
            canShow: true,
          });
        } else {
          this.setState({
            canShow: true,
          });
        }
      }
    });
    if (this.state.contribuinte_id) {
      this.getDividas();
    }
  }

  getDividas() {
    showWaiting();
    let arrayParams = new Array();
    Object.entries({ ...this.state.search }).forEach(([key, value]) => {
      arrayParams.push(`${key}=${value}`);
    });
    const params = `?${arrayParams.join("&")}`;
    const response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        );
      } else {
        this.setState({
          dividas: data.map((item) => {
            return { ...item, checkbox: true };
          }),
          selectAll: true,
          nao_tem_divida: data.length === 0,
        });
      }
      hideWaiting();
    };
    if (!this.state.contribuinte_id) {
      showNotification(
        "top",
        "center",
        "Contribuinte é obrigatório",
        "warning"
      );
      hideWaiting();
    } else {
      getDividaEmAbertoComFiltros(
        this.state.contribuinte_id.value,
        params,
        response
      );
    }
  }
  simulacao(event) {
    event.preventDefault();
    showWaiting();
    let filter = this.state.dividas.filter((item) => {
      return item.checkbox === true;
    });
    let dividas = filter.map((item) => {
      return item.id;
    });

    let parcela_valor_minimo = 0;
    let refis = this.state.refis.find(
      (item) => item.id === this.state.id_refis
    );
    /*if (this.state.contribuinte_id.tipo >= 2) {
      parcela_valor_minimo = Number(refis.valor_minimo_de_parcela_pf);
      console.log("Valor PF");
    }
    if (this.state.contribuinte_id.tipo === 1) {
      parcela_valor_minimo = Number(refis.valor_minimo_de_parcela_pj);
      console.log("Valor PJ");
    }*/
    let params = {
      dividas: dividas,
      contribuinte_id: this.state.contribuinte_id.value,
      data_primeiro_vencimento: moment(
        this.state.data_primeiro_vencimento
      ).format("YYYY-MM-DD"),
      historico: this.state.historico,
      quantidade_de_parcelas: this.state.quantidade_de_parcelas,
      refis_id: this.state.id_refis,
      parcela_valor_minimo,
    };
    let response = (error, data) => {
      hideWaiting();
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        );
        this.setState({
          errors: mapErrors(error),
        });
      } else {
        this.setState({
          modal: true,
          parcelasRef: data,
          errors: {},
        });
      }
    };
    postSimular(params, response);
  }

  loadOptions(inputValue, callback) {
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          );
          callback([]);
        } else {
          let contribuintes = data.data.map((contribuinte) => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - ${contribuinte.cpf_cnpj || ""}`,
              tipo: contribuinte.tipo_de_documento_id,
            };
          });
          this.setState({
            contribuintes: data.data,
          });
          callback(contribuintes);
        }
      }
    );
  }
  imprimirCarneRef() {
    showWaiting();
    let response = (error, data) => {
      if (error) {
        hideWaiting();
        const reader = new FileReader();
        reader.addEventListener("loadend", (e) => {
          const text = e.srcElement.result;
          try {
            let error = JSON.parse(text);

            let status = error.status;
            if (status === "pago") {
              showNotification(
                "top",
                "center",
                `Ocorreram erros! ${error.message}!`,
                "danger"
              );
            }
          } catch (e) {
            showNotification(
              "top",
              "center",
              `Ocorreram erros! ${error.message}`,
              "danger"
            );
          }
        });

        reader.readAsText(error.response.data);
      } else {
        hideWaiting();
        var url = URL.createObjectURL(data);
        let tempLink = document.createElement("a");
        tempLink.setAttribute("target", "_blank");
        tempLink.setAttribute("href", `${url}`);
        tempLink.setAttribute("style", "display:none;");
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      }
    };
    imprimirCarne(this.state.refinanciamento_id, response);
  }

  imprimirRefinanc() {
    showWaiting();
    let response = (error, data) => {
      if (error) {
        hideWaiting();
        const reader = new FileReader();
        reader.addEventListener("loadend", (e) => {
          const text = e.srcElement.result;
          try {
            let error = JSON.parse(text);

            let status = error.status;
            if (status === "pago") {
              showNotification(
                "top",
                "center",
                `Ocorreram erros! ${error.message}!`,
                "danger"
              );
            }
          } catch (e) {
            showNotification(
              "top",
              "center",
              `Ocorreram erros! ${error.message}`,
              "danger"
            );
          }
        });

        reader.readAsText(error.response.data);
      } else {
        hideWaiting();
        var url = URL.createObjectURL(data);
        let tempLink = document.createElement("a");
        tempLink.setAttribute("target", "_blank");
        tempLink.setAttribute("href", `${url}`);
        tempLink.setAttribute("style", "display:none;");
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      }
    };
    imprimirRefinanciamento(this.state.refinanciamento_id, response);
  }

  componentWillUnmount() {
    this.props.contribuinteChanged(undefined);
  }

  handleChange(event) {
    let name = event.target.name;
    let errors = this.state.errors;
    let value = event.target.value;

    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }

    if (name.split(".").length > 1) {
      let oldinfo = [...this.state.dividas];
      let components = name.split(".");
      name = components[0];
      oldinfo[components[1]][components[2]] = value;
      value = oldinfo;
    }

    this.setState(
      {
        [name]: event.target.value,
        errors: errors,
      },
      () => {
        if (this.state.contribuinte_id && name === "contribuinte_id") {
          this.getDividas();
          // let d = this.getDividaContribuinte(this.state.contribuinte_id.value)
          // let divida = d.dividas.map(item => {
          //   let item_divida = {
          //     ...item
          //   }

          //   return item_divida
          // })
          // this.setState({
          //   dividas: divida
          // })
        }
      }
    );
  }

  getDividaContribuinte(id) {
    return this.state.contribuintes.find((item) => {
      return id === item.id;
    });
  }

  handleSave(event) {
    event.preventDefault();
    showWaiting();
    let filter = this.state.dividas.filter((item) => {
      return item.checkbox === true;
    });
    let dividas = filter.map((item) => {
      return item.id;
    });

    let parcela_valor_minimo = 0;
    let refis = this.state.refis.find(
      (item) => item.id === this.state.id_refis
    );
    if (this.state.contribuinte_id.tipo >= 2) {
      parcela_valor_minimo = Number(refis.valor_minimo_de_parcela_pf);
    }
    if (this.state.contribuinte_id.tipo === 1) {
      parcela_valor_minimo = Number(refis.valor_minimo_de_parcela_pj);
    }

    let params = {
      dividas: dividas,
      contribuinte_id: this.state.contribuinte_id.value,
      data_primeiro_vencimento: moment(
        this.state.data_primeiro_vencimento
      ).format("YYYY-MM-DD"),
      historico: this.state.historico,
      quantidade_de_parcelas: this.state.quantidade_de_parcelas,
      refis_id: this.state.id_refis,
      parcela_valor_minimo,
    };
    let response = (error, data) => {
      if (error) {
        hideWaiting();
        var message = "Ocorreram erros, verifique o formulário e tente novamente";
        if (error) {
          this.setState({
            errors: mapErrors(error),
          });
        }
        showNotification("top", "center", message, "danger");
      } else {
        hideWaiting();
        showNotification(
          "top",
          "center",
          `Refinanciamento ${
            this.props.match.params.id ? "editado" : "concluído"
          } com sucesso`,
          "success"
        );
        this.setState({
          refinanciamento_id: data.id,
          showModal: true,
        });
      }
    };
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response);
  }
  toggleLoading() {
    this.setState((state) => ({
      isLoading: !state.isLoading,
    }));
  }
  init() {
    this.toggleLoading();
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        );
      } else {
        if (data.refis.length === 0) {
          showNotification(
            "top",
            "center",
            "Não existe REFIS Vigente. Você foi redirecionado à tela de pagamento normal!",
            "warning"
          );
          this.props.history.push("/divida/pagamento/create");
        } else {
          let newState = {
            refis: data.refis,
          };
          if (data.refis.length === 1) {
            newState.id_refis = data.refis[0].id;
          }
          this.setState(newState);
          this.toggleLoading();
        }
      }
    };
    if (this.props.match.params.id) {
      edit(this.props.match.params.id, response);
    } else {
      getField(response);
    }
  }
  closeModal() {
    this.setState({
      showModal: false,
    });
  }

  render() {
    const { classes } = this.props;
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do contribuinte" },
      { value: "cpf_cnpj", label: "Documento" },
      { value: "endereco", label: "Endereço" },
      { value: "nome_fantasia", label: "Nome Fantasia" },
      { value: "nome_razao_social", label: "Razão Social" },
    ];
    return (
      <div className={classes.container}>
        <LoadingContent isLoading={this.state.isLoading}>
          <div>
            <Card>
              <form onSubmit={this.handleSave}>
                <CardHeader color="primary">
                  <Grid container direction="row">
                    <Grid item lg={10} xs={12}>
                      <h2 className={classes.cardTitleWhite}>
                        Cadastro de Refinanciamento
                      </h2>
                    </Grid>
                  </Grid>
                </CardHeader>
                <CardBody>
                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <SearchFields
                        name="filterField"
                        value={this.state.filterField || ""}
                        onChangeFields={this.handleChange}
                        fields={fields}
                      />
                      <Typography color="textSecondary" variant="caption">
                        Pesquise o Contribuinte
                      </Typography>
                      <AsyncSelect
                        className={`form-control form-control-alternative form-select Select-menu-outer ${
                          this.props.error ? "has-danger" : ""
                        }`}
                        value={this.state.contribuinte_id}
                        onChange={this.handleChange}
                        loadOptions={this.loadOptions}
                        error={this.state.errors.contribuinte_id}
                        helperText={this.state.errors.contribuinte_id}
                        name="contribuinte_id"
                        placeholder="Contribuinte"
                        message="Pesquise o contribuinte"
                      />
                    </Grid>
                  </Grid>

                  {this.state.dividas.length > 0
                    ? this.state.dividas.map((item, key) => {
                        return (
                          <Card
                            style={{ marginBottom: "10px", marginTop: "10px" }}
                          >
                            <Grid
                              style={{ padding: 20 }}
                              container
                              direction="row"
                              justify="flex-center"
                              alignItems="center"
                              spacing={8}
                              key={key}
                            >
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <strong>Receita: </strong>
                                <span>
                                  {item.receita_id} - {item.receita.nome}{" "}
                                </span>
                                <br />
                                <strong>Competência: </strong>
                                <span>{item.competencia} </span> <br />
                                <strong>Código de origem: </strong>
                                <span>{item.codigo_de_origem} </span>
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <strong>Valor original: </strong>
                                <span>
                                  {"R$" +
                                    numeral(parseFloat(item.valor))
                                      .format("0.0,00")
                                      .slice(0, -2)}
                                </span>
                                <br />
                                <strong>Valor pago: </strong>
                                <span>
                                  {"R$" +
                                    numeral(parseFloat(item.valor_pago))
                                      .format("0.0,00")
                                      .slice(0, -2)}
                                </span>
                                <br />
                                <strong>Saldo devedor: </strong>
                                <span>
                                  {"R$" +
                                    numeral(parseFloat(item.saldo_devedor))
                                      .format("0.0,00")
                                      .slice(0, -2)}
                                </span>
                                <br />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <strong>Correção monetária: </strong>
                                <span>
                                  {"R$" +
                                    numeral(parseFloat(item.correcao_monetaria))
                                      .format("0.0,00")
                                      .slice(0, -2)}
                                </span>
                                <br />
                                <strong>Multa: </strong>
                                <span>
                                  {"R$" +
                                    numeral(parseFloat(item.multa))
                                      .format("0.0,00")
                                      .slice(0, -2)}
                                </span>
                                <br />
                                <strong>Juros: </strong>
                                <span>
                                  {"R$" +
                                    numeral(parseFloat(item.juros))
                                      .format("0.0,00")
                                      .slice(0, -2)}
                                </span>
                                <br />
                              </Grid>

                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <h3>
                                  Total a pagar:{" "}
                                  {"R$" +
                                    numeral(
                                      parseFloat(item.saldo_devedor) +
                                        parseFloat(item.correcao_monetaria) +
                                        parseFloat(item.multa) +
                                        parseFloat(item.juros)
                                    )
                                      .format("0.0,00")
                                      .slice(0, -2)}
                                </h3>
                              </Grid>
                            </Grid>
                          </Card>
                        );
                      })
                    : this.state.contribuinte_id && (
                        <Grid
                          className={classes.legend}
                          container
                          direction="row"
                        >
                          <Warning
                            className={classes.dot + " " + classes.dotDanger}
                          />
                          Este contribuinte não possui dívida ativa em aberto
                        </Grid>
                      )}
                  {this.state.dividas.length > 0 && (
                    <div>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            name="id_refis"
                            label="REFIS"
                            fullWidth
                            value={this.state.id_refis}
                            error={this.state.errors.refis_id}
                            helperText={this.state.errors.refis_id}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.refis.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.lei}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="date"
                            type="number"
                            variant="outlined"
                            margin="normal"
                            name="quantidade_de_parcelas"
                            label="Quantidade de parcelas"
                            className={classes.datePicker}
                            helperText={
                              this.state.errors.quantidade_de_parcelas ||
                              this.state.errors.parcela_valor_minimo
                            }
                            value={this.state.qtd_parcelas}
                            error={
                              this.state.errors.quantidade_de_parcelas ||
                              this.state.errors.parcela_valor_minimo
                            }
                            onChange={this.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="date"
                            type="date"
                            variant="outlined"
                            margin="normal"
                            name="data_primeiro_vencimento"
                            label="Data do primeiro Vencimento"
                            className={classes.datePicker}
                            helperText={
                              this.state.errors.data_primeiro_vencimento
                            }
                            value={moment(
                              this.state.data_primeiro_vencimento
                            ).format("YYYY-MM-DD")}
                            error={this.state.errors.data_primeiro_vencimento}
                            defaultValue={moment(
                              this.state.data_primeiro_vencimento
                            ).format("YYYY-MM-DD")}
                            onChange={this.handleChange}
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            id="outlined-select-currency"
                            label="Histórico"
                            fullWidth
                            required
                            value={this.state.historico}
                            onChange={this.handleChange}
                            name="historico"
                            multiline
                            rowsMax="5"
                            error={this.state.errors.historico}
                            helperText={this.state.errors.historico}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </CardBody>
                <CardFooter>
                  <Grid container justify="center" spacing={8}>
                    <Grid item lg={2}>
                      <Button
                        block
                        color="info"
                        round
                        onClick={this.handleSave}
                        disabled={!this.state.contribuinte_id}
                      >
                        Confirmar
                      </Button>
                    </Grid>
                    <Grid item lg={2}>
                      <Button
                        block
                        color="default"
                        round
                        onClick={this.simulacao}
                        disabled={!(this.state.dividas.length > 0)}
                      >
                        Simular
                      </Button>
                    </Grid>
                  </Grid>
                </CardFooter>
              </form>
            </Card>
          </div>
        </LoadingContent>
        <Dialog
          id="body"
          open={this.state.showModal}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          style={{ zIndex: "901" }}
        >
          <DialogTitle id="form-dialog-title">
            Refinanciamento cadastrado com sucesso!
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={16} justify="center">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Button
                  onClick={this.imprimirCarneRef}
                  color="success"
                  fullWidth
                >
                  Imprimir Termo
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Button onClick={this.imprimirRefinanc} color="Info" fullWidth>
                  Imprimir Carnê
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={16} justify="center">
              <Button onClick={this.closeModal} color="danger" fullWidth>
                Fechar
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="lg"
          style={{ zIndex: "901" }}
        >
          <DialogTitle id="form-dialog-title">
            Simulação de Parcelas
          </DialogTitle>
          <DialogContent>
            <Paper className={classes.tableWrapper}>
              <Table
                tableHeaderColor="primary"
                fullWidth
                actions={[]}
                tableHead={[
                  "Nº Parcela",
                  "Vencimento",
                  "Valor",
                  "Juros Adicionais",
                  "Total",
                ]}
                tableData={this.state.parcelasRef.map((item) => {
                  return [
                    item.numero_da_parcela,
                    moment(item.vencimento).format("DD/MM/YYYY"),
                    `R$ ${numeral(parseFloat(item.valor))
                      .format("0.0,00")
                      .slice(0, -2)}`,
                    `R$ ${numeral(parseFloat(item.juros_adicionais))
                      .format("0.0,00")
                      .slice(0, -2)}`,
                    `R$ ${numeral(
                      parseFloat(item.juros_adicionais) + parseFloat(item.valor)
                    )
                      .format("0.0,00")
                      .slice(0, -2)}`,
                  ];
                })}
              />
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="danger">
              Voltar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ contribuinteChanged }, dispatch);
}
function mapStateToProps(state) {
  return {
    contribuinte: state.contribuinte.contribuinte,
  };
}
RefinanciamentoForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(RefinanciamentoForm);
export default withStyles(secaoStyle)(RefinanciamentoForm);
