import React from "react"
import { withRouter } from "react-router-dom"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"
import Warning from "@material-ui/icons/Warning"

import AsyncSelect from "../../components/AsyncSelect"
import { search as searchDamAvulso, imprimir } from "../../util/damavulso"
import { showNotification, mapErrors } from "../../util/Constants"
import numeral from "../../util/numeral"
import { withStyles } from "@material-ui/core"
import Card from "../../components/Card/Card"
import CardBody from "../../components/Card/CardBody"
import secaoStyle from "../../assets/css/layout/secaoStyle"
import Button from "../../components/CustomButtons/Button"
import { showWaiting, hideWaiting } from "../../util/Constants"
import { alterarVencimento } from "../../util/damavulso"
import housecall from "housecall"

import moment from "moment"
import CardFooter from "../../components/Card/CardFooter"
import SearchFields from "../../components/SearchFields"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog"
import Checkbox from "@material-ui/core/Checkbox";
import { me } from "../../util/authApi";


class AlterarVencimentoAvulso extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      alvaras: [],
      id_alvara: 0,
      errors: {},
      dams: [],
      id_dam: "",
      descricao: "",
      cidade: "",
      bairro: "",
      valor: "",
      juros: "",
      multa: "",
      data_vencimentoAntiga: "",
      data_vencimentoNova: moment(new Date()).format("YYYY-MM-DD"),
      modalImprimir: false
    }
    this.loadDam = this.loadDam.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getDamById = this.getDamById.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
    this.imprimirDam = this.imprimirDam.bind(this)
    this.openModal = this.openModal.bind(this)
    this.close = this.close.bind(this)
    this.onClickMulta = this.onClickMulta.bind(this)
    this.onClickJuros = this.onClickJuros.bind(this)
  }


  componentDidMount() {
    me((error, data) => {
        var podeAlterar = false;
        if (data) {
            if ([1, 3, 9, 19].includes(data.id)) {
                podeAlterar = true
            }
        }
        this.setState({podeAlterarMultaOuJuros: podeAlterar})
    })
  }

  onClickMulta = () => {
    this.setState({sem_multa: !this.state.sem_multa})
  }
  onClickJuros = () => {
    this.setState({sem_juros: !this.state.sem_juros})
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState(
      {
        [name]: event.target.value,
        errors: errors
      },
      () => {
        if (this.state.id_dam && name === "id_dam") {
          let dam = this.getDamById(this.state.id_dam.value)
          this.setState({
            valor: dam.valor,
            juros: dam.juros,
            multa: dam.multa,
            descricao: dam.historico,
            cidade: dam.contribuinte.cidade,
            bairro: dam.contribuinte.bairro,
            data_vencimentoAntiga: dam.vencimento,
            lancadoNaDivida: dam.lancado_na_divida,
            divida_id: dam.divida_id,
            pago: dam.pago === true,
          })
        }
      }
    )
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        showNotification(
          "top",
          "center",
          `Data de vencimento alterada realizada com sucesso`,
          "success"
        )
        this.openModal()
        //this.props.history.push("/damavulso/cadastro")
      }
    }
    alterarVencimento(
      this.state.id_dam.value,
      this.state.data_vencimentoNova,
      this.state.sem_multa,
      this.state.sem_juros,
      response
    )
  }

  openModal() {
    this.setState({
      modalImprimir: true
    })
  }

  close() {
    this.setState({
      modalImprimir: false
    }, () => this.props.history.push("/damavulso/cadastro"))
  }

  loadDam(inputValue, callback) {
    // this.queue.push(() =>
    searchDamAvulso(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let filterDam = data.data.filter(d => {
            return d.pago === false
          })
          let dams = filterDam.map(dam => {
            return {
              value: dam.id,
              label: `Insc.:${dam.id} - Contribuinte: ${dam.contribuinte
                .nome_razao_social || ""}, Documento: ${dam.contribuinte
                  .cpf_cnpj || ""}, Tipo: ${dam.receita.nome || ""}`
            }
          })
          this.setState({
            dams: data.data
          })
          callback(dams)
        }
      }
    )
    // )
  }

  handleselectedFile = event => {
    this.setState({
      arquivo_de_baixa: event.target.files[0]
    })
  }

  getDamById(id) {
    return this.state.dams.filter(item => {
      return id === item.id
    })[0]
  }

  imprimirDam() {
    showWaiting()
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        const reader = new FileReader()
        reader.addEventListener("loadend", e => {
          const text = e.srcElement.result
          let error = JSON.parse(text)
          let status = error.status
          showNotification(
            "top",
            "center",
            `Ocorreram erros! ${error.message}`,
            "danger"
          )
        })
        reader.readAsText(error.response.data)

      } else {
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        this.close()
      }
    }
    imprimir(this.state.id_dam.value, response)
  }

  render() {
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código DAM" },
      { value: "contribuinte_id", label: "Código do contribuinte" },
      { value: "contribuinte_cpf_cnpj", label: "Documento Contribuinte" },
      { value: "contribuinte_nome_razao_social", label: "Nome Razão Social" },
      { value: "vencimento", label: "Vencimento" },
      { value: "created_at", label: "Data de criação" },
      { value: "receita_id", label: "Código Receita" },
      { value: "inscricao", label: "Inscrição" },
      { value: "competencia", label: "Competência" }
    ]
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Card>
          <CardBody>
            <Typography color="textSecondary" variant="caption">
              Altere a data de vencimento Dam de Alvará
            </Typography>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AsyncSelect
                  className={
                    `form-control form-control-alternative form-select Select-menu-outer ${
                    this.props.error ? "has-danger" : ""
                    }`
                  }
                  value={this.state.id_dam}
                  name="id_dam"
                  onChange={this.handleChange}
                  loadOptions={this.loadDam}
                  placeholder="DAM Avulso"
                  message="Pesquise o DAM Avulso"
                />
                <SearchFields
                  name="filterField"
                  value={this.state.filterField || ""}
                  onChangeFields={this.handleChange}
                  fields={fields}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="outlined-name"
                  label="Cidade"
                  fullWidth
                  disabled
                  value={this.state.cidade}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="outlined-name"
                  label="Bairro"
                  fullWidth
                  disabled
                  value={this.state.bairro}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="outlined-name"
                  label="Valor"
                  fullWidth
                  disabled
                  value={`R$ ${numeral(parseFloat(this.state.valor))
                    .format("0.0,00")
                    .slice(0, -2)}`}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-name"
                  label="Histórico"
                  fullWidth
                  multiline
                  disabled
                  value={this.state.descricao}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="date"
                  label="Data do Vencimento"
                  margin="normal"
                  disabled
                  variant="outlined"
                  type="date"
                  value={this.state.data_vencimentoAntiga}
                  fullWidth
                  helperText={this.state.errors.data_vencimentoAntiga}
                  error={this.state.errors.data_vencimentoAntiga}
                  name="data_vencimentoAntiga"
                  onChange={this.handleChange}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="date"
                  label="Nova Data de Vencimento"
                  margin="normal"
                  variant="outlined"
                  type="date"
                  fullWidth
                  value={this.state.data_vencimentoNova}
                  helperText={this.state.errors.vencimento}
                  error={this.state.errors.vencimento}
                  name="data_vencimentoNova"
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            {this.state.podeAlterarMultaOuJuros ? 
              <div className={'row'}>
                <div className={'col-1'} style={{width: 150}}>
                    <fieldset style={{border: '1px solid #bdbdbd', borderRadius: 4, marginLeft: 10}}>
                        <legend style={{fontSize: 12, color: '#787878', padding: 2, width: 'auto'}}>
                          Sem Multa
                        </legend>
                        <Checkbox
                            checked={this.state.sem_multa}
                            tabIndex={-1}
                            onClick={this.onClickMulta}
                            classes={{
                                checked: classes.checked,
                                root: classes.root
                            }}
                        />
                    </fieldset>
                </div>
                <div className={'col-1'} style={{width: 150}}>
                    <fieldset style={{border: '1px solid #bdbdbd', borderRadius: 4, marginLeft: 10}}>
                        <legend style={{fontSize: 12, color: '#787878', padding: 2, width: 'auto'}}>
                          Sem Juros
                        </legend>
                        <Checkbox
                            checked={this.state.sem_juros}
                            tabIndex={-1}
                            onClick={this.onClickJuros}
                            classes={{
                                checked: classes.checked,
                                root: classes.root
                            }}
                        />
                    </fieldset>
                </div>
            </div> : ""}
          </CardBody>
          <CardFooter>
            <Grid container justify="center">
              <Grid item lg={this.state.lancadoNaDivida ? 6 : 2}>
                <Button
                  block
                  color={this.state.lancadoNaDivida ? "danger" : "info"}
                  disabled={this.state.lancadoNaDivida}
                  round
                  onClick={this.handleSave}
                >
                  {this.state.lancadoNaDivida ? (
                    <div>
                      <div>
                        <Warning /> O DAM Selecionado foi lançado na dívida
                      </div>
                      <div>Código da dívida ativa: {this.state.divida_id}</div>
                    </div>
                  ) : (
                      <div>
                        <AddIcon /> Alterar
                    </div>
                    )}
                </Button>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>

        {/* dialog imprimir */}
        <Dialog
          open={this.state.modalImprimir}
          onClose={this.close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: "3" }}
        >
          <DialogTitle id="alert-dialog-title">Você deseja imprimir o DAM?</DialogTitle>
          <DialogActions>
            <Button onClick={this.close} color="danger">
              Cancelar
            </Button>
            <Button onClick={this.imprimirDam} color="success">
              Imprimir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
AlterarVencimentoAvulso = withRouter(AlterarVencimentoAvulso)
export default withStyles(secaoStyle)(AlterarVencimentoAvulso)
