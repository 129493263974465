import React from "react"
import {
  showNotification,
  showWaiting,
  hideWaiting,
  mapErrors
} from "../../../util/Constants"
import logradouroStyle from "../../../assets/css/layout/logradouroStyle"
import { withStyles } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import Button from "../../../components/CustomButtons/Button"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Grid from "@material-ui/core/Grid"
import AdBlockDetect from "react-ad-block-detect"
import { getRelatorio } from "../../../util/iptupagorelatorio"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { search as searchImovel } from "../../../util/imovel"
import { search as searchContribuinte } from "../../../util/contribuinte"
import AsyncSelect from "../../../components/AsyncSelect"
import InputMask from "react-input-mask"

class IptuPagoRelatorio extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      ano: "",
      ano_quantitativo: "",
      group: "todos",
      modal: false,
      // id_imovel: [],
      // id_contribuinte: []
    }
    this.handleChange = this.handleChange.bind(this)
    this.gerarRelatorio = this.gerarRelatorio.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
    this.loadImovel = this.loadImovel.bind(this)

  }
  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    if (name === "group") {
      this.setState({
        [name]: event.target.checked,
        errors: errors
      })
    }
    this.setState({
      [name]: event.target.value,
      errors: errors
    })
  }
  loadImovel(inputValue, callback) {
    searchImovel(inputValue, "", undefined, (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
        callback([])
      } else {
        let imoveis = data.data.map(imovel => {
          return {
            value: imovel.id,
            label: `${imovel.id} - ${imovel.logradouro_correspondencia ||
              ""} - ${imovel.bairro_correspondencia ||
              ""} - ${imovel.cidade_correspondencia ||
              ""} - ${imovel.cep_correspondencia || ""}`
          }
        })
        callback(imoveis)
      }
    })
  }

  gerarRelatorio(event) {
    event.preventDefault()
    showWaiting()
    let response = (error, data) => {
      if (error) {
        console.log(error.response)
        hideWaiting()
        if (error.response.status === 422) {
          const reader = new FileReader()
          reader.addEventListener("loadend", e => {
            const text = e.srcElement.result
            let json = JSON.parse(text)
            this.setState({
              errors: mapErrors({ response: { data: json } })
            })
          })
          reader.readAsText(error.response.data)

        } else {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique o formulário e tente novamente",
            "danger"
          )
        }

      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    getRelatorio(
      this.state.ano ? this.state.ano : "",
      this.state.group ? this.state.group : "",
      this.state.id_imovel ? this.state.id_imovel.value: "",
      this.state.id_contribuinte ? this.state.id_contribuinte.value: "",
      this.state.data_inicial ? this.state.data_inicial : "",
      this.state.data_final ? this.state.data_final : "",
      response
    )
  }

  loadOptions(inputValue, callback) {
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let contribuintes = data.data.map(contribuinte => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - ${contribuinte.cpf_cnpj || ""}`
            }
          })
          callback(contribuintes)
        }
      }
    )
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid spacing={24} container direction="row">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <h2 className={classes.cardTitleWhite}>Relatórios IPTU Pago</h2>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <RadioGroup
                  aria-label="position"
                  name="group"
                  value={this.state.group}
                  onChange={this.handleChange}
                  row
                >
                  <FormControlLabel
                    value="todos"
                    control={<Radio color="primary" />}
                    label="Todos"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="pagos"
                    control={<Radio color="primary" />}
                    label="Pagos"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="em_aberto"
                    control={<Radio color="primary" />}
                    label="Em aberto"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <InputMask
                  mask="9999"
                  value={this.state.ano}
                  onChange={this.handleChange}
                >
                  {() => (
                    <TextField
                      id="outlined-name"
                      label="Ano"
                      name="ano"
                      helperText={this.state.errors.ano}
                      fullWidth
                      error={this.state.errors.ano}
                      value={this.state.ano}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>
            <Grid container direction="row" style={{ marginTop: "2%" }}>
              <Divider />
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                variant="caption"
              >
                Filtrar relatório por Imóveis ou deixe vazio para a opção de
                TODOS
              </Typography>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <AsyncSelect
                  classStyle={`form-control form-control-alternative form-select Select-menu-outer ${
                    this.props.error ? "has-danger" : ""
                    }`}
                  style={{ position: "relative" }}
                  value={this.state.id_imovel}
                  onChange={this.handleChange}
                  loadOptions={this.loadImovel}
                  name="id_imovel"
                  placeholder="Imóvel"
                  message="Pesquise o imóvel"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <AsyncSelect
                  classStyle={`form-control form-control-alternative form-select Select-menu-outer ${
                    this.props.error ? "has-danger" : ""
                    }`}
                  style={{ position: "relative" }}
                  value={this.state.id_contribuinte}
                  onChange={this.handleChange}
                  loadOptions={this.loadOptions}
                  name="id_contribuinte"
                  placeholder="Contribuinte"
                  message="Pesquise o contribuinte"
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography color="textSecondary" variant="caption">
                      Periodo de Emissão
                  </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                      id="outlined-select-currency"
                      name="data_inicial"
                      label="Data Inicial"
                      fullWidth
                      type="date"
                      error={this.state.errors.data_inicial}
                      helperText={this.state.errors.data_inicial}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                      defaultValue={this.state.data_inicial}
                      InputLabelProps={{
                          shrink: true,
                      }}
                  />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                      id="outlined-select-currency"
                      name="data_final"
                      label="Data Final"
                      fullWidth
                      type="date"
                      error={this.state.errors.data_final}
                      helperText={this.state.errors.data_final}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                      defaultValue={this.state.data_final}
                      InputLabelProps={{
                          shrink: true,
                      }}
                  />
              </Grid>
            </Grid>
          </CardBody>
          <CardFooter>
            <Grid container justify="center">
              <Grid item lg={2}>
                <Button block color="info" round onClick={this.gerarRelatorio}>
                  Gerar
                </Button>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>
        {/* MODAL */}

        <AdBlockDetect>
          <Dialog open={true} aria-labelledby="form-dialog-title">
            <form onSubmit={this.handleSave}>
              <DialogContent>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Typography variant="h5" gutterBottom>
                      Por favor, desabilite o ADBlock para que o relatório seja
                      emitido.
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
            </form>
          </Dialog>
        </AdBlockDetect>

      </div>
    )
  }
}

export default withStyles(logradouroStyle)(IptuPagoRelatorio)
