import axios from "axios"

export function get(token, callBack) {
  let url = process.env.REACT_APP_API + `validateCertidaoDeDebitos/${token}`
  axios
    .get(url)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

// validacao iptu
export function getIptu(token, callBack) {
  let url = process.env.REACT_APP_API + `validateCertidaoDeImovel/${token}`
  axios
    .get(url)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

// validacao positiva
export function getPositiva(token, callBack) {
  let url = process.env.REACT_APP_API + `validateCertidaoPositiva/${token}`
  axios
    .get(url)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getEnergia(token, callBack) {
  let url = process.env.REACT_APP_API + `validateCertidaoLuz/${token}`
  axios
    .get(url)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getAgua(token, callBack) {
  let url = process.env.REACT_APP_API + `validateCertidaoAgua/${token}`
  axios
    .get(url)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}


export function getAlvara(token, callBack) {
  let url = process.env.REACT_APP_API + `alvara/verificaValidadeAlvara/${token}`
  axios
      .get(url)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
}

export function getBCE(token, callBack) {
  let url = process.env.REACT_APP_API + `alvara/validaBCE/${token}`
  axios
      .get(url)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
}

export function getITBI(token, callBack) {
  let url = process.env.REACT_APP_API + `itbi/validaItbi/${token}`
  axios
      .get(url)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
}