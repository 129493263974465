import React from "react";

import {
    Grid,
    withStyles,
    Divider,
    Typography,
    TextField,
    MenuItem
} from "@material-ui/core";

import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import AsyncSelect from "../../components/AsyncSelect"
import SearchFields from "../../components/SearchFields"
import SelectCustom from "../../components/SelectCustom"

import contribuinteStyle from "../../assets/css/layout/contribuinteStyle";

import AddIcon from "@material-ui/icons/Add";

import { mapErrors, showNotification } from "../../util/Constants";
import { search as searchContribuinte } from "../../util/contribuinte"
import { create, post, edit, put } from "../../util/ordemDeServico"
import Button from "../../components/CustomButtons/Button";
import CardFooter from "../../components/Card/CardFooter";
import moment from "moment";


class OrdemForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            contribuintes: [],
            contribuinte_id: "",
            filterField: "",
            situacoes: [],
            anexos: [],
            documentos: [],
            situacao_ordem_id: 1,
            fiscais: [],
            errors: [],
            isCreated: true
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
        this.handleselectedFile = this.handleselectedFile.bind(this);
        this.appendInput = this.appendInput.bind(this);
        this.removeAppendInput = this.removeAppendInput.bind(this);
    }


    componentDidMount() {
        this.getData()
    }

    getData() {
        let response = (error, data) => {
            if (error) {
                // console.log(error);
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
                return;
            }

            let fiscais = data.fiscais.map(fiscal => {
                return {
                    value: fiscal.id,
                    label: `${fiscal.nome} n° matrícula: ${fiscal.matricula}`
                }
            })
            this.setState({ fiscais });
            this.setState({ situacoes : data.situacoes});

            if(this.props.match.params.id){
                
                let ordemData = {}
                ordemData.contribuinte_id = {
                    value: data.ordemDeServico.contribuinte.id,
                    label: data.ordemDeServico.contribuinte.nome_razao_social
                }
                ordemData.fiscal_id = data.ordemDeServico.fiscais.map(fiscal=> {
                    return {
                        value: fiscal.id,
                        label: `${fiscal.nome} n° matrícula: ${fiscal.matricula}`
                    }
                })
                ordemData.data_inicial_acao = moment(data.data_inicial_acao).format("YYYY-MM-DD")
                ordemData.situacao_ordem_id = data.ordemDeServico.situacao.id
                ordemData.fato_motivador    = data.ordemDeServico.fato_motivador
                ordemData.observacao        = data.ordemDeServico.observacao
                ordemData.roteiro           = data.ordemDeServico.roteiro
                ordemData.responsavel_nome  = !data.ordemDeServico.responsavel ? "" :  data.ordemDeServico.responsavel.nome
                ordemData.responsavel_cargo = !data.ordemDeServico.responsavel ? "" :  data.ordemDeServico.responsavel.cargo
                ordemData.orgao_fiscal      = data.ordemDeServico.orgao_fiscal
                ordemData.modalidade_acao   = data.ordemDeServico.modalidade_acao
                ordemData.isCreated         = false

                this.setState({ ...ordemData })
            }    
        }
        this.props.match.params.id ? edit(this.props.match.params.id, response) : create(response)
    }

    handleChange(event) {
        const { name, value } = event.target

        this.setState({ [name]: value })
    }

    handleselectedFile = event => {
        this.setState({
            anexos: [...this.state.anexos, event.target.files[0]]
        });
    };

    appendInput() {
        var newInput = `documentos-${this.state.documentos.length}`;
        this.setState(prevState => ({ documentos: prevState.documentos.concat([newInput])}));
    }

    removeAppendInput() {
        this.setState(state => ({
            documentos: state.documentos.filter((_, key) => {
              return key !== this.state.documentos.length-1;
            })
          }));
    }

    loadOptions(inputValue, callback) {
        searchContribuinte(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                    callback([])
                } else {
                    let contribuintes = data.data.map(contribuinte => {
                        return {
                            value: contribuinte.id,
                            label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                                ""} - ${contribuinte.cpf_cnpj || ""}`
                        }
                    })

                    this.setState({
                        contribuintes: data.data
                    })
                    callback(contribuintes)
                }
            }
        )
    }

    handleSave(event){
        event.preventDefault()
        
        let response = (error, data) => {
            if(error){
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
                this.setState({errors : mapErrors(error)})
                return;
            }

            showNotification(
                "top",
                "center",
                data.message,
                "success"
            );

            this.props.history.push("/fiscalizacao");
        } 

        let formData = new FormData;
        formData.append("contribuinte_id", this.state.contribuinte_id.value || "");
        formData.append("situacao_ordem_id", this.state.situacao_ordem_id || "");
        formData.append("data_inicial_acao", this.state.data_inicial_acao || "");
        formData.append("fato_motivador", this.state.fato_motivador || "");
        formData.append("orgao_fiscal", this.state.orgao_fiscal || "");
        formData.append("modalidade_acao", this.state.modalidade_acao || "");
        
        if(this.props.match.params.id){
            formData.append("_method", "PUT" || "");
            formData.append("observacao", this.state.observacao || "");
            formData.append("roteiro", this.state.roteiro || "");
            formData.append("responsavel[nome]", this.state.responsavel_nome || "");
            formData.append("responsavel[cargo]", this.state.responsavel_cargo || "");
        }
        
        this.state.fiscal_id.map((fiscal, key) => {
            formData.append(`fiscal_id[${key}]`, fiscal.value)
        });

        this.state.anexos.map((anexo, key) => {
            formData.append(`anexos[${key}]`, anexo)
        })

        this.props.match.params.id ? 
            put(this.props.match.params.id, formData, response) : 
            post(formData, response); 
    }

    render() {
        const { classes } = this.props

        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Código do contribuinte" },
            { value: "cpf_cnpj", label: "Documento" },
            { value: "endereco", label: "Endereço" },
            { value: "nome_fantasia", label: "Nome Fantasia" },
            { value: "nome_razao_social", label: "Razão Social" }
        ];

        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container spacing={32} direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>Ordem de Serviço</h2>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Grid container direction="row" style={{ marginTop: "2%" }}>
                            <Divider />
                            <Typography
                                className={classes.dividerFullWidth}
                                color="textSecondary"
                                variant="caption"
                            >
                                Informações do contribuinte
                            </Typography>
                        </Grid>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <SearchFields
                                    name="filterField"
                                    value={this.state.filterField || ""}
                                    onChangeFields={this.handleChange}
                                    fields={fields}
                                />
                                <AsyncSelect
                                    className={
                                        `form-control form-control-alternative form-select Select-menu-outer ${this.props.error ? "has-danger" : ""
                                        }`
                                    }
                                    value={this.state.contribuinte_id}
                                    onChange={this.handleChange}
                                    loadOptions={this.loadOptions}
                                    error={this.state.errors.contribuinte_id}
                                    helperText={this.state.errors.contribuinte_id}
                                    name="contribuinte_id"
                                    placeholder="Contribuinte"
                                    message="Pesquise o contribuinte"
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <TextField
                                    id="date"
                                    name="data_inicial_acao"
                                    label="Data de Início"
                                    margin="normal"
                                    required
                                    variant="outlined"
                                    type="date"
                                    value={this.state.data_inicial_acao}
                                    fullWidth
                                    helperText={this.state.errors.data_inicial_acao}
                                    error={this.state.errors.data_inicial_acao}
                                    onChange={this.handleChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    name="situacao_ordem_id"
                                    label="Situações"
                                    fullWidth
                                    value={this.state.situacao_ordem_id}
                                    onChange={this.handleChange}
                                    error={this.state.errors.situacao_ordem_id}
                                    helperText={this.state.errors.situacao_ordem_id}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    {this.state.situacoes.map((option, key) => (
                                    <MenuItem key={key} value={option.id}>
                                        {option.descricao}
                                    </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <SelectCustom
                                    value={this.state.fiscal_id}
                                    name="fiscal_id"
                                    isMulti={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={this.handleChange}
                                    error={this.state.errors.fiscal_id}
                                    helperText={this.state.errors.fiscal_id}
                                    options={this.state.fiscais}
                                    placeholder="Fiscais"
                                    message="Fiscais"
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    id="orgao_fiscal"
                                    name="orgao_fiscal"
                                    label="Orgão Fiscal"
                                    margin="normal"
                                    required
                                    variant="outlined"
                                    type="text"
                                    value={this.state.orgao_fiscal}
                                    fullWidth
                                    helperText={this.state.errors.orgao_fiscal}
                                    error={this.state.errors.orgao_fiscal}
                                    onChange={this.handleChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    id="modalidade_acao"
                                    name="modalidade_acao"
                                    label="Modalidade de Ação Fiscal"
                                    margin="normal"
                                    required
                                    variant="outlined"
                                    type="text"
                                    value={this.state.modalidade_acao}
                                    fullWidth
                                    helperText={this.state.errors.modalidade_acao}
                                    error={this.state.errors.modalidade_acao}
                                    onChange={this.handleChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" rows={5} spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="outlined-select-currency"
                                    name="fato_motivador"
                                    label="Fato Motivador"
                                    fullWidth
                                    error={this.state.errors.fato_motivador}
                                    helperText={this.state.errors.fato_motivador}
                                    multiline
                                    rows={3}
                                    rowsMax="4"
                                    value={this.state.fato_motivador}
                                    defaultValue={this.state.fato_motivador}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" rows={5} spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="outlined-select-currency"
                                    name="observacao"
                                    label="Observação"
                                    fullWidth
                                    disabled={this.state.isCreated}
                                    error={this.state.errors.observacao}
                                    helperText={this.state.errors.observacao}
                                    multiline
                                    rows={3}
                                    rowsMax="4"
                                    value={this.state.observacao}
                                    defaultValue={this.state.observacao}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" rows={5} spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="outlined-select-currency"
                                    name="roteiro"
                                    label="Roteiro"
                                    fullWidth
                                    disabled={this.state.isCreated}
                                    error={this.state.errors.roteiro}
                                    helperText={this.state.errors.roteiro}
                                    multiline
                                    rows={3}
                                    rowsMax="4"
                                    value={this.state.roteiro}
                                    defaultValue={this.state.roteiro}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>

                        {/* linha para o responsável */}
                        <Grid container direction="row" style={{ marginTop: "2%" }}>
                            <Divider />
                            <Typography
                                className={classes.dividerFullWidth}
                                color="textSecondary"
                                variant="caption"
                            >
                                Informações do Responsável
                            </Typography>
                        </Grid>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                <TextField
                                    id="outlined-name"
                                    label="Nome do responsável"
                                    name="responsavel_nome"
                                    fullWidth
                                    disabled={this.state.isCreated}
                                    InputLabelProps={{ shrink: true }}
                                    value={this.state.responsavel_nome}
                                    onChange={this.handleChange}
                                    error={this.state.errors["responsavel.nome"]}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <TextField
                                    id="outlined-name"
                                    label="Cargo do responsável"
                                    name="responsavel_cargo"
                                    fullWidth
                                    disabled={this.state.isCreated}
                                    InputLabelProps={{ shrink: true }}
                                    value={this.state.responsavel_cargo}
                                    onChange={this.handleChange}
                                    error={this.state.errors["responsavel.cargo"]}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>            
                        </Grid>
                        <Grid container direction="row" alignItems="center" justify="center" spacing={16}>
                            {this.state.documentos.map((documento, key) => (
                                <Grid item xs={12} sm={12} md={12} lg={12} keys={key}>
                                    <TextField
                                        key={documento}
                                        type="file"
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        helperText={this.state.errors[`anexos.${key}`]}
                                        error={this.state.errors[`anexos.${key}`]}
                                        name="anexos"
                                        onChange={event => this.handleselectedFile(event)}
                                    />
                                </Grid>
                            ))}
                            <Grid item xl="2" md="2" sm="12">
                                <Button color="danger" round onClick={this.removeAppendInput}>
                                    Remover documento
                                </Button>
                            </Grid>
                            <Grid item xl="2" md="2" sm="12">
                                <Button color="success" round onClick={this.appendInput}>
                                    Adicionar documentos
                                </Button>
                            </Grid>
                        </Grid>
                    </CardBody>
                    <CardFooter>
                        <Grid container justify="center" spacing={16}>
                            <Grid item lg={2}>
                                <Button block color="info" round type="submit" onClick={this.handleSave}>
                                    <AddIcon />{" "}
                                    {this.props.match.params.id ? "Atualizar" : "Registrar"}
                                </Button>
                            </Grid>
                        </Grid>
                    </CardFooter>
                </Card>
            </div>
        )
    }
}

export default withStyles(contribuinteStyle)(OrdemForm)