import React from "react"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import AsyncSelect from "../../../components/AsyncSelect"
import AddIcon from "@material-ui/icons/Add"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Button from "../../../components/CustomButtons/Button"
import secaoStyle from "../../../assets/css/layout/secaoStyle"
import { withStyles } from "@material-ui/core"
// import { getFields, post, edit, put, search } from "../../../util/secaologradouro"
import { getFields, post, edit, put, search } from "../../../util/logradouro"
import { search as getBairros} from "../../../util/bairro";

import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../../util/Constants"

// import { search } from "../../../util/logradouro"
import LoadingContent from "../../../components/LoadingContent"

class LogradouroForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      errors: {},
      // Logradouro INFO
      tipo: "",
      nome: "",
      nome_atual: "",
      lei: "",
      nome_anterior: "",
      bairros: [],
      //   LOGRADOURO SELECT
      cod_bairro: "",

    }
    // this.getFields = this.getFields.bind(this)
    this.getData = this.getData.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
  }

  componentDidMount() {
    this.getData();
  }

  getData(callback = () => {}) {
    if (this.props.match.params.id) {
      edit(this.props.match.params.id, (error, data) => {
        console.log(error)
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let tipo = data.tipo;
          let nome = data.nome.match(/[^ ]+/g).join(' ');
          if (!data.tipo){
            tipo = nome.split(' ')[0];
            nome = nome.split(' ').slice(1).join(' ');
          }
          let nomeAnterior = data.nome_anterior ? data.nome_anterior : tipo +" "+ nome;
          let bairro = "";
          if (data.bairros) {
            bairro = {
              value: data.bairros.id,
              label: data.bairros.nome
            }
          };
          
          let lograd = {
            id: data.id,
            tipo: tipo,
            nome_atual: nome,
            lei: data.lei,
            nome_anterior: nomeAnterior,
            cod_bairro: bairro
          }
          this.setState({
            id: data.id,
            tipo: tipo,
            nome_atual: nome,
            lei: data.lei,
            nome_anterior: nomeAnterior,
            cod_bairro: bairro
          })
          callback(lograd)
        }})
      }
  }

  loadOptions(inputValue, callback) {

    getBairros(inputValue, undefined, undefined, (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
        callback([])
      } else {
        let bairros = data.data.map(bairro => {
          return { value: bairro.id, label: bairro.nome }
        })
        callback(bairros)
      }
    })
  }


  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: event.target.value,
      errors: errors
    })
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let params = {
      // logradouro
      tipo: this.state.tipo,
      nome: this.state.nome_atual,
      lei: this.state.lei,
      nome_anterior: this.state.nome_anterior,
      bairro_id: this.state.cod_bairro ? this.state.cod_bairro.value : undefined
    }

    let response = error => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          `Seção de Logradouros ${this.props.match.params.id ? "editada" : "incluída"
          } com sucesso`,
          "success"
        )
        this.props.history.push("/iptu/cadastro")
      }
    }
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response)
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }

  render() {
    const { classes } = this.props;
    const typeForm = this.props.history.location.pathname.split('/')[2];
    return (
      <div className={classes.container}>
        <LoadingContent isLoading={this.state.isLoading}>
          <Card>
            <form onSubmit={this.handleSave}>
              <CardHeader color="primary">
                <Grid container direction="row">
                  {
                    typeForm === 'edit' ? 
                      <Grid item lg={10} xs={12}>
                        <h2 className={classes.cardTitleWhite}>
                          Alteração de logradouro
                        </h2>
                      </Grid> :
                      <Grid item lg={10} xs={12}>
                        <h2 className={classes.cardTitleWhite}>
                          Cadastro de logradouro
                        </h2>
                      </Grid>
                  }
                  
                </Grid>
              </CardHeader>
              <CardBody>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <AsyncSelect
                      className={
                        `form-control form-control-alternative form-select Select-menu-outer ${this.props.error ? "has-danger" : ""
                        }`
                      }
                      value={this.state.cod_bairro}
                      onChange={this.handleChange}
                      loadOptions={this.loadOptions}
                      error={this.state.errors.logradouro_id}
                      helperText={this.state.errors.logradouro_id}
                      name="cod_bairro"
                      placeholder="Bairros"
                      message="Pesquise nome do Bairro"
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-name"
                      label="Tipo"
                      name="tipo"
                      required
                      fullWidth
                      value={this.state.tipo}
                      onChange={this.handleChange}
                      error={this.state.errors.cod_secao}
                      margin="normal"
                      variant="outlined"
                    />
                  
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-name"
                      label="Nome Atual"
                      name="nome_atual"
                      required
                      fullWidth
                      value={this.state.nome_atual}
                      onChange={this.handleChange}
                      error={this.state.errors.valor_m2}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      id="outlined-select-currency"
                      label="Lei"
                      name="lei"
                      // required
                      fullWidth
                      value={this.state.lei}
                      onChange={this.handleChange}
                      error={this.state.errors.tipo_iluminacao_id}
                      margin="normal"
                      variant="outlined"
                    >
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={16}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        id="outlined-name"
                        label="Nome Anterior"
                        name="nome_anterior"
                        // required
                        // disabled
                        fullWidth
                        value={this.state.nome_anterior}
                        onChange={this.handleChange}
                        error={this.state.errors.guia_e_sarjeta_id}
                        helperText={this.state.errors.guia_e_sarjeta_id}
                        margin="normal"
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>
                </Grid>
                
              </CardBody>
              <CardFooter>
                <Grid container justify="center">
                  <Grid item lg={2}>
                    <Button block color="info" round type="submit">
                      <AddIcon />{" "}
                      {this.props.match.params.id ? "Atualizar" : "Adicionar"}
                    </Button>
                  </Grid>
                </Grid>
              </CardFooter>
            </form>
          </Card>
        </LoadingContent>
      </div>
    )
  }
}

export default withStyles(secaoStyle)(LogradouroForm)
