import React, { Component } from "react"
import { printMany } from "../util/certidaodivida"
import { TextField, Typography } from "@material-ui/core"
import {
  hideWaiting,
  showWaiting,
  mapErrors,
  showNotification
} from "../util/Constants"
import Grid from "@material-ui/core/Grid"
import Button from "../components/CustomButtons/Button"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import Dialog from "@material-ui/core/Dialog"
import NumberFormatCustom from "../components/NumberFormatCustom"
import SearchFields from "../components/SearchFields"
import AsyncSelect from "../components/AsyncSelect"
import { simpleSearch as searchContribuinte } from '../util/contribuinte'

export default class PrintCertidoesModal extends Component {
  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this)
    this.printManyCertidoes = this.printManyCertidoes.bind(this)
    this.state = {
      errors: {},
      contribuintes: []
    }
    this.loadOptions = this.loadOptions.bind(this)
  }
  handleChange(e) {
    let value = e.target.value
    let name = e.target.name
    let errors = { ...this.state.errors }
    delete errors[name]
    this.setState({
      [name]: value,
      errors: errors
    })
  }
  printManyCertidoes(e) {
    e.preventDefault()
    let ano = this.state.ano
    let numeroInicial = this.state.numero_inicial
    let numeroFinal = !this.state.numero_final ? "" : this.state.numero_final
    let valorMinimo = this.state.valor_minimo
    let valorMaximo = !this.state.valor_maximo ? "" : this.state.valor_maximo
    let contribuinte_id = !this.state.contribuinte_id ? "" : this.state.contribuinte_id.value

    let response = (error, data) => {
      if (error) {
        hideWaiting()
        const reader = new FileReader()
        reader.addEventListener("loadend", e => {
          const text = e.srcElement.result
          try {
            let error = JSON.parse(text)
            this.setState({
              errors: mapErrors({ response: { data: error } })
            })
          } catch (error) {
            showNotification(
              "top",
              "center",
              "Ocorreram erros, verifique sua conexão e tente novamente",
              "danger"
            )
          }
        })
        reader.readAsText(error.response.data)
      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        this.props.toggleModal()
      }
    }
    showWaiting()
    printMany(contribuinte_id, ano, numeroInicial, numeroFinal, valorMinimo, valorMaximo, response)
  }

  loadOptions(inputValue, callback) {
    searchContribuinte(
        inputValue,
        this.state.filterField || "",
        undefined,
        (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
                callback([])
            } else {
                let contribuintes = data.data.map(contribuinte => {
                    return {
                        value: contribuinte.id,
                        label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                            ""} - ${contribuinte.cpf_cnpj || ""}`
                    }
                })
                this.setState({
                    contribuintes: data.data
                })
                callback(contribuintes)
            }
        }
    )
}

  render() {
    const fields = [
        { value: "", label: "Todos os campos" },
        { value: "id", label: "Código do contribuinte" },
        { value: "cpf_cnpj", label: "Documento" },
        { value: "endereco", label: "Endereço" },
        { value: "nome_fantasia", label: "Nome Fantasia" },
        { value: "nome_razao_social", label: "Razão Social" }
    ]
    return (
      <Dialog
        style={{ zIndex: "4" }}
        open={this.props.showModal || false}
        onClose={this.props.toggleModal}
        fullWidth
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={e => this.printManyCertidoes(e)}>
          <DialogTitle id="form-dialog-title">
            Digite o ano e o numero da certidão. PS:Deixar o numero vazio para
            imprimir todas do ano selecionado
          </DialogTitle>
          <DialogContent>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                  <SearchFields
                      name="filterField"
                      value={this.state.filterField || ""}
                      onChangeFields={this.handleChange}
                      fields={fields}
                  />
                  <Typography color="textSecondary" variant="caption">
                      Pesquise o Contribuinte
                  </Typography>
                  <AsyncSelect
                      className={
                          `form-control form-control-alternative form-select Select-menu-outer ${this.props.error ? "has-danger" : ""
                          }`
                      }
                      value={this.state.contribuinte_id}
                      onChange={this.handleChange}
                      loadOptions={this.loadOptions}
                      error={this.state.errors.contribuinte_id}
                      helperText={this.state.errors.contribuinte_id}
                      name="contribuinte_id"
                      placeholder="Contribuinte"
                      message="Pesquise o contribuinte"
                  />
              </Grid>
              <TextField
                id="outlined-select-currency"
                name="ano"
                label="Ano das certidões"
                required
                fullWidth
                error={this.state.errors.ano}
                helperText={this.state.errors.ano}
                rowsMax="3"
                type="numeric"
                value={this.state.ano || ""}
                onChange={this.handleChange}
                margin="normal"
                variant="outlined"
              />
              <Grid item sm={12} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  name="numero_inicial"
                  label="Número inicial"
                  fullWidth
                  required
                  error={this.state.errors.numero_inicial}
                  helperText={this.state.errors.numero_inicial}
                  rowsMax="3"
                  type="numeric"
                  value={this.state.numero_inicial || ""}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item sm={12} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  name="numero_final"
                  label="Número final"
                  fullWidth
                  // required
                  error={this.state.errors.numero_final}
                  helperText={this.state.errors.numero_final}
                  rowsMax="3"
                  type="numeric"
                  value={this.state.numero_final || ""}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item sm={12} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  name="valor_minimo"
                  label="Valor Mínimo"
                  fullWidth
                  required
                  error={this.state.errors.valor_minimo}
                  helperText={this.state.errors.valor_minimo}
                  rowsMax="3"
                  type="numeric"
                  value={this.state.valor_minimo || ""}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom
                  }}
                />
              </Grid>
              <Grid item sm={12} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  name="valor_maximo"
                  label="Valor Máximo"
                  fullWidth
                  // required
                  error={this.state.errors.valor_maximo}
                  helperText={this.state.errors.valor_maximo}
                  rowsMax="3"
                  type="numeric"
                  value={this.state.valor_maximo || ""}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom
                  }}
                />
                </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.toggleModal} color="danger">
              Cancelar
            </Button>
            <Button type="submit" color="success">
              Gerar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}
