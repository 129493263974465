import React from "react"
// import ContribuinteTable from "./ContribuinteTable";
import TextField from "@material-ui/core/TextField"
import { withStyles, Paper, MenuItem } from "@material-ui/core"
import logradouroStyle from "../../assets/css/layout/logradouroStyle"
import Button from "../../components/CustomButtons/Button"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import AddIcon from "@material-ui/icons/Add"
import Grid from "@material-ui/core/Grid"
import { showNotification, mapErrors } from "../../util/Constants"
import Table from "../../components/Table/Table"
import TablePagination from "@material-ui/core/TablePagination"
import Edit from "@material-ui/icons/Edit"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { get, post, put, search, getCreate } from "../../util/ipca"
import LoadingContent from "../../components/LoadingContent"
import Search from "../../components/Search"

import InputMask from "react-input-mask"

import housecall from "housecall"
import NumberFormatCustom from "../../components/NumberFormatCustom"
import Mask from "../Iptu/Movimento/mask";
import DecimalFormatCustom from "../../components/DecimalFormatCustom"

class TabelaIPCA extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      errors: {},
      nome: "",
      pageCount: 0,
      perPage: 0,
      page: 0,
      isSearch:false,
      unidades: [],
      searchText: "",

      moeda: [],
      moeda_id: 1,
      ano: "",
      mes: "",
      valor: "",

      field: 0,
      modal: false,
      id: 0
    }
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.getData = this.getData.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.filter = this.filter.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
    this.getFieldCreate = this.getFieldCreate.bind(this)
    this.init = this.init.bind(this)
    this.setLoadingFalse = this.setLoadingFalse.bind(this)
  }

  setLoadingFalse() {
    this.setState({
      isLoading: false
    })
}

  componentWillMount() {
    this.init()
  }
  init() {
    this.getData()
    this.getFieldCreate()
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  handlePageClick(data) {
    //this.getData(data.selected + 1);
  }
  componentDidMount() {
    this.queue.on("idle", this.setLoadingFalse)
  }
  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.state.unidades !== nextState.unidades) {
  //     return true
  //   }
  //   return false
  // }

  getFieldCreate() {
    //this.toggleLoading()
    let response = (error, data) => {
      //this.toggleLoading()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState({
          moeda: data.moedas
        })
        this.setState(data)
      }
    }
    getCreate(response)
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: event.target.value,
      errors: errors
    })
  }

  handleOpen = () => {
    this.setState({ modal: true }, console.log("modal: ", this.state.modal))
  }

  handleClose = () => {
    this.setState(
      {
        modal: false,
        moeda_id: 1,
        ano: "",
        mes: "",
        valor: ""
      },
      () => {
        this.getData(this.state.page)
      }
    )
  }

  handleChangeFilters = event => {
    this.setState({ filterField: event.target.value }, () => {
      if (this.state.searchActive) {
        this.filter({ target: { value: this.state.searchText } })
      }
    })
  }

  filter(event,pagina) {
    let searchText = event.target.value
    var newState =    {
      isLoading:true,
      searchText: searchText,
      searchActive: searchText !== ""
    }
    if(pagina == null){
        newState["page"]=0
    }
    this.setState(newState
   ,
      () => {
        if (this.state.searchActive) {
       
          let filtersCheckbox = this.state.filterField || ""
          this.queue.push(() =>
            search(searchText, filtersCheckbox, undefined, (error, data) => {
              this.setState({isLoading:false})
              if (error) {
                showNotification(
                  "top",
                  "center",
                  "Ocorreram erros, verifique sua conexão com a internet",
                  "danger"
                )
              } else {
                if(this.state.searchText !== "") {
                  this.setState({
                    unidades: data.data,
                    pageCount: data.total,
                    perPage: data.per_page,
                   
                  })
                }
                
              }
            },pagina)
          )
        } else {
          this.getData()
        }
      }
    )
  }

  handleSave(event) {
    event.preventDefault()
    let params = {
      moeda_id: this.state.moeda_id,
      mes: this.state.mes,
      ano: this.state.ano,
      valor: this.state.valor
    }
    let response = error => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        showNotification(
          "top",
          "center",
          `Unidade ${this.state.id ? "editada" : "incluída"} com sucesso`,
          "success"
        )
        this.handleClose()
        this.init()
      }
    }
    
    this.state.id
      ? put(this.state.id, params, response)
      : post(params, response)
    }
    
  getData(page) {
      this.setState({isLoading:true})
  
    get(page, (error, data) => {
      this.setState({isLoading:false})
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState({
          unidades: data.data,
          pageCount: data.total,
          perPage: data.per_page,
   
        })
      }
    })
  }


  handleChangePage = (event, page) => {
    var pagina;
    pagina = page + 1;
    this.setState({page: page})
    if(page < 0){
      return;
    }
    if (this.state.searchText.toString().trim() != "") {
      this.filter({target: {value: this.state.searchText}}, pagina, page)
      return;
    }
    this.getData(pagina)
  };

  handleEdit(key) {
    this.setState(
      {
        moeda_id: this.state.unidades[key].moeda_id,
        mes: this.state.unidades[key].mes,
        ano: this.state.unidades[key].ano,
        valor: this.state.unidades[key].valor,
        id: this.state.unidades[key].id
      },
      () => {
        this.handleOpen()
      }
    )
  }

  handleFieldChange(value) {
    this.setState({
      field: value
    })
  }

  render() {
    const { classes } = this.props
    const fields = []
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>IPCA</h2>
              </Grid>
              <Grid item lg={2} xs={12}>
                <Button block color="success" round onClick={this.handleOpen}>
                  <AddIcon /> Adicionar
                </Button>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Search
              label="Pesquisar por ano"
              value={this.state.filterField}
              onChangeText={this.filter}
              onChangeFields={this.handleChangeFilters}
              fields={fields}
            />
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  tableHeaderColor="primary"
                  actions={[
                    {
                      title: "Editar",
                      icon: (
                        <Edit
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      ),
                      onClick: this.handleEdit
                    }
                  ]}
                  tableHead={["#", "Nome Moeda", "Valor", "Ano", "Mês"]}
                  tableData={
                    this.state.unidades.map((item, key) => {
                      return [
                        item.id + "",
                        item.moeda.descricao,
                        parseFloat(item.valor) ,
                        item.ano + "",
                        item.mes + "",
                        key
                      ]
                    })}
                    />
                { (
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={this.state.pageCount}
                    rowsPerPage={parseInt(this.state.perPage)}
                    page={this.state.page}
                    backIconButtonProps={
                  
                     
                    {"aria-label": "Previous Page"}}
                    nextIconButtonProps={
                      {
                      "aria-label": "Next Page",
                    }}


                    SelectProps={{
                      native: true
                    }}

                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                )}
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>

        {/* MODAL */}
        <Dialog
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <form onSubmit={this.handleSave}>
            <DialogTitle id="form-dialog-title">
              {console.log(this.state.id)}
              {this.state.id ? "Atualizar" : "Adicionar"} IPCA
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-name"
                    select
                    label="Moeda"
                    name="moeda_id"
                    fullWidth
                    value={this.state.moeda_id}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  >
                    {this.state.moeda.map((option, key) => (
                      <MenuItem key={key} value={option.id}>
                        {option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <InputMask
                    mask="9.99999999999999999999"
                    maskChar=""
                    value={this.state.valor}
                    onChange={this.handleChange}
                  >
                      {()=>(
                        <TextField
                          id="outlined-select-currency"
                          name="valor"
                          label="Valor"
                          required
                          fullWidth
                          error={this.state.errors.valor}
                          helperText={this.state.errors.valor}
                          value={this.state.valor}
                          defaultValue={this.state.valor}
                          onChange={this.handleChange}
                          margin="normal"
                          variant="outlined"
                          // InputLabelProps={{
                          //   shrink: true
                          // }}
                          // InputProps={{
                          //   inputComponent: DecimalFormatCustom
                          // }}
                        />
                      )}
                  </InputMask>
                </Grid>
              </Grid>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <InputMask
                    mask="9999"
                    value={this.state.ano}
                    onChange={this.handleChange}
                  >
                    {() => (
                      <TextField
                        id="outlined-name"
                        required
                        label="Ano"
                        name="ano"
                        helperText={this.state.errors.ano}
                        fullWidth
                        error={this.state.errors.ano}
                        value={this.state.numero_documento}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <InputMask
                    mask="99"
                    value={this.state.mes}
                    onChange={this.handleChange}
                  >
                    {() => (
                      <TextField
                        id="outlined-name"
                        required
                        label="Mês"
                        name="mes"
                        helperText={this.state.errors.mes}
                        fullWidth
                        error={this.state.errors.mes}
                        value={this.state.mes}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="danger">
                Cancelar
              </Button>
              <Button type="submit" color="success">
                {this.state.id ? "Atualizar" : "Adicionar"}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(logradouroStyle)(TabelaIPCA)
