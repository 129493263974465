import axios from "axios"
import {defaultHeaders} from "./Constants"

export function get(page, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url =
        process.env.REACT_APP_API + `damsDeITBI${page ? `?page=${page}` : ""}`
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function getField(callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "damsDeITBI/create"
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function post(params, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "damsDeITBI"
    axios
        .post(url, params, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function put(id, params, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "damsDeITBI/" + id
    axios
        .put(url, params, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function edit(id, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + `damsDeITBI/${id}/edit`
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}


export function removeEmissao(id, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "damsDeITBI/" + id
    axios
        .delete(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function search(searchText, field, onStart, callBack,pagina) {
    let config = {
        headers: defaultHeaders(),
        data: {a: "a"}
    }
    let url =
        process.env.REACT_APP_API +
        `search/damITBI?text=${searchText}&fields=${field}`
    if(pagina != null){
        url += `&page=${pagina}`
    }
    if (onStart) {
        onStart()
    }
    return axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function guiaItbi(id, callBack) {
    let headers = defaultHeaders()
    let config = {
        headers: headers,
        responseType: "blob"
    }
    let url = process.env.REACT_APP_API + `guiaDeITBI/${id}`
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function relatorioDeEmissao(adquirenteId, callBack) {
    let headers = defaultHeaders()
    let config = {
        headers: headers,
        responseType: "blob"
    }
    let url = process.env.REACT_APP_API + `relatorios/itbisEmitidos/${adquirenteId ? `?adquirente_id=${adquirenteId}` : ""}`
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function relatorioDeArrecadacao(data_inicial, data_final, callBack) {
    let headers = defaultHeaders()
    let config = {
        headers: headers,
        responseType: "blob"
    }
    let url = process.env.REACT_APP_API + `relatorios/arrecadacaoItbi${data_inicial ? `?data_inicial=${data_inicial}` : ""}${data_final ? `&data_final=${data_final}` : ""}`
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function alterarVencimento(id, vencimento, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + `mudarVencimentoITBI/${id}?vencimento=${vencimento || ""}`
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function imoveisParaAvaliar(id, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + `imoveisParaAvaliar/${id}`
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}


export function avaliar(id, parameters, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + `avaliarITBI/${id}`
    axios
        .put(url, parameters, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function autorizarITBI(id, callBack){
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + `autorizarITBI/${id}`
    axios
        .put(url, {}, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}
