import { defaultHeaders } from "./Constants";
import axios from "axios";

export function get(page, callBack) {
    let config = {
      headers: defaultHeaders()
    }
    let url =
      process.env.REACT_APP_API +
      `tiposDeOcupacao${page ? `?page=${page}` : ""}`
    axios
      .get(url, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
  }