import React from "react";

import {
    Grid,
    withStyles,
    TextField
} from "@material-ui/core";

import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";

import contribuinteStyle from "../../../assets/css/layout/contribuinteStyle";
import { showNotification } from "../../../util/Constants";
import { edit, put } from "../../../util/ordemTermos";
import CardFooter from "../../../components/Card/CardFooter";
import Button from "../../../components/CustomButtons/Button";

class TermoEditForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: {},
            tipo_termo_id : "",
            descricao: "",
            documentos_solicitados: "",
            data_prorrogacao: "",
            intimacao: "",
            prazo: "",
            penalidade: "",
        }
        this.getData = this.getData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    handleChange(event){
        const { name, value } = event.target;

        this.setState({ [name]: value });
    }

    handleSave(){

        let params = {};
        params.descricao = this.state.descricao || "";
        
        if([1, 3].includes(this.state.tipo_termo_id)){
            params.documentos_solicitados = this.state.documentos_solicitados || "";
        }
        
        if(this.state.tipo_termo_id == 2){
            params.intimacao = this.state.intimacao || "";
            params.prazo = this.state.prazo || "";
            params.penalidade = this.state.penalidade || "";
        }

        if(this.state.tipo_termo_id == 3){
            params.data_prorrogacao = this.state.data_prorrogacao || "";
        }

        let response = (error, data) => {
            if(error){
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                );
                return;
            }

            showNotification(
                "top",
                "center",
                data.message,
                "success"
            );

            this.props.history.push("/fiscalizacao");
        }

        put(this.props.match.params.id, params, response);
    }

    getData() {
        let response = (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                );
                return;
            }

            let termo = { termo: data.termo}
            termo.tipo_termo_id = data.termo.tipo_termo_id;
            termo.descricao = data.termo.descricao;
            termo.documentos_solicitados = data.termo.documentos_solicitados;
            termo.data_prorrogacao = data.termo.data_prorrogacao;
            termo.intimacao = data.termo.intimacao;
            termo.prazo = data.termo.prazo;
            termo.penalidade = data.termo.penalidade;

            console.log(termo);
            this.setState({
                ...termo
            });
        }
        edit(this.props.match.params.id, response);
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container spacing={32} direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>{this.state.hasOwnProperty("termo") ? this.state.termo.tipo.descricao : ""}</h2>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Grid container direction="row" rows={5} spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="outlined-select-currency"
                                    name="descricao"
                                    label={[1, 3].includes(this.state.tipo_termo_id) ? "Contexto" : "Relato"}
                                    fullWidth
                                    error={this.state.errors.descricao}
                                    helperText={this.state.errors.descricao}
                                    multiline
                                    rows={3}
                                    rowsMax="4"
                                    value={this.state.descricao}
                                    defaultValue={this.state.descricao}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            {[1, 3].includes(this.state.tipo_termo_id) && (
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="documentos_solicitados"
                                        label="Documentos Solicitados"
                                        fullWidth
                                        error={this.state.errors.documentos_solicitados}
                                        helperText={this.state.errors.documentos_solicitados}
                                        value={this.state.documentos_solicitados}
                                        defaultValue={this.state.documentos_solicitados}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            )}
                            {this.state.tipo_termo_id == 3 && (
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="data_prorrogacao"
                                        label="Início da prorrogação"
                                        fullWidth
                                        type="date"
                                        error={this.state.errors.data_prorrogacao}
                                        helperText={this.state.errors.data_prorrogacao}
                                        value={this.state.data_prorrogacao}
                                        defaultValue={this.state.data_prorrogacao}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        {this.state.tipo_termo_id == 2 && (
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="intimacao"
                                        label="Descrição de Intimação"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        rowsMax="4"
                                        error={this.state.errors.intimacao}
                                        helperText={this.state.errors.intimacao}
                                        value={this.state.intimacao}
                                        defaultValue={this.state.intimacao}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="prazo"
                                        label="Prazo"
                                        fullWidth
                                        type="number"
                                        error={this.state.errors.prazo}
                                        helperText={this.state.errors.prazo}
                                        value={this.state.prazo}
                                        defaultValue={this.state.prazo}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="penalidade"
                                        label="Penalidade"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        rowsMax="4"
                                        error={this.state.errors.penalidade}
                                        helperText={this.state.errors.penalidade}
                                        value={this.state.penalidade}
                                        defaultValue={this.state.penalidade}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </CardBody>
                    <CardFooter>
                        <Grid container justify="center" spacing={16}>
                            <Grid item lg={2}>
                                <Button block color="info" round type="submit" onClick={this.handleSave}>
                                    Atualizar
                                </Button>
                            </Grid>
                        </Grid>
                    </CardFooter>
                </Card>
            </div>
        );
    }

}

export default withStyles(contribuinteStyle)(TermoEditForm)