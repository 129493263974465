import axios from "axios";
import { defaultHeaders, makeRequestCreator } from "./Constants";


const searchGet = makeRequestCreator()
export function search(searchText, field, onStart, callBack,pagina) {
  let url =
    process.env.REACT_APP_API +
    `search/ordemDeServico?text=${searchText}&fields=${field}`

  if(pagina != undefined){
    url += `&page=${pagina}`
  }

  return searchGet(url, callBack)
}

export function print(ordemId, callBack) {
    let headers = defaultHeaders()
    let config = {
        headers: headers,
        responseType: "blob"
    }
    let url =
        process.env.REACT_APP_API + `ordemDeServico/${ordemId}`;
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data);
        })
        .catch(error => {
            callBack(error);
        })
}

export function printInfracao(infracaoId, callBack) {
    let headers = defaultHeaders()
    let config = {
        headers: headers,
        responseType: "blob"
    }
    let url =
        process.env.REACT_APP_API + `ordemDeServico/infracoes/${infracaoId}`;
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data);
        })
        .catch(error => {
            callBack(error);
        })
}

export function get(page, callback){
    let config = {
        headers: defaultHeaders()
    }

    const url = process.env.REACT_APP_API+`ordemDeServico?page=${page ? page : ""}`;
    
    axios.get(url, config)
        .then(response => {
            callback(undefined, response.data);
        })
        .catch(error => {
            callback(error, undefined);
        })
}

export function getTermosByOrdemId(ordemId, callback)
{
    let config = {
        headers: defaultHeaders()
    }

    const url = process.env.REACT_APP_API+`ordemDeServico/${ordemId}/termos`;
    
    axios.get(url, config)
        .then(response => {
            callback(undefined, response.data);
        })
        .catch(error => {
            callback(error, undefined);
        })
} 

export function create(callback){
    let config = {
        headers: defaultHeaders()
    }

    const url = process.env.REACT_APP_API+"ordemDeServico/create";
    
    axios.get(url, config)
        .then(response => {
            callback(undefined, response.data);
        })
        .catch(error => {
            callback(error, undefined);
        })
}

export function post(formData, callback){
    let config = {
        headers: defaultHeaders(),
        "Content-Type": "multipart/form-data"
    }

    const url = process.env.REACT_APP_API+"ordemDeServico";
    
    axios.post(url, formData, config)
        .then(response => {
            callback(undefined, response.data);
        })
        .catch(error => {
            callback(error, undefined);
        })
}


export function edit(ordemId, callback){
    let config = {
        headers: defaultHeaders()
    }

    const url = process.env.REACT_APP_API+`ordemDeServico/${ordemId}/edit`;
    
    axios.get(url, config)
        .then(response => {
            callback(undefined, response.data);
        })
        .catch(error => {
            callback(error, undefined);
        })
}

export function put(ordemId, formData, callback){
    let config = {
        headers: defaultHeaders(),
        "Content-Type": "multipart/form-data"
    }

    const url = process.env.REACT_APP_API+`ordemDeServico/${ordemId}`;
    
    axios.post(url, formData, config)
        .then(response => {
            callback(undefined, response.data);
        })
        .catch(error => {
            callback(error, undefined);
        })
}

export function infracaoStore(ordemId, formData, callback)
{
    let config = {
        headers: defaultHeaders(),
    }

    const url = process.env.REACT_APP_API+`ordemDeServico/${ordemId}/infracao`;
    
    axios.post(url, formData, config)
        .then(response => {
            callback(undefined, response.data);
        })
        .catch(error => {
            callback(error, undefined);
        })
}