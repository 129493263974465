import React from "react"

import { withStyles } from "@material-ui/core"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import MenuDashboard from "../MenuDashboard"

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import theme from "../../assets/css/theme"
import FileCopy from "@material-ui/icons/FileCopy"
import Update from "@material-ui/icons/Update"
import DamAvulso from "./DamAvulso"
import GetApp from "@material-ui/icons/GetApp"
import AlterarVencimentoAvulso from "./AlterarVencimentoAvulso"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { damAvulsoTabChanged } from "../../actions"
import AvulsoBaixas from "./AvulsoBaixas"

const tab = [
  {
    name: "DAM Avulso",
    icon: <FileCopy />
  },
  {
    name: "Alterar Vencimento DAM Avulso",
    icon: <Update />
  },
  {
    name: "Baixas",
    icon: <GetApp />
  }
]

class DamAvulsoMain extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      field: 0
    }

    this.handleFieldChange = this.handleFieldChange.bind(this)
  }

  handleFieldChange(value) {
    this.props.damAvulsoTabChanged(value)
  }

  render() {
    const { classes, field } = this.props
    return (
      <div className={classes.container}>
        <MuiThemeProvider theme={theme}>
          <MenuDashboard
            names={tab}
            onChange={this.handleFieldChange}
            value={field}
          />
          {field === 0 && <DamAvulso />}
          {field === 1 && <AlterarVencimentoAvulso />}
          {field === 2 && <AvulsoBaixas />}
        </MuiThemeProvider>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    field: state.tabLayout.damAvulsoTabSelected
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ damAvulsoTabChanged }, dispatch)
}

DamAvulsoMain = connect(
  mapStateToProps,
  mapDispatchToProps
)(DamAvulsoMain)

export default withStyles(contribuinteStyle)(DamAvulsoMain)
