import axios from "axios"
import { defaultHeaders } from "./Constants"

export function getRelatorio(ano, tipo, imovel, contribuinte, data_inicial, data_final, callBack) {
  let config = {
    headers: defaultHeaders(),
    responseType: "blob"
  }
  let url =
    process.env.REACT_APP_API +
    `relatorios/iptus?ano=${ano}&tipo=${tipo}&imovel_id=${imovel}&contribuinte_id=${contribuinte}&data_inicial=${data_inicial}&data_final=${data_final}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}