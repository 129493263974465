import React from "react";
import { withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import Warning from "@material-ui/icons/Warning";
import Done from "@material-ui/icons/Done";

import AsyncSelect from "../../../components/AsyncSelect";
import { search as searchAlvara } from "../../../util/alvara";
import { showNotification, mapErrors } from "../../../util/Constants";
import numeral from "../../../util/numeral";
import { MenuItem, withStyles } from "@material-ui/core";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import secaoStyle from "../../../assets/css/layout/secaoStyle";
import Button from "../../../components/CustomButtons/Button";
import { showWaiting, hideWaiting } from "../../../util/Constants";

import { alterarVencimento } from "../../../util/alvara";
import housecall from "housecall";

import moment from "moment";
import CardFooter from "../../../components/Card/CardFooter";
import SearchFields from "../../../components/SearchFields";
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import Checkbox from "@material-ui/core/Checkbox";
import { dam } from "../../../util/dam"
import { me } from "../../../util/authApi";

class AlterarVencimentoDam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alvaras: [],
      id_alvara: 0,
      errors: {},
      dams: [],
      id_dam: "",
      descricao: "",
      cidade: "",
      bairro: "",
      valor: "",
      juros: "",
      multa: "",
      data_vencimentoAntiga: "",
      data_vencimentoNova: moment(new Date()).format("YYYY-MM-DD")
    };
    this.loadAlvara = this.loadAlvara.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getAlvaraById = this.getAlvaraById.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.getDam = this.getDam.bind(this);
    this.queue = housecall({ concurrency: 1, cooldown: 0 });
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.imprimirDam = this.imprimirDam.bind(this)
    this.onClickMulta = this.onClickMulta.bind(this)
    this.onClickJuros = this.onClickJuros.bind(this)
  }

  componentDidMount() {
    me((error, data) => {
        var podeAlterar = false;
        if (data) {
            if ([1, 3, 9, 19].includes(data.id)) {
                podeAlterar = true
            }
        }
        this.setState({podeAlterarMultaOuJuros: podeAlterar})
    })
  }

  onClickMulta = () => {
    this.setState({sem_multa: !this.state.sem_multa})
  }
  onClickJuros = () => {
    this.setState({sem_juros: !this.state.sem_juros})
  }

  handleChange(event) {
    let name = event.target.name;
    let errors = this.state.errors;
    errors[name] = undefined;
    this.setState(
      {
        [name]: event.target.value,
        errors: errors
      },
      () => {
        if (this.state.id_alvara && name === "id_alvara") {
          let alvara = this.getAlvaraById(this.state.id_alvara.value);
          this.setState({
            dams: alvara.dams,
            descricao: alvara.tipo.descricao,
            cidade: alvara.contribuinte.cidade,
            bairro: alvara.contribuinte.bairro
          });
        }
        if (this.state.id_dam && name === "id_dam") {
          let dam = this.getDam(this.state.id_dam)
          this.setState({
            valor: dam.valor,
            juros: dam.juros,
            multa: dam.multa,
            data_vencimentoAntiga: dam.vencimento,
            lancadoNaDivida: dam.lancado_na_divida,
            divida_id: dam.divida_id,
            pago: dam.pago === true
          })
        }
      }
    );
  }

  handleSave(event) {
    event.preventDefault();
    showWaiting();
    let response = (error, data) => {
      if (error) {
        hideWaiting();
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        );
        this.setState({
          errors: mapErrors(error)
        });
      } else {
        hideWaiting();
        showNotification(
          "top",
          "center",
          `Data de vencimento alterada realizada com sucesso`,
          "success"
        );

        this.handleOpen()

        //this.props.history.push("/alvara/movimentos");
      }
    };
    alterarVencimento(this.state.id_dam, this.state.data_vencimentoNova, this.state.sem_multa, this.state.sem_juros, response)
  }

  handleOpen() {
    this.setState({ modal: true })
  }

  handleClose() {
    this.setState({ modal: false }, () => { this.props.history.push("/alvara/movimentos"); })
  }

  loadAlvara(inputValue, callback) {
    searchAlvara(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          );
          callback([]);
        } else {
          let alvaras = data.data.map(alvara => {
            return {
              value: alvara.id,
              label: `Insc.:${alvara.id} - Contribuinte: ${alvara.contribuinte
                .nome_razao_social || ""}, Documento: ${alvara.contribuinte
                  .cpf_cnpj || ""}, Tipo: ${alvara.tipo.nome || ""}`
            };
          });
          this.setState({
            alvaras: data.data
          });
          callback(alvaras);
        }
      }

    );
  }

  handleselectedFile = event => {
    this.setState({
      arquivo_de_baixa: event.target.files[0]
    });
  };

  getAlvaraById(id) {
    return this.state.alvaras.filter(item => {
      return id === item.id;
    })[0];
  }

  getDam(id) {
    return this.state.dams.filter(item => {
      return id === item.id;
    })[0];
  }

  imprimirDam() {
    showWaiting()
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    dam(this.state.id_dam, response)
  }

  render() {
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do Alvará" },
      { value: "contribuinte_id", label: "Código do Contribuinte" },
      { value: "contribuinte_nome_fantasia", label: "Nome Fantasia" },
      { value: "contribuinte_nome_razao_social", label: "Razão Social" },
      {
        value: "dados_estabelecimento_endereco",
        label: "Endereço Estabelecimento"
      },
      { value: "dados_veiculo_numero_da_vaga", label: "Nº da Vaga" }
    ];
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Card>
          <CardBody>
            <Typography color="textSecondary" variant="caption">
              Altere a data de vencimento Dam de Alvará
            </Typography>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AsyncSelect
                  className={
                    `form-control form-control-alternative form-select Select-menu-outer ${
                    this.props.error ? "has-danger" : ""
                    }`
                  }
                  value={this.state.id_alvara}
                  name="id_alvara"
                  onChange={this.handleChange}
                  loadOptions={this.loadAlvara}
                  placeholder="Alvará"
                  message="Pesquise o Alvará"
                />
                <SearchFields
                  name="filterField"
                  value={this.state.filterField || ""}
                  onChangeFields={this.handleChange}
                  fields={fields}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="outlined-name"
                  label="Tipo Descrição"
                  fullWidth
                  disabled
                  value={this.state.descricao}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="outlined-name"
                  label="Cidade"
                  fullWidth
                  disabled
                  value={this.state.cidade}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="outlined-name"
                  label="Bairro"
                  fullWidth
                  disabled
                  value={this.state.bairro}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  select
                  required
                  name="id_dam"
                  label="DAMS"
                  fullWidth
                  value={this.state.id_dam}
                  onChange={this.handleChange}
                  error={this.state.errors.dam_id}
                  helperText={this.state.errors.dam_id}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  {this.state.dams.map((option, key) =>
                    !this.state.dams[key].pago ? (
                      <MenuItem key={key} value={option.id}>
                        {option.competencia}
                      </MenuItem>
                    ) : null
                  )}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-name"
                  label="Valor"
                  fullWidth
                  disabled
                  value={`R$ ${numeral(parseFloat(this.state.valor))
                    .format("0.0,00")
                    .slice(0, -2)}`}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="date"
                  label="Data do Vencimento"
                  margin="normal"
                  disabled
                  variant="outlined"
                  type="date"
                  value={this.state.data_vencimentoAntiga}
                  fullWidth
                  helperText={this.state.errors.data_vencimentoAntiga}
                  error={this.state.errors.data_vencimentoAntiga}
                  name="data_vencimentoAntiga"
                  onChange={this.handleChange}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="date"
                  label="Nova Data de Vencimento"
                  margin="normal"
                  variant="outlined"
                  type="date"
                  fullWidth
                  value={
                    this.state.data_vencimentoNova
                  }
                  helperText={this.state.errors.vencimento}
                  error={this.state.errors.vencimento}
                  name="data_vencimentoNova"
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            {this.state.podeAlterarMultaOuJuros ? 
              <div className={'row'}>
                <div className={'col-1'} style={{width: 150}}>
                    <fieldset style={{border: '1px solid #bdbdbd', borderRadius: 4, marginLeft: 10}}>
                        <legend style={{fontSize: 12, color: '#787878', padding: 2, width: 'auto'}}>
                          Sem Multa
                        </legend>
                        <Checkbox
                            checked={this.state.sem_multa}
                            tabIndex={-1}
                            onClick={this.onClickMulta}
                            classes={{
                                checked: classes.checked,
                                root: classes.root
                            }}
                        />
                    </fieldset>
                </div>
                <div className={'col-1'} style={{width: 150}}>
                    <fieldset style={{border: '1px solid #bdbdbd', borderRadius: 4, marginLeft: 10}}>
                        <legend style={{fontSize: 12, color: '#787878', padding: 2, width: 'auto'}}>
                          Sem Juros
                        </legend>
                        <Checkbox
                            checked={this.state.sem_juros}
                            tabIndex={-1}
                            onClick={this.onClickJuros}
                            classes={{
                                checked: classes.checked,
                                root: classes.root
                            }}
                        />
                    </fieldset>
                </div>
            </div> : ""}

          </CardBody>
          <CardFooter>
            <Grid container justify="center">
              <Grid item lg={(this.state.lancadoNaDivida || this.state.pago) ? 6 : 2}>
                <Button
                  block
                  color={this.state.pago ? "success" : (this.state.lancadoNaDivida ? "danger" : "info")}
                  round
                  disabled={this.state.pago || this.state.lancadoNaDivida || !(this.state.id_dam)}
                  onClick={this.handleSave}
                >
                  {(this.state.lancadoNaDivida || this.state.pago) ? (
                    this.state.pago ? (<div>
                      <Done /> O DAM Selecionado já está pago
                      </div>
                    ) : (<div>
                      <div>
                        < Warning /> O DAM Selecionado foi lançado na dívida
                      </div>
                      <div> Código da dívida ativa: {this.state.divida_id}</div>
                    </ div >)
                  ) : (
                      <div>
                        <AddIcon /> Alterar
                    </div>
                    )}
                </Button>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>

        <Dialog
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          style={{ zIndex: "3" }}
        >
          <DialogTitle id="form-dialog-title">Você deseja imprimir o DAM atualizado?</DialogTitle>
          <DialogContent>
            <Grid container justify="center">
              <Grid item lg={4}>
                <Button
                  block
                  color="info"
                  round
                  onClick={this.imprimirDam}
                >
                  Imprimir DAM
                      </Button>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="danger">
              Voltar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
AlterarVencimentoDam = withRouter(AlterarVencimentoDam);
export default withStyles(secaoStyle)(AlterarVencimentoDam);
