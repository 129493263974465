import React from "react"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import AsyncSelect from "../../components/AsyncSelect"
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import moment from "moment";
import CardFooter from "../../components/Card/CardFooter"
import Button from "../../components/CustomButtons/Button"
import secaoStyle from "../../assets/css/layout/secaoStyle"
import { withStyles } from "@material-ui/core"
import NumberFormatCustom from "../../components/NumberFormatCustom";
import { getFields, post, edit, put, search } from "../../util/logradouro"
import { search as getBairros} from "../../util/bairro";

import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../util/Constants"

// import { search } from "../../util/logradouro"
import LoadingContent from "../../components/LoadingContent"

class ProtestoForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      errors: {},
      // Logradouro INFO
      tipo: "",
      nome: "",
      nome_atual: "",
      lei: "",
      nome_anterior: "",
      bairros: [],
      //   LOGRADOURO SELECT
      data_inicio: new Date(),
      data_fim: new Date(),
      valor_minimo_fisica: 0,
      valor_minimo_juridica: 0,
      lei: ""
    }
    // this.getFields = this.getFields.bind(this)
    this.getData = this.getData.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
  }

  componentDidMount() {
    this.getData();
  }

  getData(callback = () => {}) {
    if (this.props.match.params.id) {
      edit(this.props.match.params.id, (error, data) => {
        console.log(error)
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let tipo = data.tipo;
          let nome = data.nome.match(/[^ ]+/g).join(' ');
          if (!data.tipo){
            tipo = nome.split(' ')[0];
            nome = nome.split(' ').slice(1).join(' ');
          }
          let nomeAnterior = data.nome_anterior ? data.nome_anterior : tipo +" "+ nome;
          let bairro = "";
          if (data.bairros) {
            bairro = {
              value: data.bairros.id,
              label: data.bairros.nome
            }
          };
          
          let lograd = {
            id: data.id,
            tipo: tipo,
            nome_atual: nome,
            lei: data.lei,
            nome_anterior: nomeAnterior,
            cod_bairro: bairro
          }
          this.setState({
            id: data.id,
            tipo: tipo,
            nome_atual: nome,
            lei: data.lei,
            nome_anterior: nomeAnterior,
            cod_bairro: bairro
          })
          callback(lograd)
        }})
      }
  }

  loadOptions(inputValue, callback) {

    getBairros(inputValue, undefined, undefined, (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
        callback([])
      } else {
        let bairros = data.data.map(bairro => {
          return { value: bairro.id, label: bairro.nome }
        })
        callback(bairros)
      }
    })
  }


  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: event.target.value,
      errors: errors
    })
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let params = {
      // logradouro
      tipo: this.state.tipo,
      nome: this.state.nome_atual,
      lei: this.state.lei,
      nome_anterior: this.state.nome_anterior,
      bairro_id: this.state.cod_bairro ? this.state.cod_bairro.value : undefined
    }

    let response = error => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          `Seção de Logradouros ${this.props.match.params.id ? "editada" : "incluída"
          } com sucesso`,
          "success"
        )
        this.props.history.push("/iptu/cadastro")
      }
    }
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response)
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }

  render() {
    const { classes } = this.props;
    const typeForm = this.props.history ? this.props.history.location.pathname.split('/')[2] : 'cadastro';
    return (
      <div className={classes.container}>
        <LoadingContent isLoading={this.state.isLoading}>
          <Card>
            <form onSubmit={this.handleSave}>
              <CardHeader color="primary">
                <Grid container direction="row">
                  {
                    typeForm === 'edit' ? 
                      <Grid item lg={10} xs={12}>
                        <h2 className={classes.cardTitleWhite}>
                          Alteração de protesto
                        </h2>
                      </Grid> :
                      <Grid item lg={10} xs={12}>
                        <h2 className={classes.cardTitleWhite}>
                          Cadastro de protesto
                        </h2>
                      </Grid>
                  }
                  
                </Grid>
              </CardHeader>
              <CardBody>
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  variant="caption"
                >
                  Intervalo de Busca
                </Typography>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="date"
                      type="date"
                      variant="outlined"
                      margin="normal"
                      label="Data Início"
                      className={classes.datePicker}
                      error={this.state.errors.data_inicio}
                      helperText={this.state.errors.data_inicio}
                      defaultValue={moment(this.state.data_inicio).format(
                        "YYYY-MM-DD"
                      )}
                      value={this.state.data_inicio}
                      name="data_inicio"
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="date"
                      type="date"
                      variant="outlined"
                      margin="normal"
                      name="data_fim"
                      label="Data Fim"
                      className={classes.datePicker}
                      helperText={this.state.errors.data_fim}
                      value={this.state.data_fim}
                      error={this.state.errors.data_fim}
                      defaultValue={moment(this.state.data_fim).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={this.handleChange}
                    />
                  </Grid>
                </Grid>
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  variant="caption"
                >
                  Valor Mínimo
                </Typography>
                <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="outlined-select-currency"
                      label="Pessoa Física"
                      fullWidth
                      required
                      defaultValue={this.state.valor_minimo_fisica}
                      value={this.state.valor_minimo_fisica}
                      onChange={this.handleChange}
                      name="valor_minimo_fisica"
                      error={this.state.errors.valor_minimo_fisica}
                      helperText={this.state.errors.valor_minimo_fisica}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="outlined-select-currency"
                      label="Pessoa Jurídica"
                      fullWidth
                      required
                      defaultValue={this.state.valor_minimo_juridica}
                      value={this.state.valor_minimo_juridica}
                      onChange={this.handleChange}
                      name="valor_minimo_juridica"
                      error={this.state.errors.valor_minimo_juridica}
                      helperText={this.state.errors.valor_minimo_juridica}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="outlined-select-currency"
                      label="Lei"
                      fullWidth
                      required
                      value={this.state.lei}
                      onChange={this.handleChange}
                      name="lei"
                      multiline
                      rowsMax="5"
                      error={this.state.errors.lei}
                      helperText={this.state.errors.lei}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                
              </CardBody>
              <CardFooter>
                <Grid container justify="center">
                  <Grid item lg={2}>
                    <Button block color="info" round type="submit">
                      <AddIcon />{" "}
                      {this.props.match.params.id ? "Atualizar Remessa" : "Adicionar Remessa"}
                    </Button>
                  </Grid>
                </Grid>
              </CardFooter>
            </form>
          </Card>
        </LoadingContent>
      </div>
    )
  }
}

export default withStyles(secaoStyle)(ProtestoForm)
