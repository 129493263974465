import React from "react"

import { withStyles } from "@material-ui/core"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import MenuDashboard from "../MenuDashboard"

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import theme from "../../assets/css/theme"
import { connect } from "react-redux"
import { cadastrosGeraisTabChanged } from "../../actions"
import Subject from "@material-ui/icons/Subject"
import { bindActionCreators } from "redux"
import UnidadesMonetarias from "./UnidadesMonetarias"
import Juros from "./Juros";
import Multa from "./Multa";
import Agentes from "./Agentes"
import TabelaIPCA from "./TabelaIPCA"
import Receitas from "./Receitas"
const tab = [
  {
    name: "Unidades Monetárias",
    icon: <Subject />
  },
  {
    name: "Juros",
    icon: <Subject />
  },
  {
    name: "Multa",
    icon: <Subject />
  },
  {
    name: "Agentes Recebedores",
    icon: <Subject />
  },
  {
    name: "IPCA",
    icon: <Subject />
  },
  {
    name: "Receitas",
    icon: <Subject />
  }
]

class Gerais extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      pageCount: 0,
      perPage: 0,
      page: 1,
      contribuintes: [],
      field: 0
    }
    this.handleFieldChange = this.handleFieldChange.bind(this)
  }

  handlePageClick(data) {}

  handleChange = event => {
    this.setState({ value: event.target.value })
  }

  handleChangePage = (event, page) => {
    if ((page || 1) !== this.state.page) {
      this.setState({ page }, () => { this.getData(this.state.page) })
    }
  }

  handleFieldChange(value) {
    this.props.cadastrosGeraisTabChanged(value)
  }
  render() {
    const { classes, field } = this.props
    return (
      <div className={classes.container}>
        <MuiThemeProvider theme={theme}>
          <MenuDashboard
            names={tab}
            onChange={this.handleFieldChange}
            value={field}
          />
          {field === 0 && <UnidadesMonetarias />}
          {field === 1 && <Juros />}
          {field === 2 && <Multa />}
          {field === 3 && <Agentes />}
          {field === 4 && <TabelaIPCA />}
          {field === 5 && <Receitas />}
        </MuiThemeProvider>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    field: state.tabLayout.cadastrosGeraisTabSelected
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ cadastrosGeraisTabChanged }, dispatch)
}
Gerais = connect(
  mapStateToProps,
  mapDispatchToProps
)(Gerais)
export default withStyles(contribuinteStyle)(Gerais)
