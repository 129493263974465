import React from "react";

import { withStyles } from "@material-ui/core";
import ShowChart from "@material-ui/icons/ShowChart";
import contribuinteStyle from "../../../assets/css/layout/contribuinteStyle";
import MenuDashboard from "../../MenuDashboard";

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import theme from "../../../assets/css/theme";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { dividaRelatoriosTabChanged } from "../../../actions";
import Demonstrativo from "./Demonstrativo"
import Levantamento from "./Levantamento";
import Lancamento from "./Lancamento";
import {IconLacancamento} from "./icons";
import ListIcon from '@material-ui/icons/List';
import Financiamentos from "./Financiamentos";
import NegociacoesPagamentos from "./NegociacoesPagamentos";

const tab = [
  {
    name: "Demonstrativo",
    icon: <ShowChart />
  },
  {
    name: "Levantamento de Dívida",
    icon: <ShowChart />
  },
  {
    name: "Lançamento na Dívida",
    icon: <IconLacancamento/>
  },
  {
    name: "Refinanciamentos",
    icon: <ListIcon/>
  },
  {
    name: "Pagamentos",
    icon: <ListIcon/>
  },
];

class Relatorios extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      field: 0
    }
    this.handleFieldChange = this.handleFieldChange.bind(this)
  }

  handleFieldChange(value) {
    this.props.dividaRelatoriosTabChanged(value)
  }
  render() {
    const { classes, field } = this.props
    return (
      <div className={classes.container}>
        <MuiThemeProvider theme={theme}>
          <MenuDashboard
            names={tab}
            onChange={this.handleFieldChange}
            value={field}
          />
          {field === 0 && <Demonstrativo />}
          {field === 1 && <Levantamento />}
          {field === 2 && <Lancamento />}
          {field === 3 && <Financiamentos/>}
          {field === 4 && <NegociacoesPagamentos/>}
        </MuiThemeProvider>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    field: state.tabLayout.relatoriosDividaTabSelected
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ dividaRelatoriosTabChanged }, dispatch)
}

Relatorios = connect(
  mapStateToProps,
  mapDispatchToProps
)(Relatorios)
export default withStyles(contribuinteStyle)(Relatorios)
