import React from "react"

import {
  showNotification,
  showWaiting,
  hideWaiting,
  mapErrors
} from "../../../util/Constants"
import logradouroStyle from "../../../assets/css/layout/logradouroStyle"
import { withStyles, MenuItem } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import Button from "../../../components/CustomButtons/Button"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Grid from "@material-ui/core/Grid"
import AdBlockDetect from "react-ad-block-detect"
import { search } from "../../../util/logradouro"
import { get, search as searchContribuinte } from "../../../util/contribuinte"
import AsyncSelect from "../../../components/AsyncSelect"
import SearchFields from "../../../components/SearchFields"
import { relatorioDeImoveis } from "../../../util/imovel"
import { get as getTiposDeOcupacao } from "../../../util/tiposDeOcupacao"

import housecall from "housecall"
import SelectCustom from "../../../components/SelectCustom"


class ImoveisRelatorio extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      modal: false,
      nome: "",
      logradouros: [],
      id_logradouro: "",
      secoesLogradouro: [],
      tiposOcupacao: [],
      ocupacao_id: "",
      secoes: [],
      cod_secao: "",
      group: "list",
      order: "secao",
      status: [
        {value: 0, label: "Todos"}, {value: 1, label: "Ativos"}, {value: 2, label: "Isentos"}, {value: 3, label: "Cancelados"},
      ]
    }
    this.handleChange = this.handleChange.bind(this)
    this.gerarRelatorio = this.gerarRelatorio.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
    this.loadLogradouro = this.loadLogradouro.bind(this)
    this.getSecoesByLogradouro = this.getSecoesByLogradouro.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
    this.getTiposOcupacao = this.getTiposOcupacao.bind(this)
  }

  componentDidMount()
  {
    this.getTiposOcupacao();
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: event.target.value,
      errors: errors
    }, () => {
      if (this.state.id_logradouro && name === "id_logradouro") {
        this.setState({
          secoes: this.getSecoesByLogradouro(this.state.id_logradouro.value)
        })
      }
    })
  }

  getTiposOcupacao(){
    let response = (error, response) => {
      if(error){
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        return; 
      }

      let tipoEdificado = response.data.filter(tipo => tipo.id === 6)[0]
      let tiposTerreno = response.data.filter(tipo => tipo.id !== 6).map(tipo => tipo.id).join(",");

      this.setState({
        tiposOcupacao: [
            {value: tipoEdificado.id, label: tipoEdificado.nome},
            {value: tiposTerreno, label: "Terreno"},
        ]
      });
      // console.log(this.state.tiposOcupacao);
    }

    getTiposDeOcupacao(1, response)
  }

  getSecoesByLogradouro(id) {
    return this.state.logradouros.filter(item => {
      return id === item.id
    })[0].secoes
  }
  gerarRelatorio(event) {
    event.preventDefault()
    showWaiting()
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        // showNotification(
        //   "top",
        //   "center",
        //   "Ocorreram erros, verifique o formulário e tente novamente",
        //   "danger"
        // )
        const reader = new FileReader()
        reader.addEventListener("loadend", e => {
            const text = e.srcElement.result
            try {
                let error = JSON.parse(text)
                // if (error.message) {
                //     showNotification("top", "center", error.message, "warning")
                // }

                this.setState({
                    errors: mapErrors({ response: { data: error } })
                })
            } catch (e) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            }
        })

        reader.readAsText(error.response.data)
      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    relatorioDeImoveis(
      (this.state.contribuinte_id || { value: undefined }).value, 
      this.state.id_secao, 
      (this.state.id_logradouro || { value: undefined }).value, 
      (this.state.ocupacao_id || {value: undefined}).value,
      this.state.distrito || "",
      this.state.quadra || "",
      this.state.setor || "",
      this.state.status_id || "",
      this.state.status_id == 2 ? this.state.ano_processo || "" : "",
      response
    );
  }

  loadLogradouro(inputValue, callback) {
    this.queue.push(() =>
    search(inputValue, undefined, undefined, (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
        callback([])
      } else {
        let lograd = data.data.map(logradouro => {
          return { value: logradouro.id, label: logradouro.tipo ? logradouro.tipo+" "+logradouro.nome : logradouro.nome }
        })
        this.setState({
          logradouros: data.data
        }, () => {
          console.log("LOG: ", this.state.logradouros)
        })
        callback(lograd)
      }
    }) 
    )
  }

  loadOptions(inputValue, callback) {
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let contribuintes = data.data.map(contribuinte => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - ${contribuinte.cpf_cnpj || ""}`
            }
          })
          this.setState({
            contribuintes: data.data
          })
          callback(contribuintes)
        }
      }
    )
  }

  render() {
    const { classes } = this.props
    
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do contribuinte" },
      { value: "cpf_cnpj", label: "Documento" },
      { value: "endereco", label: "Endereço" },
      { value: "nome_fantasia", label: "Nome Fantasia" },
      { value: "nome_razao_social", label: "Razão Social" }
    ]
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>
                  Relatório de Imóveis
                </h2>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Grid container direction="row" style={{ marginTop: "2%" }}>
              <Divider />
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                variant="caption"
              >
                Filtrar relatório por nome de Logradouros ou deixe vazio para a
                opção de TODOS
              </Typography>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <SearchFields
                  name="filterField"
                  value={this.state.filterField || ""}
                  onChangeFields={this.handleChange}
                  fields={fields}
                />
                <Typography color="textSecondary" variant="caption">
                  Pesquise o Contribuinte
                </Typography>
                <AsyncSelect
                  className={`form-control form-control-alternative form-select ${
                    this.props.error ? "has-danger" : ""
                    }${classes.teste}`}
                  value={this.state.contribuinte_id}
                  onChange={this.handleChange}
                  noResultsText="Nenhum resultado"
                  loadOptions={this.loadOptions}
                  loadingMessage={() => "Carregando..."}
                  error={this.state.errors.contribuinte_id}
                  helperText={this.state.errors.contribuinte_id}
                  name="contribuinte_id"
                  placeholder="Contribuinte"
                  message="Pesquise o contribuinte"
                />
                
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16} style={{marginTop: "2%"}}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography color="textSecondary" variant="caption">
                  Pesquise o Logradouro
                </Typography>
                <AsyncSelect
                  className={
                    `form-control form-control-alternative form-select Select-menu-outer ${
                      this.props.error ? "has-danger" : ""
                      }`
                  }
                  noResultsText="Nenhum resultado"
                  loadingMessage={() => "Carregando..."}
                  error={this.state.errors.id_logradouro}
                  helperText={this.state.errors.id_logradouro}
                  value={this.state.id_logradouro}
                  onChange={this.handleChange}
                  loadOptions={this.loadLogradouro}
                  name="id_logradouro"
                  placeholder="Logradouro"
                  message="Pesquise o logradouro"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: "1%"}}>
                  <SelectCustom
                    value={this.state.ocupacao_id}
                    name="ocupacao_id"
                    isMulti={false}
                    isClearable={true}
                    isSearchable={true}
                    onChange={this.handleChange}
                    error={this.state.errors.ocupacao_id}
                    helperText={this.state.errors.ocupacao_id}
                    options={this.state.tiposOcupacao}
                    placeholder="Ocupação"
                    message="Pesquise a Ocupação"
                  />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Typography color="textSecondary" variant="caption">
                Pesquise por
              </Typography>
            </Grid>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <TextField
                  id="outlined-select-currency"
                  name="distrito"
                  label="Distrito"
                  fullWidth
                  value={this.state.distrito}
                  error={this.state.errors.distrito}
                  helperText={this.state.errors.distrito}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  // InputLabelProps={{ shrink: true }}
                  inputProps={{maxLength: 2}}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <TextField
                  id="outlined-select-currency"
                  name="quadra"
                  label="quadra"
                  fullWidth
                  value={this.state.quadra}
                  error={this.state.errors.quadra}
                  helperText={this.state.errors.quadra}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  // InputLabelProps={{ shrink: true }}
                  inputProps={{maxLength: 3}}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <TextField
                  id="outlined-select-currency"
                  name="setor"
                  label="setor"
                  fullWidth
                  value={this.state.setor}
                  error={this.state.errors.setor}
                  helperText={this.state.errors.setor}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  // InputLabelProps={{ shrink: true }}
                  inputProps={{maxLength: 4}}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="outlined-select-currency"
                  select
                  name="status_id"
                  label="Selecione um status"
                  fullWidth
                  value={this.state.status_id}
                  error={this.state.errors.status_id}
                  helperText={this.state.errors.status_id}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                >
                  {this.state.status.map((option, key) => (
                    <MenuItem key={key} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {[2].includes(this.state.status_id) && (
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <TextField
                    id="outlined-select-currency"
                    name="ano_processo"
                    label="Ano de Processo"
                    fullWidth
                    value={this.state.ano_processo}
                    error={this.state.errors.ano_processo}
                    helperText={this.state.errors.ano_processo}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    // InputLabelProps={{ shrink: true }}
                    inputProps={{maxLength: 4}}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-select-currency"
                  select
                  disabled={this.state.secoes.length === 0}
                  name="id_secao"
                  label={this.state.secoes.length === 0 ? "Selecione um logradouro para ver suas seções" : "Selecione uma seção ou deixe em deixe em branco para considerar TODOS"}
                  fullWidth
                  value={this.state.id_secao}
                  error={this.state.errors.secao_id}
                  helperText={this.state.errors.secao_id}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                >
                  {this.state.secoes.map((option, key) => (
                    <MenuItem key={key} value={option.id}>
                      {option.secao}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

          </CardBody>
          <CardFooter>
            <Grid container justify="center">
              <Grid item lg={2}>
                <Button block color="info" round onClick={this.gerarRelatorio}>
                  Gerar
                </Button>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>
        {/* MODAL */}

        <AdBlockDetect>
          <Dialog open={true} aria-labelledby="form-dialog-title">
            <form onSubmit={this.handleSave}>
              <DialogContent>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Typography variant="h5" gutterBottom>
                      Por favor, desabilite o ADBlock para que o relatório seja
                      emitido.
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
            </form>
          </Dialog>
        </AdBlockDetect>
      </div>
    )
  }
}

export default withStyles(logradouroStyle)(ImoveisRelatorio)
