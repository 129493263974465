import React from "react"
import { Link } from "react-router-dom"
import { withStyles, Paper } from "@material-ui/core"
import contribuinteStyle from "../../../assets/css/layout/contribuinteStyle"
import Button from "../../../components/CustomButtons/Button"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import AddIcon from "@material-ui/icons/Add"
import Grid from "@material-ui/core/Grid"
import Table from "../../../components/Table/Table"
import TablePagination from "@material-ui/core/TablePagination"
import { get, search } from "../../../util/livro"
import LoadingContent from "../../../components/LoadingContent"
import {
  showNotification
} from "../../../util/Constants"
import housecall from "housecall"

class LancamentoLivroMain extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      pageCount: 0,
      perPage: 0,
      page: 0,
      isSearch:false,
      lanclivros: [],
      searchText: "",
      field: 0
    }
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.getData = this.getData.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.filter = this.filter.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
    this.setLoadingFalse = this.setLoadingFalse.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.lanclivros !== nextState.lanclivros) {
      return true
    }
    return false
  }

  componentWillMount() {
    this.getData()
  }

  handlePageClick(data) {
    //this.getData(data.selected + 1);
  }

  handleChange = event => {
    this.setState({ filterField: event.target.value }, () => {
      if (this.state.searchActive) {
        this.filter({ target: { value: this.state.searchText } })
      }
    })
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  setLoadingFalse() {
    this.setState({
      isLoading: false
    })
  }
  componentDidMount() {
    this.queue.on("idle", this.setLoadingFalse)
  }
  filter(event,pagina) {
    let searchText = event.target.value
    var newState =  {
      searchText: searchText,
      searchActive: searchText !== "",
      isLoading:true,
      isSearch:true,
    }
    if(pagina == undefined){
      newState['page'] = 0;
    }
    this.setState(
     newState,
      () => {
        if (this.state.searchActive) {

          let filtersCheckbox = this.state.filterField || ""
          this.queue.push(() =>
            search(searchText, filtersCheckbox, undefined, (error, data) => {
              this.setState({isLoading:false,isSearch:false})
              if (error) {
                showNotification(
                  "top",
                  "center",
                  "Ocorreram erros, verifique sua conexão com a internet",
                  "danger"
                )
              } else {
                if (this.state.searchText !== "") {
                  this.setState({
                    lanclivros: data.data,
                    pageCount: data.total,
                    perPage: data.per_page
                  })
                }
              }
            },pagina)
          )
        } else {
          this.getData()
        }
      }
    )
  }
  getData(page) {
    this.setState({isLoading:true,isSearch:true})

    get(page, (error, data) => {
      this.setState({isLoading:false,isSearch:false})
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState(
          {
            lanclivros: data.data,

            pageCount: data.total,
            perPage: data.per_page
          },
          () => {
            // console.log(this.state.lanclivros)
          }
        )
      }
    })
  }

  handleChangePage = (event, page) => {
    var pagina;
    pagina = page + 1;
    this.setState({page: page})
    if(page < 0){
      return;
    }
    if (this.state.searchText.toString().trim() != "") {
      this.filter({target: {value: this.state.searchText}}, pagina, page)
      return;
    }
    this.getData(pagina)
  };

  handleFieldChange(value) {
    this.setState({
      field: value
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container spacing={32} direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Lançamento do Livro</h2>
              </Grid>
              <Grid item lg={2} xs={12}>
                <Link to="/lanclivro/create" style={{ textDecoration: "none" }}>
                  <Button block color="success" round>
                    <AddIcon /> Lançar
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            {/* <Search
              label="Pesquise aqui"
              value={this.state.filterField}
              onChangeText={this.filter}
              onChangeFields={this.handleChange}
              fields={fields}
            /> */}
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  tableHeaderColor="primary"
                  tableHead={["Código", "Ano", "Número"]}
                  tableData={this.state.lanclivros.map((item, key) => {
                    return [item.id + "", item.ano, item.numero, key]
                  })}
                />
                {(
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={this.state.pageCount}
                    rowsPerPage={this.state.perPage?this.state.perPage:0}
                    page={this.state.page?this.state.page:0}
                    backIconButtonProps={
                      this.state.isSearch ?
                          {"aria-label": "Previous Page", disabled: this.state.isSearch} :
                          {"aria-label": "Previous Page"}}
                    nextIconButtonProps={this.state.isSearch ? {
                      "aria-label": "Next Page",
                      disabled: this.state.isSearch
                    } : {
                      "aria-label": "Next Page",
                    }}
                    SelectProps={{
                      native: true
                    }}

                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                )}
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>
      </div>
    )
  }
}

export default withStyles(contribuinteStyle)(LancamentoLivroMain)
