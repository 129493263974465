import React from "react"

import { withStyles } from "@material-ui/core"
import contribuinteStyle from "../../../assets/css/layout/contribuinteStyle"
import MenuDashboard from "../../MenuDashboard"

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import theme from "../../../assets/css/theme"
import { damAvulsoRelatoriosTabChanged } from "../../../actions"
import Assessment from "@material-ui/icons/Assessment"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import RelatorioDamAvulso from "./RelatorioDamAvulso"
import RelatorioEmissaoDamAvulso from "./RelatorioEmissaoDamAvulso"
import RelatorioDamAvulsoEmAberto from "./RelatorioDamAvulsoEmAberto"

const tab = [
    {
        name: "Relatório de Arrecadação de DAM Avulso",
        icon: <Assessment />
    },
    {
        name: "Relatório de Emissão de DAM Avulso",
        icon: <Assessment />
    },
    {
        name: "Relatório de DAM Avulso Em Aberto",
        icon: <Assessment />
    }
]

class RelatorioDam extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: "",
            error: "",
            field: 0
        }
        this.handleFieldChange = this.handleFieldChange.bind(this)
    }

    handleFieldChange(value) {
        this.props.damAvulsoRelatoriosTabChanged(value)
    }
    render() {
        const { classes, field } = this.props
        return (
            <div className={classes.container}>
                <MuiThemeProvider theme={theme}>
                    <MenuDashboard
                        names={tab}
                        onChange={this.handleFieldChange}
                        value={field}
                    />
                    {field === 0 && <RelatorioDamAvulso />}
                    {field === 1 && <RelatorioEmissaoDamAvulso />}
                    {field === 2 && <RelatorioDamAvulsoEmAberto />}
                </MuiThemeProvider>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        field: state.tabLayout.relatoriosDamAvulsoTabSelected
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ damAvulsoRelatoriosTabChanged }, dispatch)
}
RelatorioDam = connect(
    mapStateToProps,
    mapDispatchToProps
)(RelatorioDam)
export default withStyles(contribuinteStyle)(RelatorioDam)
