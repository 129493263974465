import React from "react"
import { useHistory } from "react-router-dom"
import Warning from "@material-ui/icons/Warning"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import moment from "moment"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Button from "../../../components/CustomButtons/Button"
import secaoStyle from "../../../assets/css/layout/secaoStyle"
import { withStyles, Checkbox, Paper } from "@material-ui/core"
import { post, imprimirPagamento, postSimular, consultarDivida } from "../../../util/pagamentoDeDivida"
import { getRemessaDetalhe, updateRemessaDetalhe } from "../../../util/confirmacao"
import { getField } from "../../../util/refinanciamento"
import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../../util/Constants"
import LoadingContent from "../../../components/LoadingContent"
import { search as searchRemessa } from "../../../util/remessa"
import AsyncSelect from "../../../components/AsyncSelect"
import housecall from "housecall"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { contribuinteChanged } from "../../../actions"
import numeral from "../../../util/numeral"

import SearchIcon from "@material-ui/icons/Search"
import DeleteIcon from "@material-ui/icons/Delete"
import { me } from "../../../util/authApi"

class ConfirmacaoForm extends React.Component {
    constructor(props) {
        super(props)
        let remessa = props.remessa
        let remessaState = { remessas: new Array }
        let search = {}
        let dividas = new Array
        if (remessa) {
            // search = {remessa_id: remessa.id, nome: remessa.nome}
            remessaState = {
                remessas: [props.remessa],
                remessa_id: {
                    value: props.remessa.id,
                    label: `${props.remessa.id} - ${props.remessa.nome || ""}`
                }
            }
        }
        this.state = {
            desconto: 0,
            errors: {},
            data_inicio: new Date(),
            data_fim: new Date(),
            valor_minimo_fisica: "",
            valor_minimo_juridica: "",
            data_de_vencimento: new Date(),
            lei: "",
            abragencias: [],
            id_abragencias: [],
            id_refis: "",
            refis: [],
            dividas: dividas,
            usuario_id: null,
            id_divida: 0,
            quantidade_de_parcelas: "",
            historico: "",
            editingItem: 0,
            isLoadingDialog: false,
            modal: false,
            canShow: false,
            parcelasSimulacao: [],
            showWarningModal: false,
            showField: true,
            ...remessaState,
            selectAll: false,
            search: { ...search },
            receitas: [],
            nao_tem_divida: false,
            // Desistencia
            remessas: [],
            remessas_detalhe: [],
            nome: "",
            valor_minimo: 0,
            remessa_id: 0
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleCloseWarning = this.handleCloseWarning.bind(this)
        this.toggleLoadingDialog = this.toggleLoadingDialog.bind(this)
        this.loadOptions = this.loadOptions.bind(this)
        this.getDividaContribuinte = this.getDividaContribuinte.bind(this)
        // this.imprimirCarneRef = this.imprimirCarneRef.bind(this)
        this.queue = housecall({ concurrency: 1, cooldown: 0 })
        this.consultar = this.consultar.bind(this)
        this.checkAll = this.checkAll.bind(this)
        // this.getReceitas = this.getReceitas.bind(this)
        this.getDividas = this.getDividas.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.cleanAdvancedSearch = this.cleanAdvancedSearch.bind(this)
    }

    consultar() {
        this.setState({
            showField: false
        }, () => {
            let id = this.state.dividas.map(item => {
                return item.id
            }).join()
            showWaiting()
            consultarDivida(id, (error, data) => {
                hideWaiting()
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros! Tente novamente",
                        "danger"
                    )
                } else {
                    this.setState({
                        valor_primeira_parcela: parseFloat(data.valor_primeira_parcela)
                    }, () => {
                        this.setState({
                            showField: true
                        })
                    })
                }
            })
        })
    }

    handleClose() {
        this.setState({ modal: false })
    }
    handleCloseWarning() {
        this.setState({ showWarningModal: false })
    }

    componentWillUnmount() {
        this.props.contribuinteChanged(undefined)
    }

    componentDidMount() {
        me((error, data) => {
            if (error) {
                return
            }
            this.setState({ usuario_id: data.id })
        })
        getField((error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                if (data.refis.length > 0) {
                    this.setState({
                        showWarningModal: true,
                        canShow: true
                    })
                } else {
                    this.setState({
                        canShow: true
                    })
                }
            }
        })
        // this.getReceitas()
        if (this.state.remessa_id) {
            this.getDividas()
        }
    }
    getDividas() {
        showWaiting()
        const response = (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                let filter = data.filter(item => {
                    return item.status_id === 1
                })
                let dividas_remessas = filter.map(item => {
                    return { ...item, checkbox: true }
                })
                this.setState({
                    dividas: dividas_remessas,
                    selectAll: true,
                    nao_tem_divida: dividas_remessas.length === 0
                })
            }
            hideWaiting()
        }
        if (!this.state.remessa_id) {
            showNotification(
                "top",
                "center",
                "Contribuinte é obrigatória",
                "warning"
            )
            hideWaiting()
        } else {
            getRemessaDetalhe(this.state.remessa_id.value, response)
        }
    }
    loadOptions(inputValue, callback) {
        searchRemessa(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                    callback([])
                } else {
                    let remessas = data.data.map(remessa => {
                        return {
                            value: remessa.id,
                            label: `${remessa.id} - ${remessa.nome || ""}`
                        }
                    })
                    this.setState({
                        remessas: data.data
                    })
                    callback(remessas)
                }
            }
        )
    }

    toggleLoadingDialog() {
        this.setState(state => ({
            isLoadingDialog: !state.isLoadingDialog
        }))
    }

    handleChangeCheckbox(event) {
        const name = parseInt(event.target.name)
        const checked = event.target.checked
        let newDividas = this.state.dividas
        newDividas.map((item, index, dividas) => {
            if (index === name) {
                item.checkbox = checked
            }
            dividas[index] = { ...item }
        })
        this.setState({
            dividas: newDividas
        })
    }
    checkAll(event) {
        let checked = true
        if (event) {
            checked = event.target.checked
        }
        let newDividas = this.state.dividas
        newDividas.map((item, index, dividas) => {
            item.checkbox = checked
            dividas[index] = { ...item }
        })
        this.setState({
            dividas: newDividas,
            selectAll: checked
        })
    }
    async handleChange(event) {
        const name = event.target.name
        const errors = this.state.errors
        if (name === "parcelas_iguais") {
            await this.setState({
                [name]: event.target.checked,
                errors: errors
            })
        } else {
            await this.setState({
                [name]: event.target.value,
                errors: errors
            })
        }
    }
    async handleSearchChange(event) {
        const name = event.target.name.replace("search_", "")
        if (event.target.value) {
            await this.setState({
                search: { ...this.state.search, [name]: event.target.value }
            })
        } else {
            delete this.state.search[name]
            await this.setState({
                search: { ...this.state.search }
            })
        }
    }
    async cleanAdvancedSearch() {
        await this.setState({
            search: {},
            remessa_id: null,
            dividas: []
        })
        // this.advancedSearch()
    }

    getDividaContribuinte(id) {
        return this.state.contribuintes.find(item => {
            return id === item.id
        })
    }

    handleSave(event) {
        event.preventDefault()

        if (this.state.remessa_id) {
            showWaiting()
            let filter = this.state.dividas.filter(item => {
                return item.checkbox === true
            })
            let dividas = filter.map(item => {
                return item.id
            })

            let params = {
                dividas: dividas,
                remessa_id: this.state.remessa_id.value
            }

            console.log(params)

            let response = (error, data) => {
                if (error) {
                    hideWaiting()
                    let message = "";
                    message = error.response.data.message;
                    showNotification(
                        "top",
                        "center",
                        `Ocorreram erros! ${message}`,
                        "danger"
                    )
                    // this.setState({
                    //     errors: mapErrors(error)
                    // })
                } else {
                    hideWaiting()
                    showNotification(
                        "top",
                        "center",
                        `Pagamento de dívida ${this.props.match.params.id ? "editado" : "cadastrado"
                        } com sucesso`,
                        "success"
                    )
                    this.history.push('/protesto');
                    // this.imprimirCarneRef(data.id)
                }
            }
            updateRemessaDetalhe(params, response)
        }

    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    render() {

        const { classes } = this.props
        return (
            <div className={classes.container}>
                <LoadingContent isLoading={this.state.isLoading}>
                    <div>
                        <LoadingContent isLoading={this.state.canShow === false}>
                            <Card>
                                <form onSubmit={this.handleSave}>
                                    <CardHeader color="primary">
                                        <Grid container direction="row">
                                            <Grid item lg={10} xs={12}>
                                                <h2 className={classes.cardTitleWhite}>
                                                    Confirmação de Remessa
                                                </h2>
                                            </Grid>
                                        </Grid>
                                    </CardHeader>
                                    <CardBody>
                                        <Grid container direction="row" spacing={16}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Typography color="textSecondary" variant="caption">
                                                    Pesquise a Remessa
                                                </Typography>
                                                <AsyncSelect
                                                    className={
                                                        `form-control form-control-alternative form-select Select-menu-outer ${this.props.error ? "has-danger" : ""
                                                        }`
                                                    }
                                                    value={this.state.remessa_id}
                                                    onChange={this.handleChange}
                                                    loadOptions={this.loadOptions}
                                                    error={this.state.errors.remessa_id}
                                                    helperText={this.state.errors.remessa_id}
                                                    name="remessa_id"
                                                    placeholder="Remessa"
                                                    message="Pesquise a remessa"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" spacing={16} justify={"flex-end"}>
                                            <Grid item xs={12} sm={4} md={3} lg={2}>
                                                <Button block color="danger" fullWidth onClick={this.cleanAdvancedSearch}>
                                                    <DeleteIcon /> Limpar Busca
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={3} lg={2}>
                                                <Button block color="primary" fullWidth onClick={this.getDividas}>
                                                    <SearchIcon /> Buscar
                                                </Button>
                                            </Grid>
                                        </Grid>

                                        {this.state.dividas.length > 0 ? (
                                            <div>
                                                <Typography color="textSecondary" variant="caption">
                                                    Selecione os contribuintes que serão incluídos na confirmação da remessa
                                                </Typography>
                                                <div>Selecione todos</div>
                                                <Grid item xs={12} sm={12} md={1} lg={1} alignItems="center"
                                                    style={{ display: "inline-block" }} justify="center">
                                                    <Checkbox
                                                        icon={
                                                            <CheckBoxOutlineBlankIcon fontSize="large" />
                                                        }
                                                        checkedIcon={
                                                            <CheckBoxIcon fontSize="large" />
                                                        }

                                                        onChange={this.checkAll}
                                                        checked={this.state.selectAll}
                                                        name={"Selecionar todos"}
                                                    />
                                                </Grid>
                                                {this.state.dividas.map((item, key) => {
                                                    if (item.status_id !== 2) {
                                                        return (
                                                            <div>
                                                                <Card>
                                                                    <Grid
                                                                        style={{ marginBottom: "15px" }}
                                                                        container
                                                                        direction="row"
                                                                        justify="flex-center"
                                                                        alignItems="center"
                                                                        spacing={8}
                                                                        key={key}
                                                                    >
                                                                        <Grid className={classes.flexInherit} item xs={12}
                                                                            sm={12} md={1} lg={1} alignItems="center"
                                                                            justify="center">
                                                                            <Checkbox
                                                                                icon={
                                                                                    <CheckBoxOutlineBlankIcon
                                                                                        fontSize="large" />
                                                                                }
                                                                                checkedIcon={
                                                                                    <CheckBoxIcon fontSize="large" />
                                                                                }
                                                                                onChange={this.handleChangeCheckbox}
                                                                                value={item.checkbox}
                                                                                checked={item.checkbox}
                                                                                name={key}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12} md={3} lg={8}>
                                                                            <br />
                                                                            <strong>Receita: </strong><span>{item.receita_id} - {item.receita.nome} </span><br />
                                                                            <strong>Contribuinte: </strong><span>{item.contribuinte.nome_razao_social}  </span>
                                                                            <br />
                                                                            <strong>Data de inscrição da dívida: </strong><span>{moment(item.data_inscricao).format('DD/MM/YYYY')}  </span>
                                                                        </Grid>


                                                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                                                            <br />
                                                                            <h3>Total a
                                                                                pagar: {"R$ " + numeral(parseFloat(item.total)).format("0.0,00")
                                                                                    .slice(0, -2)}</h3>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Card>
                                                                {this.state.errors && (
                                                                    <Typography variant="caption"
                                                                        color="error">{this.state.errors[`dividas.${key}`]}</Typography>
                                                                )}
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        ) : (this.state.nao_tem_divida && (
                                            <Grid className={classes.legend} container direction="row"><Warning
                                                className={classes.dot + " " + classes.dotDanger} />
                                                Esta remessa não possui dívida ativa em aberto</Grid>))}
                                    </CardBody>
                                    <CardFooter>
                                        <Grid container justify="center" spacing={8}>
                                            <Grid item lg={2}>
                                                <Button block color="info" round onClick={this.handleSave}>
                                                    Confirmar
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardFooter>
                                </form>
                            </Card>
                        </LoadingContent>
                    </div>
                </LoadingContent>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ contribuinteChanged }, dispatch)
}

function mapStateToProps(state) {
    return {
        contribuinte: state.contribuinte.contribuinte
    }
}

ConfirmacaoForm = connect(mapStateToProps, mapDispatchToProps)(ConfirmacaoForm)
export default withStyles(secaoStyle)(ConfirmacaoForm)
