import React from "react";
import { Link, withRouter } from "react-router-dom";
import { withStyles, Paper } from "@material-ui/core";
import logradouroStyle from "../../../assets/css/layout/logradouroStyle";
import Button from "../../../components/CustomButtons/Button";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import FileCopy from "@material-ui/icons/FileCopy"
import numeral from "../../../util/numeral";
import moment from "moment";

import { showNotification, mapErrors, showWaiting } from "../../../util/Constants";


import Table from "../../../components/Table/Table";
import TablePagination from "@material-ui/core/TablePagination";

import {detalhaRemessa, get as getRemessa, search} from "../../../util/remessa";
import LoadingContent from "../../../components/LoadingContent";
import Search from "../../../components/Search";
import housecall from "housecall";
import Close from "@material-ui/icons/Close"



class RemessaDetalhe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      searchText:"",
      error: "",
      errors: {},
      nome: "",
      pageCount: 0,
      perPage: 0,
      id: 0,
      page: 0,
      modal: false,
      isSearch:false,
      remessas: [],
      remessaDetalhe: [],
      field: 0,
    };
    this.getData = this.getData.bind(this);
    //this.toggleLoading = this.toggleLoading.bind(this);
    this.queue = housecall({ concurrency: 1, cooldown: 0 });
    
  }

  componentWillMount() {
    console.log("Dentro do componentWillMount");
    this.getData();
    
  }
  
  getData() {
    let response = (error, data) => {
      console.log("Dentro do getData" + data);
      
      if (error) {
        
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexÃ£o com a internet",
          "danger"
          )
        } else {
          console.log("Dentro do else do RemessaDetalhe getData")
          //data.remessaDetalhe.id = data.remessaDetalhe.id;
          //data.remessaDetalhe.receita.nome = data.remessaDetalhe.receita.nome;
          //data.remessaDetalhe.contribuinte.nome = data.remessaDetalhe.contribuinte.nome;
          //data.remessaDetalhe.divida.id = data.remessaDetalhe.divida.id;
          //data.remessaDetalhe.status.nome = data.remessaDetalhe.status.nome;
          
          this.setState({

            remessaDetalhe: data

          })
        }
        console.log("Apos o metodo detalhRemessa " + data.contribuinte_id);
        //detalhaRemessa(this.props.match.params.id, response);  
      }
   // console.log("Final do getData");
    //detalhaRemessa(this.props.match.params.id, response);   
  }

toggleLoading() {
  this.setState(state => ({
    isLoading: !state.isLoading
  }))
}

getData() {
  this.toggleLoading()
  detalhaRemessa(this.props.match.params.id, (error, data) => {
    this.toggleLoading()
    if (error) {
      showNotification(
        "top",
        "center",
        "Ocorreram erros, verifique o formulário e tente novamente",
        "danger"
      )
    } else {
      console.log("Valor da data: " . data);
      this.setState({
        remessaDetalhe: data,
        
      });
      console.log("Final - apos retorna do back" + this.remessaDetalhe);
    }

        
  });
}


  render() {
    const { classes } = this.props;
    const fields = [];
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}> Detalhe da Remessa</h2>
              </Grid>
              <Grid item lg={2} xs={12}>
              
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  tableHeaderColor="primary"
                  actions={[
                    {
                        title: "Remover",
                        icon: (
                            <Close
                                className={
                                    classes.tableActionButtonIcon +
                                    " " +
                                    classes.edit
                                }
                            />
                        ),
                        onClick: this.areUSure
                    }

                ]}
                  tableHead={["Remessa", "Contribuinte", "Receita", "Valor da Divida", "Status da Remessa", "Cartório"]}
                  tableData={this.state.remessaDetalhe.map((item, key) => {
                    let nomeCartorio;
                    console.log("nome do cartorio : " + item.cartorio);
                    if(item.cartorio === null){
                      nomeCartorio = " - ";
                    }
                    else{
                      nomeCartorio = item.cartorio.nome;
                    }

                    return [
                      item.remessa_id, 
                      item.contribuinte.nome_razao_social,
                      item.receita.nome,
                      item.divida.valor,
                      item.status.nome,
                      nomeCartorio,
                      key
                    ];
                  })}
                />
               

              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>
      </div>
    );
  }
}
//RemessaDetalhe = withRouter(RemessaDetalhe);
export default withStyles(logradouroStyle)(RemessaDetalhe);
