import React from "react"
import { Link } from "react-router-dom"
import { withStyles, Paper } from "@material-ui/core"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import Button from "../../components/CustomButtons/Button"
import Close from "@material-ui/icons/Close"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import AddIcon from "@material-ui/icons/Add"
import Grid from "@material-ui/core/Grid"
import Table from "../../components/Table/Table"
import TablePagination from "@material-ui/core/TablePagination"
import Edit from "@material-ui/icons/Edit"
import Description from "@material-ui/icons/Description"
import DeviceHub from "@material-ui/icons/DeviceHub"
import Print from "@material-ui/icons/Print"
import { get, search, ficha, destroy, debitos, unificar } from "../../util/contribuinte"
import LoadingContent from "../../components/LoadingContent"
import Search from "../../components/Search"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import AsyncSelect from "../../components/AsyncSelect"
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import { simpleSearch as searchContribuinte } from "../../util/contribuinte"
import SearchFields from "../../components/SearchFields"
import {
  showNotification,
  hideWaiting,
  showWaiting
} from "../../util/Constants"
import housecall from "housecall"
class Contribuinte extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      pageCount: 0,
      perPage: 0,
      page: 0,
      isSearch:false,
      contribuintes: [],
      searchText: "",
      field: 0,
      modalRemover: false,
      errors: [],
      contribuinte_antigo_id: {},
      id: ""
    }
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.getFicha = this.getFicha.bind(this)
    this.debitos = this.debitos.bind(this)
    this.getData = this.getData.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleCommomInputChange = this.handleCommomInputChange.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.filter = this.filter.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
    this.setLoadingFalse = this.setLoadingFalse.bind(this)
    this.openRemover = this.openRemover.bind(this)
    this.closeRemover = this.closeRemover.bind(this)
    this.remover = this.remover.bind(this)
    this.closeUnificacaoModal = this.closeUnificacaoModal.bind(this)
    this.openUnificacaoModal = this.openUnificacaoModal.bind(this)
    this.unificar = this.unificar.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
  }

  remover() {
    showWaiting()
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros! Tente novamente",
          "danger"
        )
      } else {
        showNotification(
          "top",
          "center",
          "Contribuinte removido com sucesso!",
          "success"
        )
        this.closeRemover()
        this.getData()
      }
    }
    destroy(this.state.id, response)
  }



  openRemover(key) {
    this.setState({
      modalRemover: true,
      id: this.state.contribuintes[key].id
    })
  }

  closeRemover() {
    this.setState({
      modalRemover: false
    })
  }

  componentWillMount() {
    this.getData()
  }

  handlePageClick(data) {
    //this.getData(data.selected + 1);
  }

  handleChange = event => {
    this.setState({ filterField: event.target.value }, () => {
      if (this.state.searchActive) {
        this.filter({ target: { value: this.state.searchText } })
      }
    })
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  setLoadingFalse() {
    this.setState({
      isLoading: false
    })
  }
  componentDidMount() {
    this.queue.on("idle", this.setLoadingFalse)
  }
  filter(event,pagina) {
    let searchText = event.target.value
    var newState =    {
      isLoading:true,
      searchText: searchText,
      searchActive: searchText !== ""
    }
    if(pagina == null){
      newState["page"] = 0
    }
    this.setState(newState
   ,
      () => {
        if (this.state.searchActive) {
          // if (!this.state.isLoading) {
          //   this.toggleLoading()
          // }
          let filtersCheckbox = this.state.filterField || ""
          // this.queue.push(() =>
          search(searchText, filtersCheckbox, undefined, (error, data) => {
            this.setState({isLoading:false})
            if (error) {
              showNotification(
                "top",
                "center",
                "Ocorreram erros, verifique sua conexão com a internet",
                "danger"
              )
            } else {
              if (this.state.searchText !== "") {
                this.setState({
                  contribuintes: data.data,
                  pageCount: data.total,
                  perPage: data.per_page,
                 
                })
              }
            }
          },pagina)
          // )
        } else {
          this.getData()
        }
      }
    )
  }
  handleCommomInputChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    let value = event.target.value
    errors[name] = undefined
    this.setState(state => ({
      [name]: value,
      errors: { ...state.errors, [name]: undefined }
    }))
  }
  getData(page) {
  this.setState({isLoading:true})
 
    get(page, (error, data) => {
      this.setState({isLoading:false})
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState({
          contribuintes: data.data,
          pageCount: data.total,
          perPage: data.per_page,
          
        })
      }
    })
  }
  getFicha(index) {
    showWaiting()
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    let contribuinte = this.state.contribuintes[index]
    ficha(contribuinte ? contribuinte.id : contribuinte, response)
  }
  openUnificacaoModal(index) {
    this.setState({
      showUnificacaoModal: true,
      contribuinteUnificacao: this.state.contribuintes[index],
      contribuinte_antigo_id: {}
    })
  }
  closeUnificacaoModal() {
    this.setState({
      showUnificacaoModal: false
    })
  }
  debitos(index) {
    showWaiting()
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    let contribuinte = this.state.contribuintes[index]
    debitos(contribuinte.id, response)
  }







  handleChangePage = (event, page) => {
    var pagina;
    pagina = page + 1;
    this.setState({page: page})
    if(page < 0){
      return;
    }
    if (this.state.searchText.toString().trim() != "") {
      this.filter({target: {value: this.state.searchText}}, pagina, page)
      return;
    }
    this.getData(pagina)
  };

  unificar() {
    const contribuinte = this.state.contribuinteUnificacao.id
    const contribuinteAntigo = this.state.contribuinte_antigo_id.value
    unificar(contribuinte, contribuinteAntigo, (error) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        showNotification(
          "top",
          "center",
          "As incrições foram unificadas com sucesso!",
          "success"
        )
        this.closeUnificacaoModal()
        this.getData()
      }
    })
  }

  handleEdit(key) {
    this.props.history.push(
      "/contribuintes/cadastro/edit/" + this.state.contribuintes[key].id
    )
  }

  handleFieldChange(value) {
    this.setState({
      field: value
    })
  }
  loadOptions(inputValue, callback) {
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let contribuintes = data.data.map(contribuinte => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - ${contribuinte.cpf_cnpj || ""}`
            }
          })
          callback(contribuintes)
        }
      }
    )
  }
  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do contribuinte" },
      { value: "cpf_cnpj", label: "Documento" },
      { value: "endereco", label: "Endereço" },
      { value: "nome_fantasia", label: "Nome Fantasia" },
      { value: "nome_razao_social", label: "Razão Social" }
    ]
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container spacing={32} direction="row">
              <Grid item lg={8} xs={12}>
                <h2 className={classes.cardTitleWhite}>Contribuintes</h2>
              </Grid>
              <Grid item lg={2} xs={12}>
                <Button onClick={this.getFicha} block color="info" round>
                  <Print /> Ficha
                </Button>
              </Grid>
              <Grid item lg={2} xs={12}>
                <Link
                  to="/contribuintes/cadastro/create"
                  style={{ textDecoration: "none" }}
                >
                  <Button block color="success" round>
                    <AddIcon /> Adicionar
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Search
              label="Pesquise aqui"
              value={this.state.filterField}
              onChangeText={this.filter}
              onChangeFields={this.handleChange}
              fields={fields}
            />
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  tableHeaderColor="primary"
                  actions={[
                    {
                      title: "Editar",
                      icon: (
                        <Edit
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      ),
                      onClick: this.handleEdit
                    },
                    {
                      title: "Imprimir Ficha Contribuinte",
                      icon: (
                        <Print
                          className={
                            classes.tableActionButtonIcon + " " + classes.ficha
                          }
                        />
                      ),
                      onClick: this.getFicha
                    },
                    {
                      title: "Imprimir Histórico de Lançamentos",
                      icon: (
                        <Description
                          className={
                            classes.tableActionButtonIcon + " " + classes.debitos
                          }
                        />
                      ),
                      onClick: this.debitos
                    }, {
                      title: "Unificar inscrição duplicada",
                      icon: (
                        <DeviceHub
                          className={
                            classes.tableActionButtonIcon + " " + classes.debitos
                          }
                        />
                      ),
                      onClick: this.openUnificacaoModal
                    },
                    {
                      title: "Remover",
                      icon: (
                        <Close
                          className={
                            classes.tableActionButtonIcon + " " + classes.close
                          }
                        />
                      ),
                      onClick: this.openRemover
                    },
                  ]}
                  tableHead={[
                    "Código",
                    "Nome Razão Social",
                    "Nome Fantasia",
                    "CPF/CNPJ",
                    "Endereço",
                    "Bairro",
                    "Cidade",
                    "Ações"
                  ]}
                  tableData={this.state.contribuintes.map((item, key) => {
                    return [
                      item.id + "",
                      item.nome_razao_social,
                      item.nome_fantasia,
                      item.cpf_cnpj,
                      item.endereco,
                      item.bairro,
                      item.cidade,
                      key
                    ]
                  })}
                />


                { (
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={this.state.pageCount}
                    rowsPerPage={parseInt(this.state.perPage)}
                    page={this.state.page}
                    backIconButtonProps={
             
                          {"aria-label": "Previous Page"}}
                    nextIconButtonProps={ {
                      "aria-label": "Next Page",
                    }}

                    SelectProps={{
                      native: true
                    }}

                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                )}
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>

        {/* dialog confirmacao */}
        <Dialog
          open={this.state.modalRemover}
          onClose={this.closeRemover}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: "3" }}
        >
          <DialogTitle id="alert-dialog-title">Você tem certeza que deseja remover esse contribuinte?</DialogTitle>
          <DialogContent>
            <Typography variant="h5" gutterBottom color="error">
              Você irá excluir os seguintes serviços ligados ao contribuinte: alvará, DAM Avulso, dívida ativa, ficha etc. Deseja continuar?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeRemover} color="danger">
              Cancelar
            </Button>
            <Button onClick={this.remover} color="success">
              Remover
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.showUnificacaoModal}
          onClose={this.closeUnificacaoModal}
          maxWidth="lg"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: "3" }}
        >
          <DialogTitle id="alert-dialog-title">Unificação de contribuintes</DialogTitle>
          <DialogContent>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>

                <SearchFields
                  name="modalFilterField"
                  value={this.state.modalFilterField || ""}
                  onChangeFields={this.handleCommomInputChange}
                  fields={fields}
                />
                <Typography gutterBottom color="error">
                  Selecione o contribuinte duplicado de {(this.state.contribuinteUnificacao || {}).id} - {(this.state.contribuinteUnificacao || {}).nome_razao_social}
                </Typography>
                <AsyncSelect
                  className={
                    `form-control form-control-alternative form-select ${
                    this.props.error ? "has-danger" : ""
                    }`
                  }
                  value={this.state.contribuinte_antigo_id}
                  onChange={this.handleCommomInputChange}
                  noResultsText="Nenhum resultado"
                  loadOptions={this.loadOptions}
                  loadingMessage={() => "Carregando..."}
                  error={this.state.errors.contribuinte_antigo_id}
                  helperText={this.state.errors.contribuinte_antigo_id}
                  name="contribuinte_antigo_id"
                  placeholder="Pesquisar"
                  message="Pesquise o contribuinte"
                />
              </Grid>
            </Grid>
            <Typography variant="h5" gutterBottom color="error">
              ESTA É UMA TAREFA IRREVERSÍVEL, TENHA CERTEZA DE QUE AS DUAS INSCRIÇÕES SÃO DUPLICADAS. CONTINUAR?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeUnificacaoModal} color="danger">
              Cancelar
            </Button>
            <Button onClick={this.unificar} color="success">
              Continuar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(contribuinteStyle)(Contribuinte)
