import React from "react";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
  withStyles,
  MenuItem,
  Switch,
  FormControlLabel,
  FormGroup
} from "@material-ui/core";
import InputMask from "react-input-mask";
import moment from "moment";

import logradouroStyle from "../../../assets/css/layout/logradouroStyle";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
import AsyncSelect from "../../../components/AsyncSelect";
import Button from "../../../components/CustomButtons/Button";
import { search as searchContribuinte } from "../../../util/contribuinte";
import { search as searchLogradouro } from "../../../util/logradouro";
import { getField, post, imprimirDams } from "../../../util/geracaoDam";
import {
  showNotification,
  mapErrors,
  showWaiting,
  hideWaiting
} from "../../../util/Constants";
import LoadingContent from "../../../components/LoadingContent";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";

class GeracaoDam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ano: "",
      mes: "",
      mask: "9999/99",
      data_vencimento: "",
      errors: {},
      tipos_alvara: [],
      id_tipos_alvara: "",
      data_validade: "",
      condicao: false,
      taxas: [],
      id_logradouro: [],
      id_taxas: "",
      contribuintes: [],
      id_contribuintes: 0,
      modal: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.loadLogradouro = this.loadLogradouro.bind(this);
    this.getTaxaById = this.getTaxaById.bind(this);
    this.getFields = this.getFields.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.imprimir = this.imprimir.bind(this);
  }

  componentWillMount() {
    this.getFields();
  }

  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }));
  }

  getFields() {
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        );
      } else {
        this.setState({
          tipos_alvara: data
        });
        this.setState(data);
      }
    };
    getField(response);
  }

  handleChange(event) {
    let name = event.target.name;
    let errors = this.state.errors;
    errors[name] = undefined;
    this.setState(
      {
        [name]: event.target.value,
        errors: errors
      },
      () => {
        if (this.state.id_tipos_alvara && name === "id_tipos_alvara") {
          let tipoAlvara = this.getTaxaById(this.state.id_tipos_alvara);
          this.setState({
            taxas: tipoAlvara.taxas
          });
        }
      }
    );
  }

  imprimir(event) {
    showWaiting();
    event.preventDefault();
    let params = {
      tipo_id: this.state.id_tipos_alvara,
      ano: this.state.ano,
      mes: this.state.mes,
      taxa_id: this.state.id_taxas,
      contribuinte_id: this.state.id_contribuintes.value,
      logradouro_id: this.state.id_logradouro.value
    };
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        );
        this.setState({
          errors: mapErrors(error)
        });
      } else {
        hideWaiting();
        var url = URL.createObjectURL(data);
        let tempLink = document.createElement("a");
        tempLink.setAttribute("target", "_blank");
        tempLink.setAttribute("href", `${url}`);
        tempLink.setAttribute("style", "display:none;");
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      }
    };
    imprimirDams(params, response);
  }

  loadOptions(inputValue, callback) {
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          );
          callback([]);
        } else {
          let contribuintes = data.data.map(contribuinte => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - ${contribuinte.cpf_cnpj || ""}`
            };
          });
          this.setState({
            contribuintes: data.data
          });
          callback(contribuintes);
        }
      }
    );
  }

  loadLogradouro(inputValue, callback) {
    searchLogradouro(inputValue, undefined, undefined, (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        );
        callback([]);
      } else {
        let lograd = data.map(logradouro => {
          return { value: logradouro.id, label: logradouro.tipo ? logradouro.tipo+ " "+logradouro.nome : logradouro.nome };
        });
        callback(lograd);
      }
    });
  }

  handleChangeSwitch = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  getTaxaById(id) {
    return this.state.tipos_alvara.filter(item => {
      return id === item.id;
    })[0];
  }

  handleOpen = () => {
    this.setState({ modal: true });
  };

  handleClose = () => {
    this.setState({ modal: false });
  };

  handleSave(event) {
    event.preventDefault();
    showWaiting();
    let params = {
      tipo_id: this.state.id_tipos_alvara,
      ano: this.state.ano,
      mes: this.state.mes,
      taxa_id: this.state.id_taxas,
      contribuinte_id: this.state.id_contribuintes.value,
      historico: this.state.historico_dam,
      observacao: this.state.observacao,
      vencimento: this.state.data_vencimento,
      validade: this.state.data_validade
    };
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        );
        this.setState(
          {
            errors: mapErrors(error)
          },
          () => {
            hideWaiting();
          }
        );
      } else {
        hideWaiting();
        this.setState({
          modal: true
        });
      }
    };
    post(params, response);
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <LoadingContent isLoading={this.state.isLoading}>
          <Card>
            <CardHeader color="primary">
              <Grid container direction="row">
                <Grid item lg={10} xs={12}>
                  <h2 className={classes.cardTitleWhite}>
                    Geração DAM de Alvará Automático
                  </h2>
                </Grid>
              </Grid>
            </CardHeader>
            <CardBody>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <InputMask
                    mask="9999"
                    value={this.state.ano}
                    onChange={this.handleChange}
                  >
                    {() => (
                      <TextField
                        id="outlined-name"
                        required
                        label="Ano"
                        name="ano"
                        helperText={this.state.errors.ano}
                        fullWidth
                        error={this.state.errors.ano}
                        value={this.state.ano}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <InputMask
                    mask="99"
                    value={this.state.mes}
                    onChange={this.handleChange}
                  >
                    {() => (
                      <TextField
                        id="outlined-name"
                        required
                        label="Mês"
                        name="mes"
                        helperText={this.state.errors.mes}
                        fullWidth
                        error={this.state.errors.mes}
                        value={this.state.mes}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="date"
                    label="Data do Vencimento"
                    margin="normal"
                    variant="outlined"
                    type="date"
                    value={this.state.data_vencimento}
                    fullWidth
                    helperText={this.state.errors.vencimento}
                    error={this.state.errors.vencimento}
                    defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                    name="data_vencimento"
                    onChange={this.handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    required
                    name="id_tipos_alvara"
                    label="Tipos de Alvará"
                    fullWidth
                    value={this.state.id_tipos_alvara}
                    onChange={this.handleChange}
                    error={this.state.errors.id_tipos_alvara}
                    helperText={this.state.errors.id_tipos_alvara}
                    margin="normal"
                    variant="outlined"
                  >
                    {this.state.tipos_alvara.map((option, key) => (
                      <MenuItem key={key} value={option.id}>
                        {option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="date"
                      label="Data da Validade"
                      margin="normal"
                      variant="outlined"
                      type="date"
                      value={this.state.data_validade}
                      fullWidth
                      helperText={this.state.errors.validade}
                      error={this.state.errors.validade}
                      defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                      name="data_validade"
                      onChange={this.handleChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid> */}
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    label="Histórico do DAM"
                    margin="normal"
                    multiline
                    rowsMax={4}
                    variant="outlined"
                    fullWidth
                    helperText={this.state.errors.historico_dam}
                    error={this.state.errors.historico_dam}
                    name="historico_dam"
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16} justify="center">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="outlined-select-currency"
                    name="observacao"
                    label="Observação"
                    fullWidth
                    multiline
                    rowsMax="3"
                    error={this.state.errors.observacao}
                    helperText={this.state.errors.observacao}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.condicao}
                          onChange={this.handleChangeSwitch("condicao")}
                          value={true}
                        />
                      }
                      label="Aplicar Condição (Opcional)"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <AsyncSelect
                    className={
                      `form-control form-control-alternative form-select Select-menu-outer ${
                      this.props.error ? "has-danger" : ""
                      }`
                    }
                    value={this.state.id_contribuintes}
                    onChange={this.handleChange}
                    loadOptions={this.loadOptions}
                    name="id_contribuintes"
                    placeholder="Contribuinte"
                    message="Pesquise o contribuinte"
                    error={this.state.errors.contribuinte_id}
                    helperText={this.state.errors.contribuinte_id}
                    disabled={!this.state.condicao}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <AsyncSelect
                    className={
                      `form-control form-control-alternative form-select Select-menu-outer ${
                      this.props.error ? "has-danger" : ""
                      }`
                    }
                    value={this.state.id_logradouro}
                    onChange={this.handleChange}
                    loadOptions={this.loadLogradouro}
                    error={this.state.errors.logradouro_id}
                    helperText={this.state.errors.logradouro_id}
                    name="id_logradouro"
                    placeholder="Logradouro"
                    message="Pesquise o logradouro"
                    disabled={!this.state.condicao}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    required
                    name="id_taxas"
                    label="Taxas"
                    fullWidth
                    disabled={this.state.id_tipos_alvara === 0}
                    value={this.state.id_taxas}
                    onChange={this.handleChange}
                    error={this.state.errors.id_taxas}
                    helperText={this.state.errors.id_taxas}
                    margin="normal"
                    variant="outlined"
                  >
                    {this.state.taxas.map((option, key) => (
                      <MenuItem key={key} value={option.id}>
                        {option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    label="Descrição de taxa"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    disabled
                    helperText={this.state.errors.descricao_taxa}
                    error={this.state.errors.descricao_taxa}
                    name="descricao_taxa"
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
            </CardBody>

            <CardFooter>
              <Grid container justify="center">
                <Grid item lg={2}>
                  <Button block color="info" round onClick={this.handleSave}>
                    <AddIcon /> Gerar
                  </Button>
                </Grid>
              </Grid>
            </CardFooter>
          </Card>
        </LoadingContent>
        {/* DIALOG */}
        <Dialog
          id="body"
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          style={{ zIndex: "901" }}
        >
          <form onSubmit={this.handleSave}>
            <DialogTitle id="form-dialog-title">
              DAM GERADO COM SUCESSO!
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={16} justify="center">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button onClick={this.imprimir} color="success" fullWidth>
                    Imprimir DAM
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
            {/* <DialogActions>
              <Button onClick={this.handleClose} color="danger">
                Cancelar
              </Button>
            </DialogActions> */}
          </form>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(logradouroStyle)(GeracaoDam);
