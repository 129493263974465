import axios from "axios"
import { defaultHeaders } from "./Constants"

export function get(page, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `cancelamento${page ? `?page=${page}` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getRemessaCusta(page, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `cancelamentoCusta${page ? `?page=${page}` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function post(params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  console.log("dentro da javascript")
  console.log(params);
  let url = process.env.REACT_APP_API + "cancelamento"
  axios
    .post(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
    
}

export function getCancelamentoPorContribuinte(contribuinteId, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `search/cancelamentoPorContribuinte/${contribuinteId}`
  axios.get(url, config)
    .then(response => {
      callBack(undefined, response.data)

    })
    .catch(error => {
      callBack(error)
    })
}


export function cancelarTitulo(params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  console.log("dentro da javascript")
  console.log(params)
  let url = process.env.REACT_APP_API + "cancelarTitulo"
  axios
    .post(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function cancelarTituloComCusta(params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  console.log("dentro da javascript")
  console.log(params)
  let url = process.env.REACT_APP_API + "cancelarTituloComCusta"
  axios
    .post(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

// export function updateRemessaDetalhe(params, callBack) {
//   let config = {
//     headers: defaultHeaders()
//   }
//   let url = process.env.REACT_APP_API + "desistencia"
//   axios
//     .post(url, params, config)
//     .then(response => {
//       callBack(undefined, response.data)
//     })
//     .catch(error => {
//       callBack(error)
//     })
// }

// export function edit(id, callBack) {
//   let config = {
//     headers: defaultHeaders()
//   }
//   let url = process.env.REACT_APP_API + `desistencia/${id}/edit`
//   axios
//     .get(url, config)
//     .then(response => {
//       callBack(undefined, response.data)
//     })
//     .catch(error => {
//       callBack(error)
//     })
// }

export function getRemessaDetalhe(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `search/remessaDetalhe?id=${id}`
  axios.get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getDesistenciaPorContribuinte(contribuinteId, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `search/cancelamentoPorContribuinte/${contribuinteId}`
  axios.get(url, config)
    .then(response => {
      callBack(undefined, response.data)

    })
    .catch(error => {
      callBack(error)
    })


}

export function search(searchText, field, onStart, callBack, pagina) {
  let config = {
    headers: defaultHeaders(),
    data: { a: "a" }
  }
  let url = process.env.REACT_APP_API + `search/desistencia?text=${searchText}&fields=${field}`

  if (pagina != null) {
    url += `&page=${pagina}`
  }
  if (onStart) {
    onStart()
  }
  return axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}