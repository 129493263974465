import React from "react"

import { withStyles } from "@material-ui/core"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import MenuDashboard from "../MenuDashboard"

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import theme from "../../assets/css/theme"
import { connect } from "react-redux"
import { cadastrosTabChanged } from "../../actions"
import Home from "@material-ui/icons/Home"
import AssignmentReturn from "@material-ui/icons/AssignmentReturnOutlined"
import Confirm from "@material-ui/icons/AssignmentTurnedIn"
import MoneyOff from "@material-ui/icons/MoneyOff"
import MonetizationOn from "@material-ui/icons/MonetizationOn"
import TrendingDown from "@material-ui/icons/TrendingDown"
import { bindActionCreators } from "redux"
import Protesto from "./Protesto"
import Remessa from './Remessa/Remessa';
import Desistencia from "./Desistencia/Desistencia";
import DesistenciaForm from "./Desistencia/DesistenciaForm"
import Confirmacao from "./Confirmacao/Confirmacao"
import Cancelamento from "./Cancelamento/Cancelamento"
import CancelamentoCusta from "./Cancelamento/CancelamentoCusta"
import Retorno from "./Retorno/Retorno"
import Assessment from "@material-ui/icons/Assessment"


const tab = [
  {
    name: "Remessas",
    icon: <Home />
  },
  {
    name: "Confirmação",
    icon: <Confirm />
  },
  {
    name: "Desistência",
    icon: <AssignmentReturn />
  },
  {
    name: "Cancelamento sem Custa",
    icon: <MoneyOff />
  },
  {
    name: "Cancelamento com Custa",
    icon: <MonetizationOn />
  },
  {
    name: "Retorno",
    icon: <TrendingDown />
  },
  {
    name: "Relatorio",
    icon: <Assessment />
  }
]

class ProtestoList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      perPage: 0,
      page: 1,
      contribuintes: [],
      field: 0
    }
    this.handleFieldChange = this.handleFieldChange.bind(this)
  }

  handlePageClick(data) { }

  handleChange = event => {
    this.setState({ value: event.target.value })
  }

  // handleChangePage = (event, page) => {
  //   if ((page || 1) !== this.state.page) {
  //     this.setState({ page }, () => { this.getData(this.state.page) })
  //   }
  // }

  handleFieldChange(value) {
    this.props.cadastrosTabChanged(value)
  }
  
  render() {
    const { classes, field } = this.props
    return (
      <div className={classes.container}>
        <MuiThemeProvider theme={theme}>
          <MenuDashboard
            names={tab}
            onChange={this.handleFieldChange}
            value={field}
          />
          {field === 0 && <Remessa />}
          {field === 1 && <Confirmacao />}
          {field === 2 && <Desistencia />}
          {field === 3 && <Cancelamento />}
          {field === 4 && <CancelamentoCusta />}
          {field === 5 && <Retorno />}
        </MuiThemeProvider>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    field: state.tabLayout.cadastrosTabSelected
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ cadastrosTabChanged }, dispatch)
}
ProtestoList = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProtestoList)
export default withStyles(contribuinteStyle)(ProtestoList)
