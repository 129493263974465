import React from "react"

import { withStyles } from "@material-ui/core"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import MenuDashboard from "../MenuDashboard"

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import theme from "../../assets/css/theme"
import LocationCity from "@material-ui/icons/LocationCity"
import GetApp from "@material-ui/icons/GetApp"

import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { ocupacaoTabChanged } from "../../actions"
import OcupacaoMain from "./OcupacaoMain"
import AlterarVencDamOc from "./AlterarVencDamOc"
import BaixaOcupacao from "./BaixaOcupacao"

const tab = [
  {
    name: "Cadastro",
    icon: <LocationCity />
  },
  {
    name: "Alterar Vencimento DAM de Ocupação",
    icon: <LocationCity />
  },
  {
    name: "Baixas",
    icon: <GetApp />
  }
]

class Ocupacao extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      field: 0
    }

    this.handleFieldChange = this.handleFieldChange.bind(this)
  }

  handleFieldChange(value) {
    this.props.ocupacaoTabChanged(value)
  }

  render() {
    const { classes, field } = this.props
    return (
      <div className={classes.container}>
        <MuiThemeProvider theme={theme}>
          <MenuDashboard
            names={tab}
            onChange={this.handleFieldChange}
            value={field}
          />
          {field === 0 && <OcupacaoMain />}
          {field === 1 && <AlterarVencDamOc />}
          {field === 2 && <BaixaOcupacao />}
        </MuiThemeProvider>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    field: state.tabLayout.ocupacaoTabSelected
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ocupacaoTabChanged }, dispatch)
}

Ocupacao = connect(
  mapStateToProps,
  mapDispatchToProps
)(Ocupacao)

export default withStyles(contribuinteStyle)(Ocupacao)
