import React from "react"
import { Link } from "react-router-dom"

import { withStyles, Paper, TextField, MenuItem } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import TablePagination from "@material-ui/core/TablePagination"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"

import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import LoadingContent from "../../components/LoadingContent"
import Button from "../../components/CustomButtons/Button"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import Table from "../../components/Table/Table"
import Search from "../../components/Search"

import AddIcon from "@material-ui/icons/Add"
import Print from "@material-ui/icons/Print"
import Lens from "@material-ui/icons/Lens"
import Edit from "@material-ui/icons/Edit"
import Book from "@material-ui/icons/Book"
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import WarningIcon from '@material-ui/icons/Warning';

import { get, search, print, getTermosByOrdemId, printInfracao } from "../../util/ordemDeServico"
import { print as printTermo, create as tiposTermos, post as emitirTermo } from "../../util/ordemTermos"

import {
    showNotification,
    hideWaiting,
    showWaiting,
    mapErrors
} from "../../util/Constants"
import housecall from "housecall"
import moment from "moment"

class OrdemMain extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: "",
            error: "",
            pageCount: 0,
            perPage: 0,
            page: 0,
            isSearch:false,
            ordems: [],
            ordem_id: "",
            searchText: "",
            field: 0,
            errors: {},
            tipos_termo: [],
            tipo_termo_id: "",
            termos: []
        }
        this.handleFieldChange = this.handleFieldChange.bind(this)
        this.print = this.print.bind(this)
        this.printTermo = this.printTermo.bind(this)
        this.getData = this.getData.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
        this.filter = this.filter.bind(this)
        this.queue = housecall({ concurrency: 1, cooldown: 0 })
        this.setLoadingFalse = this.setLoadingFalse.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleOpemModal = this.handleOpemModal.bind(this)
        this.emitir = this.emitir.bind(this)
        this.edit = this.edit.bind(this)
        this.editTermo = this.editTermo.bind(this)
        this.getTiposTermos = this.getTiposTermos.bind(this)
        this.handleChangeTermos = this.handleChangeTermos.bind(this)
        this.getTermos = this.getTermos.bind(this)
        this.infracaoFormRedirect = this.infracaoFormRedirect.bind(this)
        this.imprimirInfracao = this.imprimirInfracao.bind(this)
    }

    emitir() {
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros! Tente novamente",
                    "danger"
                )
                this.setState({errors : mapErrors(error)})
                return
            }
           
            showNotification(
                "top",
                "center",
                data.message,
                "success"
            )
            this.handleCloseModal("emitir")
            this.setState({
                selected_ordem_id : "",
                tipo_termo_id : "",
                descricao : "",
                intimacao: "",
                prazo: "",
                penalidade: "",
                documentos_solicitados: "",
                data_prorrogacao: "",
                errors: {}
            })
        }
        
        let params = {
            ordem_id: this.state.selected_ordem_id,
            tipo_termo_id: this.state.tipo_termo_id,
            descricao: this.state.descricao || "",  
        }
        if(this.state.tipo_termo_id == 2)
        {
            params.intimacao = this.state.intimacao || "";
            params.prazo = this.state.prazo || "";
            params.penalidade = this.state.penalidade || "";
        }else if(this.state.tipo_termo_id == 3)
        {
            params.documentos_solicitados = this.state.documentos_solicitados || "";
            params.data_prorrogacao = this.state.data_prorrogacao || "";
        }

        emitirTermo(params, response)
    }

    handleOpemModal(key, modal)
    {
        let data
        switch (modal) {
            case "emitir":
                data = { showEmitirModal : true }
                break;
            case "lista":
                data = { showListaModal: true }
                this.getTermos(key)
                break;
            default:
                break;
        }
        this.setState({ 
            ...data,
            selected_ordem_id: this.state.ordems[key].id
        })
    }

    print(key) {
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
            } else {

                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        print(this.state.ordems[key].id, response)
    }

    imprimirInfracao(key) {
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
            } else {

                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        printInfracao(this.state.ordems[key].infracao.id, response)
    }

    printTermo(key) {
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
            } else {

                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }

        printTermo(this.state.termos[key].id, response)
    }

    handleCloseModal(modal) {
        let data
        switch (modal) {
            case "emitir":
                data = { showEmitirModal: false }
                break;
            case "lista":
                data = { showListaModal: false }
                break;
        
            default:
                break;
        }
        this.setState({ 
            ...data,
            selected_ordem_id: ""
        })
    }

    infracaoFormRedirect(key)
    {
        let ordem = this.state.ordems[key];
        
        this.props.history.push({
            pathname: `fiscalizacao/${ordem.id}/infracao/create`,
            state: { contribuinte_id: ordem.contribuinte.id }
        });
    }

    componentWillMount() {
        this.getData()
        this.getTiposTermos()
    }

    handlePageClick(data) {
        //this.getData(data.selected + 1);
    }

    handleChange = event => {
        this.setState({ filterField: event.target.value }, () => {
            if (this.state.searchActive) {
                this.filter({ target: { value: this.state.searchText } })
            }
        })
    }

    handleChangeTermos(event)
    {
        const { name, value } = event.target
        this.setState({ [name]: value })
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }
    setLoadingFalse() {
        this.setState({
            isLoading: false
        })
    }

    filter(event,pagina) {
        let searchText = event.target.value
        var newState =     {
            isLoading:true,
            searchText: searchText,
            searchActive: searchText !== ""
        }
        if(pagina == null){
            newState["page"] = 0
        }
        this.setState(
            newState
            ,
            () => {
                if (this.state.searchActive) {
                    let filtersCheckbox = this.state.filterField || ""
                    search(searchText, filtersCheckbox, undefined, (error, data) => {
                        this.setState({isLoading:false})
                        if (error) {
                            showNotification(
                                "top",
                                "center",
                                "Ocorreram erros, verifique sua conexão com a internet",
                                "danger"
                            )
                        } else {
                            if (this.state.searchText !== "") {
                                this.setState({
                                    ordems: data.data,
                                    pageCount: data.total,
                                    perPage: data.per_page,

                                })
                            }
                        }
                    },pagina)
                } else {
                    this.getData()
                }
            }
        )
    }

    getTiposTermos()
    {
        tiposTermos((error, data) => {
            if(error){
                showNotification(
                    "top",
                    "center",
                    "Erro ao carregar dados para criação dos termos",
                    "danger"
                )
                return
            }

            this.setState({ tipos_termo: data.tipos_de_termo})
        })
    }

    getTermos(key)
    {
        let response = (error, data) => {
            if(error)
            {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
                return
            }

            this.setState({ termos : data })
        } 
        getTermosByOrdemId(this.state.ordems[key].id, response)
    }

    getData(page) {
        this.setState({isLoading:true})

        get(page, (error, data) => {
            this.setState({isLoading:false})
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                this.setState({
                    ordems: data.data,
                    pageCount: data.total,
                    perPage: data.per_page,

                })
            }
        })
    }
    handleChangePage = (event, page) => {
        var pagina;
        pagina = page + 1;
        this.setState({page: page})
        if(page < 0){
            return;
        }
        if (this.state.searchText.toString().trim() != "") {
            this.filter({target: {value: this.state.searchText}}, pagina, page)
            return;
        }
        this.getData(pagina)
    };


    handleFieldChange(value) {
        this.setState({
            field: value
        })
    }

    edit(key){
        let ordem = this.state.ordems[key]
        this.props.history.push("/fiscalizacao/edit/" + ordem.id)
    }

    editTermo(key){
        let termo = this.state.termos[key]
        this.props.history.push(`/fiscalizacao/termo/${termo.id}/edit/`)
    }

    render() {
        const { classes } = this.props
        // assunto_nome, area_administrativa_id, area_administrativa_nome, contribuinte_nome_razao_social, data, situacao_id, situacao_nome
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Código da Ordem" },
            { value: "n_ordem", label: "N° da ordem" },
            { value: "contribuinte_id", label: "Código do contribuinte" },
            { value: "termo_tipo_descricao", label: "Tipo de Termo" },
            // { value: "orgao_id", label: "Endereço" },
            // { value: "orgao_nome", label: "Nome do orgão" },
            // { value: "assunto_id", label: "Razão Social" },
            // { value: "assunto_nome", label: "Assunto" },
            // { value: "area_administrativa_id", label: "Razão Social" },
            // { value: "area_administrativa_nome", label: "Área administrativa" },
            // { value: "contribuinte_nome_razao_social", label: "Nome/razão social" },
            // { value: "data_inicial_acao", label: "Data de abertura (dd/mm/aaaa)" },
            // { value: "situacao_id", label: "Razão Social" },
            // { value: "situacao", label: "Situação" },
        ]
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container spacing={32} direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>Ordens de Serviço</h2>
                            </Grid>
                            <Grid item lg={2} xs={12}>
                                <Link
                                    to="/fiscalizacao/create"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button block color="success" round>
                                        <AddIcon /> Nova Ordem
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Search
                            label="Pesquise aqui"
                            value={this.state.filterField}
                            onChangeText={this.filter}
                            onChangeFields={this.handleChange}
                            fields={fields}
                        />
                        <Grid className={classes.legend} container direction="row">
                            <Lens className={classes.dot + " " + classes.dotWarning}/>
                            Ordem Encerrada 
                        </Grid>
                        <LoadingContent isLoading={this.state.isLoading}>
                            <Paper className={classes.tableWrapper}>
                                <Table
                                    getRowColor={(key, classes) => {
                                        if (this.state.ordems[key].encerrado) {
                                            return classes.warningTableRow
                                        } else {
                                            return ""
                                        }
                                    }}
                                    filter={true}
                                    ordems={this.state.ordems}
                                    tableHeaderColor="primary"
                                    actions={[
                                        {
                                            index: 0,
                                            title: "Imprimir Ordem",
                                            icon: (
                                                <Print
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.print
                                                    }
                                                />
                                            ),
                                            onClick: (key) => this.print(key)
                                        },
                                        {
                                            index: 2,
                                            title: "Editar",
                                            icon: (
                                                <Edit 
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: (key)=> this.edit(key)
                                        },
                                        {
                                            index: 3,
                                            title: "Gerar Termo",
                                            icon: (
                                                <Book 
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: (key)=> this.handleOpemModal(key, "emitir")
                                        },
                                        {
                                            index: 4,
                                            title: "Listar Termos",
                                            icon: (
                                                <AssignmentIcon 
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: key => this.handleOpemModal(key, "lista")
                                        },
                                        {
                                            index: 5,
                                            title: "Criar Auto de Infração",
                                            icon: (
                                                <WarningIcon
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.print
                                                    }
                                                />
                                            ),
                                            onClick: key => this.infracaoFormRedirect(key)
                                        },
                                        {
                                            index: 1,
                                            title: "Imprimir Auto de Intimação",
                                            icon: (
                                                <AssignmentLateIcon 
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: key => this.imprimirInfracao(key)
                                        }
                                    ]}
                                    tableHead={[
                                        "Código",
                                        "N° da ordem",
                                        "Contribuinte",
                                        "Data inicial",
                                        "Data Final",
                                        "Situação",
                                        ""
                                    ]}
                                    tableData={this.state.ordems.map((item, key) => {
                                        return [
                                            item.id + "",
                                            item.n_ordem,
                                            item.contribuinte.nome_razao_social,
                                            moment(item.data_inicial_acao).format('DD/MM/YYYY'),
                                            moment(item.data_final_acao).format('DD/MM/YYYY'),
                                            !item.situacao ? "" : item.situacao.descricao,
                                            key
                                        ]
                                    })}
                                />
                                { (
                                    <TablePagination
                                        component="div"
                                        rowsPerPageOptions={[]}
                                        count={this.state.pageCount}
                                        rowsPerPage={parseInt(this.state.perPage)}
                                        page={this.state.page}
                                        backIconButtonProps={

                                            {"aria-label": "Previous Page"}}
                                        nextIconButtonProps={
                                            {
                                                "aria-label": "Next Page",
                                            }}

                                        SelectProps={{
                                            native: true
                                        }}

                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                )}
                            </Paper>
                        </LoadingContent>
                    </CardBody>
                </Card>

                {/* dialog lista de termos */}
                <Dialog
                    open={this.state.showListaModal}
                    onClose={() => this.handleCloseModal("lista")}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: "3" }}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="alert-dialog-title">Lista de Termos</DialogTitle>
                    <DialogContent>
                        <Paper>
                            <Table
                                getRowColor={(key, classes) => {
                                    if (this.state.ordems[key]) {
                                        // return classes.successTableRow
                                    } else {
                                        return ""
                                    }
                                }}
                                tableHeaderColor="primary"
                                actions={[
                                    {
                                        title: "Imprimir",
                                        icon: (
                                            <Print
                                                className={
                                                    classes.tableActionButtonIcon + " " + classes.print
                                                }
                                            />
                                        ),
                                        onClick: (key) => this.printTermo(key)
                                    },
                                    {
                                        title: "Editar",
                                        icon: (
                                            <Edit 
                                                className={
                                                    classes.tableActionButtonIcon + " " + classes.edit
                                                }
                                            />
                                        ),
                                        onClick: (key)=> this.editTermo(key)
                                    }
                                ]}
                                tableHead={[
                                    "Código",
                                    "Tipo",
                                    "Data Emissão",
                                    ""
                                ]}
                                tableData={this.state.termos.map((termo, key) => {
                                    return [
                                        termo.id + "",
                                        termo.tipo.descricao,
                                        moment(termo.created_at).format("DD/MM/YYYY"),
                                        key
                                    ]
                                })}
                            />
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleCloseModal("lista")} color="danger">
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* dialog emissão de termos */}
                <Dialog
                    open={this.state.showEmitirModal}
                    onClose={() => this.handleCloseModal("emitir")}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: "3" }}
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="alert-dialog-title">Emissão de Termos</DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    name="tipo_termo_id"
                                    label="Tipos de Termo"
                                    fullWidth
                                    value={this.state.tipo_termo_id}
                                    onChange={this.handleChangeTermos}
                                    error={this.state.errors.tipo_termo_id}
                                    helperText={this.state.errors.tipo_termo_id}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    {this.state.tipos_termo.filter(tipo => tipo.id !== 1).map((option, key) => (
                                    <MenuItem key={key} value={option.id}>
                                        {option.descricao}
                                    </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" rows={5} spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="outlined-select-currency"
                                    name="descricao"
                                    label={this.state.tipo_termo_id != 3 ? "Relato" : "Contexto"}
                                    fullWidth
                                    error={this.state.errors.descricao}
                                    helperText={this.state.errors.descricao}
                                    multiline
                                    rows={3}
                                    rowsMax="4"
                                    value={this.state.descricao}
                                    defaultValue={this.state.descricao}
                                    onChange={this.handleChangeTermos}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            {this.state.tipo_termo_id == 3 && (
                                <>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="documentos_solicitados"
                                        label="Documentos Solicitados"
                                        fullWidth
                                        error={this.state.errors.documentos_solicitados}
                                        helperText={this.state.errors.documentos_solicitados}
                                        value={this.state.documentos_solicitados}
                                        defaultValue={this.state.documentos_solicitados}
                                        onChange={this.handleChangeTermos}
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="data_prorrogacao"
                                        label="Início da prorrogação"
                                        fullWidth
                                        type="date"
                                        error={this.state.errors.data_prorrogacao}
                                        helperText={this.state.errors.data_prorrogacao}
                                        value={this.state.data_prorrogacao}
                                        defaultValue={this.state.data_prorrogacao}
                                        onChange={this.handleChangeTermos}
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            </>
                            )}
                        </Grid>
                        {this.state.tipo_termo_id == 2 && (
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="intimacao"
                                        label="Descrição de Intimação"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        rowsMax="4"
                                        error={this.state.errors.intimacao}
                                        helperText={this.state.errors.intimacao}
                                        value={this.state.intimacao}
                                        defaultValue={this.state.intimacao}
                                        onChange={this.handleChangeTermos}
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="prazo"
                                        label="Prazo"
                                        fullWidth
                                        type="number"
                                        error={this.state.errors.prazo}
                                        helperText={this.state.errors.prazo}
                                        value={this.state.prazo}
                                        defaultValue={this.state.prazo}
                                        onChange={this.handleChangeTermos}
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="penalidade"
                                        label="Penalidade"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        rowsMax="4"
                                        error={this.state.errors.penalidade}
                                        helperText={this.state.errors.penalidade}
                                        value={this.state.penalidade}
                                        defaultValue={this.state.penalidade}
                                        onChange={this.handleChangeTermos}
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleCloseModal("emitir")} color="danger">
                            Cancelar
                        </Button>
                        <Button onClick={this.emitir} color="success">
                            Emitir
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}

export default withStyles(contribuinteStyle)(OrdemMain)

