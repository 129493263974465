import React from "react"
import TextField from "@material-ui/core/TextField"
import { withStyles, Paper } from "@material-ui/core"
import logradouroStyle from "../../assets/css/layout/logradouroStyle"
import Button from "../../components/CustomButtons/Button"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import AddIcon from "@material-ui/icons/Add"
import Grid from "@material-ui/core/Grid"
import { showNotification, mapErrors } from "../../util/Constants"
import Table from "../../components/Table/Table"
import TablePagination from "@material-ui/core/TablePagination"
import Edit from "@material-ui/icons/Edit"
import LoadingContent from "../../components/LoadingContent"
import housecall from "housecall"
import { get } from "../../util/receitas"

class Receitas extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      errors: {},
      nome: "",
      pageCount: 0,
      perPage: 0,
      page: 1,
      receitas: [],
      receitasFiltered: [],
      search: false,
      field: 0,
      modal: false,
      id: 0
    }
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.getData = this.getData.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.searchFilter = this.searchFilter.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
  }

  searchFilter(value) {
    let filtered = this.state.receitas
    if(value !== ''){
      this.setState({
        search: true
      })
    } else {
      this.setState({
        search: false
      })
    }
    filtered = filtered.filter((filter) => {
      if(filter.nome !== null) {
        return filter.nome.indexOf(value.toUpperCase()) !== -1;
      } else {
        return null
      }
    })
    this.setState({ receitasFiltered: filtered })
  }

  componentWillMount() {
    this.getData()
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: event.target.value,
      errors: errors
    })
  }

  handleChangeFilters = event => {
    this.setState({ filterField: event.target.value }, () => {
      if (this.state.searchActive) {
        this.filter({ target: { value: this.state.searchText } })
      }
    })
  }

  componentDidMount() {
    this.queue.on("idle", this.toggleLoading)
  }

  getData(page) {
    if (!page) {
      this.toggleLoading()
    }
    get(page, (error, data) => {
      if (!page) {
        this.toggleLoading()
      }
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        this.setState({
          receitas: data.data,
          receitasFiltered: data.data,
          pageCount: data.total,
          perPage: data.per_page
        })
      }
    })
  }

  handleChangePage = (event, page) => {
    if ((page || 1) !== this.state.page) {
      this.setState({ page }, () => { this.getData(this.state.page) })
    }
  }

  handleFieldChange(value) {
    this.setState({
      field: value
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Receitas</h2>
              </Grid>
              <Grid item lg={2} xs={12}>
                {/* <Button block color="success" round onClick={this.handleOpen}>
                  <AddIcon /> Adicionar
                </Button> */}
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
          <TextField
                id="standard-search"
                label="Pesquisar por ano"
                fullWidth
                type="search"
                name="search"
                onChange={(event) => this.searchFilter(event.target.value)}
                className={classes.textField}
                margin="normal"
              />
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  tableHeaderColor="primary"
                  actions={[]}
                  tableHead={["#", "receita", "Dam avulso"]}
                  tableData={(this.state.search ? this.state.receitasFiltered : this.state.receitas).map((item, key) => {
                    return [
                      item.id + "",
                      item.nome,
                      item.dam_avulso == 1 ? "Sim" : "Não",
                      key
                    ]
                  })}
                />
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[]}
                  count={this.state.pageCount}
                  rowsPerPage={parseInt(this.state.perPage)}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page"
                  }}
                  SelectProps={{
                    native: true
                  }}
                 
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>
      </div>
    )
  }
}

export default withStyles(logradouroStyle)(Receitas)
