import React from "react"
import {withRouter} from "react-router-dom"

import logradouroStyle from "../../../assets/css/layout/logradouroStyle"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Button from "../../../components/CustomButtons/Button"
import { withStyles } from "@material-ui/core"
import InputMask from "react-input-mask"
import {showWaiting, hideWaiting, mapErrors, showNotification} from "../../../util/Constants"
import {post} from "../../../util/dividaLancamento"

import FormLabel from "@material-ui/core/FormLabel"
import FormControl from "@material-ui/core/FormControl"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import moment from "moment"
import SearchFields from "../../../components/SearchFields"
import Typography from "@material-ui/core/Typography"
import AsyncSelect from "../../../components/AsyncSelect"
import { simpleSearch as searchContribuinte } from "../../../util/contribuinte"

class LancamentoDivida extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contribuinte_id: "",
      iptu: false,
      dam_avulso: false,
      alvara: false,
      ocupacao_de_imovel: false,
      construcao_civil: false,
      itbi: false,
      list: [],
      inscricao: new Date(),
      // exercicio: "",
      competencia_divida: "",
      vencimento_inicial: "",
      vencimento_final: "",
      errors: {}
    }
    this.handleChange = this.handleChange.bind(this)
    this.addItem = this.addItem.bind(this)
    this.removeItem = this.removeItem.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.toggleLoad = this.toggleLoad.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
  }

  handleChange(event) {
    let name = event.target.name

    let errors = this.state.errors
    errors[name] = undefined

    if (name === "iptu" || name === "alvara" || name === "dam_avulso" ||
        name === "ocupacao_de_imovel" || name === "construcao_civil" || name === "itbi") {
      let chec = event.target.checked
      this.setState(
        {
          [name]: event.target.checked,
          errors: errors
        },
        () => {
          if (chec === true) {
            this.addItem(name)
          } else {
            this.removeItem(name)
          }
        }
      )
    } else {
      this.setState({
        [name]: event.target.value,
        errors: errors
      })
    }
  }
  addItem(value) {
    this.setState(state => {
      const cp = [...state.list, value]
      let list = cp.filter((v, i) => cp.indexOf(v) === i)

      return {
        list
      }
    })
  }
  removeItem(value) {
    let array = this.state.list
    let find = array.indexOf(value)
    if (find !== -1) {
      array.splice(find, 1)
      this.setState({
        list: array
      })
    }
  }
  toggleLoad() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let params = {
      contribuinte_id: this.state.contribuinte_id.value || "",
      modulos: this.state.list,
      // exercicio: this.state.exercicio,
      competencia_divida: this.state.competencia_divida,
      vencimento_inicial: this.state.vencimento_inicial,
      vencimento_final: this.state.vencimento_final,
      data: moment(this.state.inscricao).format("YYYY-MM-DD"),
    }
    this.toggleLoad()
    let response = error => {
      this.toggleLoad()
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Lançamento realizado com sucesso",
          "success"
        )
        this.props.history.push("/divida/cadastro")
      }
    }
    post(params, response)
  }
  loadOptions(inputValue, callback) {
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let contribuintes = data.data.map(contribuinte => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - ${contribuinte.cpf_cnpj || ""}`
            }
          })
          this.setState({
            contribuintes: data.data
          })
          callback(contribuintes)
        }
      }
    )
  }
  render() {
    const { classes } = this.props
     const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do contribuinte" },
      { value: "cpf_cnpj", label: "Documento" },
      { value: "endereco", label: "Endereço" },
      { value: "nome_fantasia", label: "Nome Fantasia" },
      { value: "nome_razao_social", label: "Razão Social" }
    ]
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>
                  Lançamento na Dívida Ativa
                </h2>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <SearchFields
                  name="filterField"
                  value={this.state.filterField || ""}
                  onChangeFields={this.handleChange}
                  fields={fields}
                />
                <Typography color="textSecondary" variant="caption">
                  Pesquise o Contribuinte
                                </Typography>
                <AsyncSelect
                  className={
                    `form-control form-control-alternative form-select Select-menu-outer ${this.props.error ? "has-danger" : ""
                    }`
                  }
                  value={this.state.contribuinte_id}
                  onChange={this.handleChange}
                  loadOptions={this.loadOptions}
                  error={this.state.errors.contribuinte_id}
                  helperText={this.state.errors.contribuinte_id}
                  name="contribuinte_id"
                  placeholder="Contribuinte"
                  message="Pesquise o contribuinte"
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel component="legend">
                    Selecione as receitas para lançamento
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={this.state.alvara}
                          onChange={this.handleChange}
                          name="alvara"
                        />
                      }
                      label="Alvará"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={this.state.iptu}
                          onChange={this.handleChange}
                          name="iptu"
                        />
                      }
                      label="IPTU"
                    />
                    <FormControlLabel
                        control={
                          <Checkbox
                              color="secondary"
                              checked={this.state.dam_avulso}
                              onChange={this.handleChange}
                              name="dam_avulso"
                          />
                        }
                        label="DAM Avulso"
                    />
                    <FormControlLabel
                        control={
                          <Checkbox
                              color="secondary"
                              checked={this.state.ocupacao_civil}
                              onChange={this.handleChange}
                              name="construcao_civil"
                          />
                        }
                        label="Construção Civil"
                    /><FormControlLabel
                      control={
                        <Checkbox
                            color="secondary"
                            checked={this.state.ocupacao_civil}
                            onChange={this.handleChange}
                            name="ocupacao_de_imovel"
                        />
                      }
                      label="Ocupação Imóvel"
                  />
                    <FormControlLabel
                        control={
                          <Checkbox
                              color="secondary"
                              checked={this.state.itbi}
                              onChange={this.handleChange}
                              name="itbi"
                          />
                        }
                        label="ITBI"
                    />
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={this.state.dam_avulso}
                          onChange={this.handleChange}
                          name="dam_avulso"
                        />
                      }
                      label="Dam Avulso"
                    /> */}
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={this.state.ocupacao_de_imovel}
                          onChange={this.handleChange}
                          name="ocupacao_de_imovel"
                        />
                      }
                      label="Ocupação de Imóvel"
                    /> */}
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  name="vencimento_inicial"
                  label="Vencimento Inicial do DAM"
                  fullWidth
                  type="date"
                  error={this.state.errors.vencimento_inicial}
                  helperText={this.state.errors.vencimento_inicial}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  defaultValue={this.state.vencimento_inicial}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  name="vencimento_final"
                  label="Vencimento Final do DAM"
                  fullWidth
                  type="date"
                  error={this.state.errors.vencimento_final}
                  helperText={this.state.errors.vencimento_final}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  defaultValue={this.state.vencimento_final}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputMask
                  mask="9999"
                  value={this.state.competencia_divida}
                  onChange={this.handleChange}
                >
                  {() => (
                    <TextField
                      id="outlined-select-currency"
                      name="competencia_divida"
                      label="Competência de lançamento na dívida"
                      fullWidth
                      error={this.state.errors.competencia_divida}
                      helperText={this.state.errors.competencia_divida}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  name="inscricao"
                  label="Data Inscrição Dívida"
                  fullWidth
                  type="date"
                  error={this.state.errors.data}
                  helperText={this.state.errors.data}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  defaultValue={moment(this.state.inscricao).format("YYYY-MM-DD")}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
          </CardBody>
          <CardFooter>
              <Grid container justify="center">
                <Grid item lg={2}>
                  <Button block color="info" round onClick={this.handleSave}>
                    Lançar
                  </Button>
                </Grid>
              </Grid>
            </CardFooter>
        </Card>
      </div>
    )
  }
}
LancamentoDivida = withRouter(LancamentoDivida)
export default withStyles(logradouroStyle)(LancamentoDivida)
