import axios from "axios"
import { defaultHeaders } from "./Constants"

export function get(page, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url =
    process.env.REACT_APP_API + `certidoes${page ? `?page=${page}` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function print(id, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `certidoes/${id}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function printMany(contribuinte, ano, numeroInicial, numeroFinal, valorMinimo, valorMaximo, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url =
    process.env.REACT_APP_API +
    `printCertidoes?contribuinte=${contribuinte}&ano=${ano}&numero_inicial=${numeroInicial}&numero_final=${numeroFinal}&valor_minimo=${valorMinimo}&valor_maximo=${valorMaximo}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function post(params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "certidoes"
  axios
    .post(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function search(searchText, field, onStart, callBack,pagina) {
  let config = {
    headers: defaultHeaders(),
    data: { a: "a" }
  }
  let url =
    process.env.REACT_APP_API +
    `search/certidoes?text=${searchText}&fields=${field}`

  if(pagina != null){
    url += `&page=${pagina}`

  }
  if (onStart) {
    onStart()
  }
  return axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function put(id, params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "certidoesNumeroProcesso/" + id
  axios
    .put(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function atualizarCertidao(id, callBack){
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "certidoes/"+id
  axios
    .put(url, {}, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error, error.response.data)
    })
}