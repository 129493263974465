import axios from "axios"
import { defaultHeaders, makeRequestCreator } from "./Constants"

export function get(page, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `alvara${page ? `?page=${page}` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getField(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "alvara/create"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getTaxas(page, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "taxas"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getEnquadramentos(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "alvara/create"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data.enquadramentos)
      console.log("RESPONSE: ", response.data.enquadramentos)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getMotivoCancelamento(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "motivosDeBaixa"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function cancelamento(id, motivo, data_da_baixa, descricao, protocolo, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `cancelarAlvara/${id}?motivo_de_baixa_id=${motivo}${data_da_baixa ? `&data_da_baixa=${data_da_baixa}` : ""}&descricao_baixa=${descricao}&protocolo_de_baixa=${protocolo || ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function reativar(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `reativar/${id}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function post(params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "alvara"
  axios
    .post(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function put(id, params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "alvara/" + id
  axios
    .put(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function edit(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `alvara/${id}/edit`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function destroy(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "alvara/" + id
  axios
    .delete(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
const searchGet = makeRequestCreator()
export function search(searchText, field, onStart, callBack,pagina) {
  let url =
    process.env.REACT_APP_API +
    `search/alvara?text=${searchText}&fields=${field}`
  if(pagina != null){
    url += `&page=${pagina}`
  }

  return searchGet(url, callBack)
}

export function imprimirAlvaraBy(id, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `getAlvara/${id}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function imprimirBCE(id, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `bce/${id}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function certidaoDeBaixa(id, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `certidaoDeBaixaDeAlvara/${id}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function alterarVencimento(id, date, sem_multa, sem_juros, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `mudarVencimentoAlvara/${id}?vencimento=${date}&sem_multa=${sem_multa}&sem_juros=${sem_juros}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function relatorioCadastro(taxa, contr, end, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `relatorios/cadastroDeAlvara?taxa_id=${taxa ? taxa : ""}&endereco=${end ? end : ""}&contribuinte_id=${contr ? contr : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function relatorioAlvarasContribuintes(taxa_id, documento_id, status_id, enquadramento_id, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `relatorios/contribuinteDeAlvara?taxa_id=${taxa_id ? taxa_id : ""}&documento_id=${documento_id ? documento_id : ""}&status_id=${status_id ? status_id : ""}&enquadramento_id=${enquadramento_id ? enquadramento_id : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getCnae(codigo, callBack) {
  axios
    .get(`https://servicodados.ibge.gov.br/api/v2/cnae/subclasses/${codigo ? codigo : ""}`)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function removeDamAlvara(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "damsDeAlvara/" + id
  axios
    .delete(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function autorizarAlvara(id, callBack){
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "alvara/autorizarAlvara/" + id
  axios
      .get(url, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error, undefined)
      })
}

export function relatorioAlvarasLancados(taxa, contr, compen, tipo, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `relatorios/alvarasLancados?taxa_id=${taxa ? taxa : ""}&competencia=${compen ? compen : ""}&tipo_alvara_id=${tipo ? tipo : ""}&contribuinte_id=${contr ? contr : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function printAlvarasPagosOuEmAberto(contribuinte, taxa, competencia, data_inicial, data_final, modelo, callBack)
{
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `relatorios/alvarasPagosOuEmAberto?contribuinte_id=${contribuinte ? contribuinte : ""}&taxa_id=${taxa ? taxa : ""}&competencia=${competencia ? competencia : ""}&data_inicial=${data_inicial}&data_final=${data_final}&modelo=${modelo}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function printAlvarasIsentos(contribuinte, taxa, competencia, data_inicial, data_final, callBack)
{
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `relatorios/alvarasIsentos?contribuinte_id=${contribuinte ? contribuinte : ""}&taxa_id=${taxa ? taxa : ""}&competencia=${competencia ? competencia : ""}&data_inicial=${data_inicial}&data_final=${data_final}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}