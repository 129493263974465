import React from "react";
import { 
    withStyles, 
    Checkbox, 
    Switch, 
    FormControlLabel 
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import LoadingContent from "../../../components/LoadingContent";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
import Button from "../../../components/CustomButtons/Button";
import SelectCustom from "../../../components/SelectCustom"

import secaoStyle from "../../../assets/css/layout/secaoStyle";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { 
    hideWaiting, 
    mapErrors, 
    showNotification, 
    showWaiting 
} from "../../../util/Constants";

import numeral from "../../../util/numeral";
import { getDividaEmAbertoComFiltros } from "../../../util/dividas";
import { infracaoStore } from "../../../util/ordemDeServico";
import { get as fatosGeradores } from "../../../util/fatosGeradores";





class AutoDeInfracaoForm extends React.Component
{
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            fatosGeradores: [],
            dividas: [],
            errors: {}
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.getDividas = this.getDividas.bind(this);
        this.getFatosGeradores = this.getFatosGeradores.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.checkAll = this.checkAll.bind(this);
    }

    componentDidMount(){
        this.getDividas();
        this.getFatosGeradores();
    }

    handleChange(event)
    {
        const { name, value } = event.target;

        this.setState(state => ({
            [name]: value,
            errors: { ...state.errors, [name]: undefined }
        }));

        if(name === 'isDividas'){
            this.setState({
                isDividas: !this.state.isDividas
            })
        }
    }

    handleChangeCheckbox(event) {
        const name = parseInt(event.target.name)
        const checked = event.target.checked
        let newDividas = this.state.dividas
        newDividas.map((item, index, dividas) => {
            if (index === name) {
                item.checkbox = checked
            }
            dividas[index] = { ...item }
        })
        this.setState({
            dividas: newDividas
        })
    }
    checkAll(event) {
        let checked = true
        if (event) {
            checked = event.target.checked
        }
        let newDividas = this.state.dividas
        newDividas.map((item, index, dividas) => {
            item.checkbox = checked
            dividas[index] = { ...item }
        })
        this.setState({
            dividas: newDividas,
            selectAll: checked
        })
    }

    handleSave(event)
    {
        event.preventDefault()
        let dividas = [];

        if(this.state.isDividas)
        {
            let filter = this.state.dividas.filter(item => {
                return item.checkbox === true
            })
            dividas = filter.map(item => {
                return item.id
            })
        }

        let params = {
            dividas,
            "fato_gerador_id": this.state.fato_gerador_id ? this.state.fato_gerador_id.value : "",
            "descricao": this.state.descricao,
        }

        let response = (error, data) => {
            showWaiting();
            if(error){
                hideWaiting();
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )

                this.setState({
                    errors: mapErrors(error)
                })
                return;
            }

            hideWaiting();
            showNotification(
                "top",
                "center",
                "Auto de Infração registrado com sucesso.",
                "success"
            )

            this.props.history.push("/fiscalizacao");
        }

        infracaoStore(this.props.match.params.id, params, response);
    }

    getFatosGeradores()
    {
        showWaiting();

        const response = (error, data) => {
            if(error){
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
                return
            }
            hideWaiting();
            let fatosGeradores = data.map(fatoGerador => {
                return {value: fatoGerador.id, label: `${fatoGerador.id} - ${fatoGerador.descricao}`}
            });

            this.setState({ fatosGeradores })
        }

        fatosGeradores(response);
    }

    getDividas() {
        showWaiting()
        let arrayParams = new Array()
        Object.entries({ ...this.state.search }).forEach(([key, value]) => {
            arrayParams.push(`${key}=${value}`)
        })
        const params = `?${arrayParams.join("&")}`
        const response = (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                this.setState({
                    dividas: data.map((item) => {
                        return { ...item, checkbox: false }
                    }),
                    selectAll: false,
                    nao_tem_divida: data.length === 0
                })
            }
            hideWaiting()
        }
        if (!this.props.location.state.contribuinte_id) {
            showNotification(
                "top",
                "center",
                "Contribuinte é obrigatório",
                "warning"
            )
            hideWaiting()
        } else {
            getDividaEmAbertoComFiltros(this.props.location.state.contribuinte_id, params, response)
        }
    }

    render(){
        const { classes } = this.props

        return (
            <div className={classes.container}>
                <LoadingContent isLoading={this.state.isLoading}>
                    <div>
                        <LoadingContent isLoading={this.state.canShow === false}>
                            <Card>
                                <form onSubmit={this.handleSave}>
                                    <CardHeader color="primary">
                                        <Grid container direction="row">
                                            <Grid item lg={10} xs={12}>
                                                <h2 className={classes.cardTitleWhite}>
                                                    Auto de Infração
                                                </h2>
                                            </Grid>
                                        </Grid>
                                    </CardHeader>
                                    <CardBody>
                                        {!this.state.dividas.find(divida => [200, 212].includes(divida.receita_id)) ? "": (
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <SelectCustom
                                                    value={this.state.fato_gerador_id}
                                                    name="fato_gerador_id"
                                                    isMulti={false}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={this.handleChange}
                                                    error={this.state.errors.fato_gerador_id}
                                                    helperText={this.state.errors.fato_gerador_id}
                                                    options={this.state.fatosGeradores}
                                                    placeholder="Fatos Geradores"
                                                    message="Pesquise o Fato Gerador"
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <TextField
                                                id="outlined-select-currency"
                                                name="descricao"
                                                label="Descrição"
                                                fullWidth
                                                error={this.state.errors.descricao}
                                                helperText={this.state.errors.descricao}
                                                multiline
                                                rows={4}
                                                value={this.state.descricao}
                                                defaultValue={this.state.descricao}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        <Grid container spacing={16}>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <FormControlLabel
                                                control={
                                                    <Switch
                                                    name={'isDividas'}
                                                    defaultChecked={this.state.isDividas}
                                                    checked={
                                                        this.state.isDividas
                                                    }
                                                    onChange={this.handleChange}
                                                    value={this.state.isDividas}
                                                    />
                                                }
                                                label="Adicionar dívidas?"
                                                />
                                            </Grid>
                                        </Grid>
                                        {this.state.isDividas && this.state.dividas.length > 0 ? (
                                            <div>
                                                <Typography color="textSecondary" variant="caption">
                                                    Selecione as dívidas que serão incluídas no Auto de infração
                                                </Typography>
                                                <div>Selecione todos</div>
                                                <Grid item xs={12} sm={12} md={1} lg={1} alignItems="center"
                                                    style={{ display: "inline-block" }} justify="center">
                                                    <Checkbox
                                                        icon={
                                                            <CheckBoxOutlineBlankIcon fontSize="large" />
                                                        }
                                                        checkedIcon={
                                                            <CheckBoxIcon fontSize="large" />
                                                        }

                                                        onChange={this.checkAll}
                                                        checked={this.state.selectAll}
                                                        name={"Selecionar todos"}
                                                    />
                                                </Grid>
                                                {this.state.dividas.map((item, key) => {
                                                    return (
                                                        <div>
                                                            <Card>
                                                                <Grid
                                                                    style={{ marginBottom: "15px" }}
                                                                    container
                                                                    direction="row"
                                                                    justify="flex-center"
                                                                    alignItems="center"
                                                                    spacing={8}
                                                                    key={key}
                                                                >
                                                                    <Grid className={classes.flexInherit} item xs={12}
                                                                        sm={12} md={1} lg={1} alignItems="center"
                                                                        justify="center">
                                                                        <Checkbox
                                                                            icon={
                                                                                <CheckBoxOutlineBlankIcon
                                                                                    fontSize="large" />
                                                                            }
                                                                            checkedIcon={
                                                                                <CheckBoxIcon fontSize="large" />
                                                                            }
                                                                            onChange={this.handleChangeCheckbox}
                                                                            value={item.checkbox}
                                                                            checked={item.checkbox}
                                                                            name={key}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                                                        <strong>Receita: </strong><span>{item.receita_id} - {item.receita.nome} </span><br />
                                                                        <strong>Competência: </strong><span>{item.competencia}  </span>
                                                                        <br />
                                                                        <strong>Código de
                                                                            origem: </strong><span>{item.codigo_de_origem}  </span>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                                                        <strong>Valor original: </strong><span>{"R$" +
                                                                            numeral(parseFloat(item.valor))
                                                                                .format("0.0,00")
                                                                                .slice(0, -2)}</span><br />
                                                                        <strong>Valor pago: </strong><span>{"R$" +
                                                                            numeral(parseFloat(item.valor_pago))
                                                                                .format("0.0,00")
                                                                                .slice(0, -2)}</span><br />
                                                                        <strong>Saldo devedor: </strong><span>{"R$" +
                                                                            numeral(parseFloat(item.saldo_devedor))
                                                                                .format("0.0,00")
                                                                                .slice(0, -2)}</span><br />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                                                        <strong>Correção
                                                                            monetária: </strong><span>{"R$" +
                                                                            numeral(parseFloat(item.correcao_monetaria))
                                                                                .format("0.0,00")
                                                                                .slice(0, -2)}</span><br />
                                                                        <strong>Multa: </strong><span>{"R$" +
                                                                            numeral(parseFloat(item.multa))
                                                                                .format("0.0,00")
                                                                                .slice(0, -2)}</span><br />
                                                                        <strong>Juros: </strong><span>{"R$" +
                                                                            numeral(parseFloat(item.juros))
                                                                                .format("0.0,00")
                                                                                .slice(0, -2)}</span><br />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={12} md={2} lg={2}>
                                                                        <h3>Total a
                                                                            pagar: {"R$" + numeral(parseFloat(item.saldo_devedor)
                                                                            + parseFloat(item.correcao_monetaria) + parseFloat(item.multa) + parseFloat(item.juros)).format("0.0,00")
                                                                                .slice(0, -2)}</h3>
                                                                    </Grid>
                                                                </Grid>
                                                            </Card>
                                                            {this.state.errors && (
                                                                <Typography variant="caption"
                                                                    color="error">{this.state.errors[`dividas.${key}`]}</Typography>
                                                            )}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        ): ""}
                                    </CardBody>
                                    <CardFooter>
                                        <Grid container justify="center" spacing={8}>
                                            <Grid item lg={2}>
                                                <Button block color="info" round onClick={this.handleSave}>
                                                    Confirmar
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardFooter>
                                </form>
                            </Card>
                        </LoadingContent>
                    </div>
                </LoadingContent>
            </div>
        );
    }
}


export default withStyles(secaoStyle)(AutoDeInfracaoForm);