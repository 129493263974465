import React from "react";
import { Link, withRouter } from "react-router-dom";
import { withStyles, Paper } from "@material-ui/core";
import logradouroStyle from "../../../assets/css/layout/logradouroStyle";
import Button from "../../../components/CustomButtons/Button";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import FileCopy from "@material-ui/icons/FileCopy"
import numeral from "../../../util/numeral";
import moment from "moment";

import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../../util/Constants";


import Table from "../../../components/Table/Table";
import TablePagination from "@material-ui/core/TablePagination";

import { detalhaConfirmacao, get as getRemessa, search } from "../../../util/confirmacao";
import LoadingContent from "../../../components/LoadingContent";
import Search from "../../../components/Search";
import housecall from "housecall";

class Confirmacao extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      searchText: "",
      error: "",
      errors: {},
      nome: "",
      pageCount: 0,
      perPage: 0,
      page: 0,
      isSearch: false,
      remessas: [],
      remessaDetalhe: [],
      field: 0,
      modal: false,
      id: 0
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.getData = this.getData.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.filter = this.filter.bind(this);
    this.queue = housecall({ concurrency: 1, cooldown: 0 });
    this.getDetalheRemessa = this.getDetalheRemessa.bind(this)
  }

  getDetalheRemessa(key) {

    let response = (error, data) => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        console.log("Dentro do else -> getDetalheRemessa" + data);

      }
    }

    //console.log('Dentro da Remessa ainda -> ' +  this.state.remessas[key].id)
    this.props.history.push("/protesto/confirmacaodetalhe/" + this.state.remessas[key].id)
    //detalhaRemessa(this.state.remessas[key].id, response);  
  }

  componentWillMount() {
    this.getData();
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }));
  }
  handleChange(event) {
    let name = event.target.name;
    let errors = this.state.errors;
    errors[name] = undefined;
    this.setState({
      [name]: event.target.value,
      errors: errors
    });
  }

  handleChangeFilters = event => {
    this.setState({ filterField: event.target.value }, () => {
      if (this.state.searchActive) {
        this.filter({ target: { value: this.state.searchText } });
      }
    });
  };

  componentDidMount() {
    this.queue.on("idle", this.toggleLoading);
  }
  filter(event, pagina) {
    let searchText = event.target.value;
    var newState = {
      isLoading: true,
      searchText: searchText,
      searchActive: searchText !== ""
    }
    if (pagina == null) {
      newState["page"] = 0
    }
    this.setState(newState
      ,
      () => {

        if (this.state.searchActive) {

          let filtersCheckbox = this.state.filterField || "";

          search(searchText, filtersCheckbox, undefined, (error, data) => {

            this.setState({ isLoading: false })
            if (error) {
              showNotification(
                "top",
                "center",
                "Ocorreram erros, verifique sua conexão com a internet",
                "danger"
              );
            } else {
              this.setState({
                remessas: data.data,
                pageCount: data.total,
                perPage: data.per_page
              }, () => {

              });
            }
          }, pagina)

        } else {
          this.getData();
        }
      }
    );
  }

  handleOpen = () => {
    this.setState({ modal: true });
  };

  handleClose = () => {
    this.setState({ modal: false }, () => {
      this.getData(this.state.page);
    });
  };

  getData(page) {
    this.setState({ isLoading: true })

    getRemessa(page, (error, data) => {
      this.setState({ isLoading: false })
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        this.setState({
          remessaDetalhe: data.data,
          pageCount: data.total,
          perPage: data.per_page
        });
      }

    });
  }
  handleChangePage = (event, page) => {
    var pagina;
    pagina = page + 1;

    if (page < 0) {
      return;
    }
    this.setState({ page: page })
    if (this.state.searchText.toString().trim() != "") {
      this.filter({ target: { value: this.state.searchText } }, pagina, page)
      return;
    } else

      this.getData(pagina)
  };

  handleFieldChange(value) {
    this.setState({
      field: value
    });
  }


  render() {
    const { classes } = this.props;
    const fields = [];
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Confirmação</h2>
                <h4 className={classes.cardTitleWhite}>Obs.: O arquivo confirmação esta disponível após as 14h30min.</h4>
              </Grid>
              <Grid item lg={2} xs={12}>
                
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Search
              label="Pesquise aqui"
              value={this.state.filterField}
              onChangeText={this.filter}
              onChangeFields={this.handleChangeFilters}
              fields={fields}
            />
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  
                  tableHead={["#", "Contribuinte", "Receita", "Valor da Divida", "Cartório", "Nº do Protocolo", "Data", "Ocorrencia"]}
                  tableData={this.state.remessaDetalhe.map((item, key) => {
                    let nomeCartorio;
                    let numeroProtocolo;
                    let dataProtocolo;
                    let nomeOcorrencia;

                    if(item.cartorio === null){
                      nomeCartorio = " - ";
                    }
                    else{
                      nomeCartorio = item.cartorio.nome;
                    }

                    if(item.protocolo === 0){
                      numeroProtocolo = " - ";
                    }
                    else{
                      numeroProtocolo = item.protocolo;
                    }

                    if(item.data_protocolo === null){
                      dataProtocolo = " - ";
                    }
                    else{
                      dataProtocolo = moment(item.data_protocolo).format('DD/MM/YYYY');
                    }

                    if(item.ocorrencia === null){
                      nomeOcorrencia = " - ";
                    }else{  
                      nomeOcorrencia = item.ocorrencia.descricao;

                    }
                    
                    return [
                      item.id + "",
                      item.contribuinte.nome_razao_social,
                      item.receita.nome,                      // moment(item.data_fim).format('DD/MM/YYYY'),
                      `R$ ${numeral(parseFloat(item.total)).format(
                        "0.0,00"
                      )}`.slice(0, -2),
                      nomeCartorio,
                      numeroProtocolo,
                      dataProtocolo, 
                      nomeOcorrencia,
                      key
                    ];
                  })}
                />
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[]}
                  count={this.state.pageCount}
                  rowsPerPage={this.state.perPage ? this.state.perPage : 0}
                  page={this.state.page ? this.state.page : 0}
                  backIconButtonProps={

                    { "aria-label": "Previous Page" }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}

                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>
      </div>
    );
  }
}
Confirmacao = withRouter(Confirmacao);
export default withStyles(logradouroStyle)(Confirmacao);
