import React from "react";

import { withStyles } from "@material-ui/core";
import contribuinteStyle from "../../../assets/css/layout/contribuinteStyle";
import MenuDashboard from "../../MenuDashboard";

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import theme from "../../../assets/css/theme";
import { movimentosTabChanged } from "../../../actions";
import NewReleases from "@material-ui/icons/NewReleases";
import Description from "@material-ui/icons/Description";
import DeviceHub from "@material-ui/icons/DeviceHub";
import Assignment from "@material-ui/icons/Assignment";
import LancamentoIptu from "./LancamentoIptu";
import IsencaoIptu from "./IsencaoIptu";
import Update from "@material-ui/icons/Update";
import AlterarVencimentoParcela from "./AlterarVencimentoParcela";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ArquivoRemessa from "./ArquivoRemessa";
import ParcelasUnicas from "./ParcelasUnicas";

const tab = [
  {
    name: "Lançamento IPTU",
    icon: <NewReleases />,
  },
  {
    name: "Unificação de parcelas",
    icon: <DeviceHub />,
  },
  {
    name: "Isenção de IPTU",
    icon: <Assignment />,
  },
  {
    name: "Alterar Vencimento de Parcela",
    icon: <Update />,
  },
  {
    name: "Arquivo para Remessa de IPTU",
    icon: <Description />,
  },
];

class MovimentosIptu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      error: "",
      field: 0,
    };
    // console.log("Antes");
    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  handleFieldChange(value) {
    this.props.movimentosTabChanged(value);
  }

  render() {
    const { classes, field } = this.props;
    console.log(field);
    return (
      <div className={classes.container}>
        <MuiThemeProvider theme={theme}>
          <MenuDashboard
            names={tab}
            onChange={this.handleFieldChange}
            value={field}
          />
          {field === 0 && <LancamentoIptu />}
          {field === 1 && <ParcelasUnicas />}
          {field === 2 && <IsencaoIptu />}
          {field === 3 && <AlterarVencimentoParcela />}
          {field === 4 && <ArquivoRemessa />}
        </MuiThemeProvider>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    field: state.tabLayout.movimentosTabSelected,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ movimentosTabChanged }, dispatch);
}

MovimentosIptu = connect(
  mapStateToProps,
  mapDispatchToProps
)(MovimentosIptu);
export default withStyles(contribuinteStyle)(MovimentosIptu);
