import axios from "axios";
import { defaultHeaders } from "./Constants";


export function print(termoId, callBack) {
    let headers = defaultHeaders()
    let config = {
        headers: headers,
        responseType: "blob"
    }
    let url =
        process.env.REACT_APP_API + `termo/${termoId}`
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data);
        })
        .catch(error => {
            callBack(error);
        })
}

export function get(page, callback){
    let config = {
        headers: defaultHeaders()
    }

    const url = process.env.REACT_APP_API+`termo?page=${page ? page : ""}`;
    
    axios.get(url, config)
        .then(response => {
            callback(undefined, response.data);
        })
        .catch(error => {
            callback(error, undefined);
        });
}

export function create(callback){
    let config = {
        headers: defaultHeaders()
    }

    const url = process.env.REACT_APP_API+"termo/create";
    
    axios.get(url, config)
        .then(response => {
            callback(undefined, response.data);
        })
        .catch(error => {
            callback(error, undefined);
        });
}

export function post(params, callback){
    let config = {
        headers: defaultHeaders()
    }

    const url = process.env.REACT_APP_API+"termo";
    
    axios.post(url, params, config)
        .then(response => {
            callback(undefined, response.data);
        })
        .catch(error => {
            callback(error, undefined);
        });
}


export function edit(termoId, callback){
    let config = {
        headers: defaultHeaders()
    }

    const url = process.env.REACT_APP_API+`termo/${termoId}/edit`;
    
    axios.get(url, config)
        .then(response => {
            callback(undefined, response.data);
        })
        .catch(error => {
            callback(error, undefined);
        });
}

export function put(termoId, params, callback){
    let config = {
        headers: defaultHeaders()
    }

    const url = process.env.REACT_APP_API+`termo/${termoId}`;
    
    axios.put(url, params, config)
        .then(response => {
            callback(undefined, response.data);
        })
        .catch(error => {
            callback(error, undefined);
        });
}