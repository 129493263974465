import React from 'react'

import { withStyles } from '@material-ui/core';
import secaoStyle from "../../../assets/css/layout/secaoStyle";

import Button from "../../../components/CustomButtons/Button";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LoadingContent from "../../../components/LoadingContent";

import { simpleSearch as searchContribuinte } from "../../../util/contribuinte"
import AsyncSelect from "../../../components/AsyncSelect"
import SearchFields from "../../../components/SearchFields"
import { showNotification, mapErrors, showWaiting, hideWaiting } from '../../../util/Constants';

import { printPagamentosNegociados } from '../../../util/pagamentoDeDivida';
import moment from 'moment';

class NegociacoesPagamentos extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            // data_inicial: moment().format(
            //     "YYYY-MM-DD"
            // ),
            // data_final: moment().format(
            //     "YYYY-MM-DD"
            // ),
            errors: {}
        }
        this.handleChange = this.handleChange.bind(this)
        this.loadOptions = this.loadOptions.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        let value = event.target.value
    
        if (event.target.type === "checkbox") {
          value = event.target.checked
        }

        this.setState({
            [name] : value
        })
    }

    handleSave(event){
        event.preventDefault()

        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                const reader = new FileReader()
                reader.addEventListener("loadend", e => {
                const text = e.srcElement.result
                try {
                    let error = JSON.parse(text)
                    this.setState({
                    errors: mapErrors({ response: { data: error } })
                    })
                } catch (error) {
                    showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão e tente novamente",
                    "danger"
                    )
                }
                })
                reader.readAsText(error.response.data)
                return;
            }

            var url = URL.createObjectURL(data)
            let tempLink = document.createElement("a")
            tempLink.setAttribute("target", "_blank")
            tempLink.setAttribute("href", `${url}`)
            tempLink.setAttribute("style", "display:none;")
            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
        }

        let contribuinte = this.state.contribuinte_id ? this.state.contribuinte_id.value : "";
        printPagamentosNegociados(contribuinte, this.state.data_inicial, this.state.data_final, response)
    }

    loadOptions(inputValue, callback) {
        searchContribuinte(
          inputValue,
          this.state.filterField || "",
          undefined,
          (error, data) => {
            if (error) {
              showNotification(
                "top",
                "center",
                "Ocorreram erros, verifique sua conexão com a internet",
                "danger"
              )
              callback([])
            } else {
              let contribuintes = data.data.map(contribuinte => {
                return {
                  value: contribuinte.id,
                  label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                    ""} - ${contribuinte.cpf_cnpj || ""}`
                }
              })
              this.setState({
                contribuintes: data.data
              })
              callback(contribuintes)
            }
          }
        )
      }

    render() {
        const { classes } = this.props;
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Código do contribuinte" },
            { value: "cpf_cnpj", label: "Documento" },
            { value: "endereco", label: "Endereço" },
            { value: "nome_fantasia", label: "Nome Fantasia" },
            { value: "nome_razao_social", label: "Razão Social" }
          ]
        return (
            <div className={classes.container}>
                <LoadingContent isLoading={this.state.isLoading}>
                <form onSubmit={this.handleSave}>
                <div>
                    <Card>
                        <CardHeader color="primary">
                        <Grid container direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>
                                    Relatório de Pagamentos Negociados
                                </h2>
                            </Grid>
                        </Grid>
                        </CardHeader>
                        <CardBody>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <SearchFields
                                        name="filterField"
                                        value={this.state.filterField || ""}
                                        onChangeFields={this.handleChange}
                                        fields={fields}
                                    />
                                    <Typography color="textSecondary" variant="caption">
                                        Pesquise o Contribuinte
                                    </Typography>
                                    <AsyncSelect
                                        className={
                                            `form-control form-control-alternative form-select Select-menu-outer ${this.props.error ? "has-danger" : ""}`
                                        }
                                        value={this.state.contribuinte_id}
                                        onChange={this.handleChange}
                                        loadOptions={this.loadOptions}
                                        error={this.state.errors.contribuinte_id}
                                        helperText={this.state.errors.contribuinte_id}
                                        name="contribuinte_id"
                                        placeholder="Contribuinte"
                                        message="Pesquise o contribuinte"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={16}>
                                <Grid item lg={6} xs={12}>
                                    <TextField
                                        id="date"
                                        type="date"
                                        variant="outlined"
                                        margin="normal"
                                        name="data_inicial"
                                        label="Data Inicial"
                                        className={classes.datePicker}
                                        helperText={this.state.errors.data_inicial}
                                        value={this.state.data_inicial}
                                        error={this.state.errors.data_inicial}
                                        defaultValue={this.state.data_inicial}
                                        onChange={this.handleChange}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <TextField
                                        id="date"
                                        type="date"
                                        variant="outlined"
                                        margin="normal"
                                        name="data_final"
                                        label="Data Final"
                                        className={classes.datePicker}
                                        helperText={this.state.errors.data_final}
                                        value={this.state.data_final}
                                        error={this.state.errors.data_final}
                                        defaultValue={this.state.data_final}
                                        onChange={this.handleChange}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            </Grid>
                        </CardBody>
                        <CardFooter>
                            <Grid container justify="center">
                            <Grid item lg={2} xs={12}>
                                <Button block color="info" round onClick={this.handleSave}>
                                    GERAR
                                </Button>
                            </Grid>
                            </Grid>
                        </CardFooter>
                    </Card>
                </div>
                </form>
                </LoadingContent>
            </div>
        )
    }


}

export default withStyles(secaoStyle)(NegociacoesPagamentos);
