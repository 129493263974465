import React from "react";
import { Link, withRouter } from "react-router-dom";
import { withStyles, Paper } from "@material-ui/core";
import logradouroStyle from "../../../assets/css/layout/logradouroStyle";
import Button from "../../../components/CustomButtons/Button";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import FileCopy from "@material-ui/icons/FileCopy"
import numeral from "../../../util/numeral";
import moment from "moment";

import { showNotification, mapErrors, showWaiting,  hideWaiting } from "../../../util/Constants";


import Table from "../../../components/Table/Table";
import TablePagination from "@material-ui/core/TablePagination";

import {detalhaConfirmacao, get as getRemessa, search} from "../../../util/confirmacao";
import LoadingContent from "../../../components/LoadingContent";
import Search from "../../../components/Search";
import housecall from "housecall";
import Close from "@material-ui/icons/Close"


class ConfirmacaoDetalhe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      searchText:"",
      error: "",
      errors: {},
      nome: "",
      pageCount: 0,
      perPage: 0,
      id: 0,
      page: 0,
      modal: false,
      isSearch:false,
      remessas: [],
      confirmacaoDetalhe: [],
      field: 0,
    };
    this.getData = this.getData.bind(this);

    this.queue = housecall({ concurrency: 1, cooldown: 0 });
  }

  componentWillMount() {
    this.getData();
  }


  getData() {
    let response = (error, data) => {
      console.log("Dentro do getData" + data);
      
      if (error) {
        
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexÃÂ£o com a internet",
          "danger"
          )
        } else {
          console.log("Dentro do else do RemessaDetalhe getData")
          //data.remessaDetalhe.id = data.remessaDetalhe.id;
          //data.remessaDetalhe.receita.nome = data.remessaDetalhe.receita.nome;
          //data.remessaDetalhe.contribuinte.nome = data.remessaDetalhe.contribuinte.nome;
          //data.remessaDetalhe.divida.id = data.remessaDetalhe.divida.id;
          //data.remessaDetalhe.status.nome = data.remessaDetalhe.status.nome;
          
          this.setState({

            remessaDetalhe: data

          })
        }
        console.log("Apos o metodo detalhRemessa " + data.contribuinte_id);
        //detalhaRemessa(this.props.match.params.id, response);  
      }
   // console.log("Final do getData");
    //detalhaRemessa(this.props.match.params.id, response);   
  }

  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }

  getData(page) {
    this.toggleLoading()
    detalhaConfirmacao(this.props.match.params.id, (error, data) => {
    this.toggleLoading()
    if (error) {
      showNotification(
        "top",
        "center",
        "Ocorreram erros, verifique o formulÃ¡rio e tente novamente",
        "danger"
      )
    } else {
      console.log("Valor da data: " . data);
      this.setState({
        confirmacaoDetalhe: data,
        
      });
      console.log("Final - apos retorna do back" + this.confirmacaoDetalhe);
    }

        
  });
  }

  
  render() {
    const { classes } = this.props;
    const fields = [];
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}> Detalhe após Confirmação Remessa</h2>
              </Grid>
              <Grid item lg={2} xs={12}>
              
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  tableHeaderColor="primary"
                  
                  tableHead={["Remessa", "Contribuinte", "Receita", "Valor da Divida", "Cartório", "Nº do Protocolo", "Data"]}
                  tableData={this.state.confirmacaoDetalhe.map((item, key) => {
                    let nomeCartorio;
                    let numeroProtocolo;
                    let dataProtocolo;

                    if(item.cartorio === null){
                      nomeCartorio = " - ";
                    }
                    else{
                      nomeCartorio = item.cartorio.nome;
                    }

                    if(item.protocolo === 0){
                      numeroProtocolo = " - ";
                    }
                    else{
                      numeroProtocolo = item.protocolo;
                    }

                    if(item.data_protocolo === null){
                      dataProtocolo = " - ";
                    }
                    else{
                      dataProtocolo = item.data_protocolo;
                    }

                    console.log(item);
                    return [
                      item.remessa_id, 
                      item.contribuinte.nome_razao_social,
                      item.receita.nome,
                      item.divida.valor,
                      nomeCartorio,
                      numeroProtocolo,
                      dataProtocolo,
                      key
                    ];
                  })}
                />
               
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>
      </div>
    );
  }
}

//ConfirmacaoDetalhe = withRouter(ConfirmacaoDetalhe);
export default withStyles(logradouroStyle)(ConfirmacaoDetalhe);
