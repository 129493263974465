import React from "react"
import { useHistory } from "react-router-dom"
import Warning from "@material-ui/icons/Warning"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import moment from "moment"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Button from "../../../components/CustomButtons/Button"
import secaoStyle from "../../../assets/css/layout/secaoStyle"
import { withStyles, Checkbox, Paper } from "@material-ui/core"
import { imprimirPagamento, postSimular, consultarDivida } from "../../../util/pagamentoDeDivida"
import { post, getRemessaDetalhe, updateRemessaDetalhe, getDesistenciaPorContribuinte, desistirTitulo } from "../../../util/desistencia"
import { getField } from "../../../util/refinanciamento"
import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../../util/Constants"
import LoadingContent from "../../../components/LoadingContent"
import { search as searchRemessa } from "../../../util/remessa"
import AsyncSelect from "../../../components/AsyncSelect"
import housecall from "housecall"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { contribuinteChanged } from "../../../actions"
import numeral from "../../../util/numeral"

import SearchIcon from "@material-ui/icons/Search"
import DeleteIcon from "@material-ui/icons/Delete"
import { me } from "../../../util/authApi"
import { simpleSearch as searchContribuinte } from "../../../util/contribuinte"
import SearchFields from "../../../components/SearchFields"
import TextField from "@material-ui/core/TextField"

class DesistenciaForm extends React.Component {
    constructor(props) {
        super(props)
        let remessa = props.remessa
        let remessaState = { remessas: new Array }
        let search = {}
        let dividas = new Array
        if (remessa) {
            // search = {remessa_id: remessa.id, nome: remessa.nome}
            remessaState = {
                remessas: [props.remessa],
                remessa_id: {
                    value: props.remessa.id,
                    label: `${props.remessa.id} - ${props.remessa.nome || ""}`
                }
            }
        }
        this.state = {
            desconto: 0,
            errors: {},
            abragencias: [],
            id_abragencias: [],
            refis: [],
            dividas: dividas,
            dividas_total: [],
            usuario_id: null,
            isLoadingDialog: false,
            modal: false,
            canShow: false,
            parcelasSimulacao: [],
            showWarningModal: false,
            showField: true,
            ...remessaState,
            selectAll: false,
            search: { ...search },
            nao_tem_divida: false,
            // Desistencia
            remessas: [],
            remessas_detalhe: [],
            nome: "",
            valor_minimo: 0,
            remessa_id: 0,
            contribuintes: [],
            contribuintes_remessa: [],
            observacao: ""
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleCloseWarning = this.handleCloseWarning.bind(this)
        this.toggleLoadingDialog = this.toggleLoadingDialog.bind(this)
        this.loadOptions = this.loadOptions.bind(this)
        this.getDividaContribuinte = this.getDividaContribuinte.bind(this)
        this.queue = housecall({ concurrency: 1, cooldown: 0 })
        this.consultar = this.consultar.bind(this)
        this.checkAll = this.checkAll.bind(this)
        this.getDividas = this.getDividas.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.cleanAdvancedSearch = this.cleanAdvancedSearch.bind(this)
    }

    consultar() {
        this.setState({
            showField: false
        }, () => {
            let id = this.state.dividas.map(item => {
                return item.id
            }).join()
            showWaiting()
            consultarDivida(id, (error, data) => {
                hideWaiting()
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros! Tente novamente",
                        "danger"
                    )
                } else {
                    this.setState({
                        valor_primeira_parcela: parseFloat(data.valor_primeira_parcela)
                    }, () => {
                        this.setState({
                            showField: true
                        })
                    })
                }
            })
        })
    }

    handleClose() {
        this.setState({ modal: false })
    }

    handleCloseWarning() {
        this.setState({ showWarningModal: false })
    }

    componentWillUnmount() {
        this.props.contribuinteChanged(undefined)
    }

    componentDidMount() {
        me((error, data) => {
            if (error) {
                return
            }
            this.setState({ usuario_id: data.id })
        })
        getField((error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                if (data.refis.length > 0) {
                    this.setState({
                        showWarningModal: true,
                        canShow: true
                    })
                } else {
                    this.setState({
                        canShow: true
                    })
                }
            }
        })
        // this.getReceitas()
        if (this.state.remessa_id) {
            this.getDividas()
        }
    }

    getDividas() {
        showWaiting()
        console.log(this.state.contribuinte_id);
        getDesistenciaPorContribuinte(this.state.contribuinte_id.value, (error, data) => {
            //this.setState({isLoading:false})
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
            } else {
                console.info(" Voltando do back ");
                // this.setState({
                //   remessas_detalhe : data.data,
                //   pageCount: data.total,
                //   perPage: data.per_page
                // });

                this.setState({
                    remessas_detalhe: data.map((item) => {
                        return { ...item, checkbox: true }
                    }),
                    selectAll: true,
                    nao_tem_divida: data.length === 0

                })
            }
            hideWaiting();

            console.table(this.state.remessas_detalhe);
        });

    }

    loadOptions(inputValue, callback) {
        searchContribuinte(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                    callback([])
                } else {
                    let contribuintes = data.data.map(contribuinte => {
                        return {
                            value: contribuinte.id,
                            label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                                ""} - ${contribuinte.cpf_cnpj || ""}`
                        }
                    })
                    this.setState({
                        contribuintes: data.data
                    })
                    callback(contribuintes)
                }
            }
        )
    }

    toggleLoadingDialog() {
        this.setState(state => ({
            isLoadingDialog: !state.isLoadingDialog
        }))
    }

    handleChangeCheckbox(event) {
        const name = parseInt(event.target.name)
        const checked = event.target.checked
        let newTitulo = this.state.remessas_detalhe
        newTitulo.map((item, index, remessas_detalhe) => {
            if (index === name) {
                item.checkbox = checked
            }
            remessas_detalhe[index] = { ...item }
        })
        this.setState({
            remessas_detalhe: newTitulo
        })
    }
    checkAll(event) {
        let checked = true
        if (event) {
            checked = event.target.checked
        }
        let newTitulo = this.state.remessas_detalhe
        newTitulo.map((item, index, remessas_detalhe) => {
            item.checkbox = checked
            remessas_detalhe[index] = { ...item }
        })
        this.setState({
            remessas_detalhe: newTitulo,
            selectAll: checked
        })
    }

    async handleChange(event) {
        const name = event.target.name
        const errors = this.state.errors
        if (name === "parcelas_iguais") {
            await this.setState({
                [name]: event.target.checked,
                errors: errors
            })
        } else {
            await this.setState({
                [name]: event.target.value,
                errors: errors
            })
        }
    }

    async handleSearchChange(event) {
        const name = event.target.name.replace("search_", "")
        if (event.target.value) {
            await this.setState({
                search: { ...this.state.search, [name]: event.target.value }
            })
        } else {
            delete this.state.search[name]
            await this.setState({
                search: { ...this.state.search }
            })
        }
    }

    async cleanAdvancedSearch() {
        await this.setState({
            search: {},
            remessa_id: null,
            dividas: [],
            remessas_detalhe: [],
            contribuintes: []
        })
        // this.advancedSearch()
    }

    getDividaContribuinte(id) {
        return this.state.contribuintes.find(item => {
            return id === item.id
        })
    }

    handleSave(event) {
        event.preventDefault()

        if (this.state.remessas_detalhe) {
            console.log("Dentro do if");
            showWaiting()
            let filter = this.state.remessas_detalhe.filter(item => {
                return item.checkbox === true
            })
            let dividas = filter.map(item => {
                return item.id
            })

            let params = {
                dividas: dividas,
                observacao: this.state.observacao
                //remessa_detalhe: this.state.remessa_detalhe.value
            }

            let response = (error, data) => {
                if (error) {
                    hideWaiting()
                    let message = "";
                    message = error.response.data.message;
                    showNotification(
                        "top",
                        "center",
                        `Ocorreram erros! ${message}`,
                        "danger"
                    )
                    // this.setState({
                    //     errors: mapErrors(error)
                    // })
                } else {
                    hideWaiting()
                    let message = "";
                    message = data.message;
                    showNotification(
                        "top",
                        "center",
                        `${message}`,
                        "success"
                    )
                    this.props.history.push("/protesto/desistencia")
                    // this.history.push('/protesto');
                    // this.imprimirCarneRef(data.id)
                }
            }
            //updateRemessaDetalhe(params, response)
            desistirTitulo(params, response)
        }

    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    render() {

        const { classes } = this.props
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Código do contribuinte" },
            { value: "cpf_cnpj", label: "Documento" },
            { value: "endereco", label: "Endereço" },
            { value: "nome_fantasia", label: "Nome Fantasia" },
            { value: "nome_razao_social", label: "Razão Social" }
        ]
        return (
            <div className={classes.container}>
                <LoadingContent isLoading={this.state.isLoading}>
                    <div>
                        <LoadingContent isLoading={this.state.canShow === false}>
                            <Card>
                                <form onSubmit={this.handleSave}>
                                    <CardHeader color="primary">
                                        <Grid container direction="row">
                                            <Grid item lg={10} xs={12}>
                                                <h2 className={classes.cardTitleWhite}>
                                                    Desistência de títulos
                                                </h2>
                                            </Grid>
                                        </Grid>
                                    </CardHeader>
                                    <CardBody>
                                        <Grid container direction="row" spacing={16}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <SearchFields
                                                    name="filterField"
                                                    value={this.state.filterField || ""}
                                                    onChangeFields={this.handleChange}
                                                    fields={fields}
                                                />
                                                <Typography color="textSecondary" variant="caption">
                                                    Pesquise o Contribuinte
                                                </Typography>
                                                <AsyncSelect
                                                    className={
                                                        `form-control form-control-alternative form-select Select-menu-outer ${this.props.error ? "has-danger" : ""
                                                        }`
                                                    }
                                                    value={this.state.contribuinte_id}
                                                    onChange={this.handleChange}
                                                    loadOptions={this.loadOptions}
                                                    error={this.state.errors.contribuinte_id}
                                                    helperText={this.state.errors.contribuinte_id}
                                                    name="contribuinte_id"
                                                    placeholder="Contribuinte"
                                                    message="Pesquise o contribuinte"
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container direction="row" spacing={16} justify={"flex-end"}>
                                            <Grid item xs={12} sm={4} md={3} lg={2}>
                                                <Button block color="danger" fullWidth onClick={this.cleanAdvancedSearch}>
                                                    <DeleteIcon /> Limpar Busca
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={3} lg={2}>
                                                <Button block color="primary" fullWidth onClick={this.getDividas}>
                                                    <SearchIcon /> Buscar
                                                </Button>
                                            </Grid>
                                        </Grid>

                                        {this.state.remessas_detalhe.length > 0 ? (
                                            <div>
                                                <Typography color="textSecondary" variant="caption">
                                                    Selecione os títulos que serão incluídos na desistência
                                                </Typography>
                                                <div>Selecione todos</div>
                                                <Grid item xs={12} sm={12} md={1} lg={1} alignItems="center"
                                                    style={{ display: "inline-block" }} justify="center">
                                                    <Checkbox
                                                        icon={
                                                            <CheckBoxOutlineBlankIcon fontSize="large" />
                                                        }
                                                        checkedIcon={
                                                            <CheckBoxIcon fontSize="large" />
                                                        }

                                                        onChange={this.checkAll}
                                                        checked={this.state.selectAll}
                                                        name={"Selecionar todos"}
                                                    />
                                                </Grid>
                                                {this.state.remessas_detalhe.map((item, key) => {
                                                    if (item.status_id === 2) {
                                                        return (
                                                            <div>
                                                                <Card>
                                                                    <Grid
                                                                        style={{ marginBottom: "15px" }}
                                                                        container
                                                                        direction="row"
                                                                        justify="flex-center"
                                                                        alignItems="center"
                                                                        spacing={8}
                                                                        key={key}
                                                                    >
                                                                        <Grid className={classes.flexInherit} item xs={12}
                                                                            sm={12} md={1} lg={1} alignItems="center"
                                                                            justify="center">
                                                                            <Checkbox
                                                                                icon={
                                                                                    <CheckBoxOutlineBlankIcon
                                                                                        fontSize="large" />
                                                                                }
                                                                                checkedIcon={
                                                                                    <CheckBoxIcon fontSize="large" />
                                                                                }
                                                                                onChange={this.handleChangeCheckbox}
                                                                                value={item.checkbox}
                                                                                checked={item.checkbox}
                                                                                name={key}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12} md={3} lg={8}>
                                                                            <br />
                                                                            <strong>Remessa: </strong><span>{item.remessa.id} - {item.remessa.nome} </span><br />
                                                                            <strong>Receita: </strong><span>{item.receita_id} - {item.receita.nome} </span><br />
                                                                            <strong>Contribuinte: </strong><span>{item.contribuinte.nome_razao_social}  </span>
                                                                            <br />
                                                                            <strong>Data de inscrição: </strong><span>{moment(item.data_inscricao).format('DD/MM/YYYY')}  </span>
                                                                            <strong>Protocolo: </strong><span>{item.protocolo} </span><br />
                                                                            <strong>Cartorio: </strong><span>{item.cartorio.nome} </span><br />
                                                                        </Grid>


                                                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                                                            <br />
                                                                            <h3>Total a
                                                                                pagar: {"R$ " + numeral(parseFloat(item.total)).format("0.0,00")
                                                                                    .slice(0, -2)}</h3>
                                                                        </Grid>

                                                                    </Grid>
                                                                </Card>
                                                                {this.state.errors && (
                                                                    <Typography variant="caption"
                                                                        color="error">{this.state.errors[`dividas.${key}`]}</Typography>
                                                                )}
                                                            </div>
                                                        )
                                                    }
                                                })}

                                                <Grid container direction="row" spacing={16}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <TextField
                                                            label="Justificativa"
                                                            name="observacao"
                                                            value={this.state.observacao}
                                                            error={this.state.errors.observacao}
                                                            helperText={this.state.errors.observacao}
                                                            onChange={this.handleChange}
                                                            fullWidth
                                                            multiline
                                                            rows="4"
                                                            margin="normal"
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </div>
                                        ) : (this.state.nao_tem_divida && (
                                            <Grid className={classes.legend} container direction="row"><Warning
                                                className={classes.dot + " " + classes.dotDanger} />
                                                Este contribuinte não possui titulos para a desistencia</Grid>))}
                                    </CardBody>

                                    <CardFooter>
                                        <Grid container justify="center" spacing={8}>
                                            <Grid item lg={2}>
                                                <Button block color="info" round onClick={this.handleSave}>
                                                    Confirmar Desistência
                                                </Button>
                                            </Grid>


                                        </Grid>
                                    </CardFooter>
                                </form>
                            </Card>
                        </LoadingContent>
                    </div>
                </LoadingContent>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ contribuinteChanged }, dispatch)
}

function mapStateToProps(state) {
    return {
        contribuinte: state.contribuinte.contribuinte
    }
}

DesistenciaForm = connect(mapStateToProps, mapDispatchToProps)(DesistenciaForm)
export default withStyles(secaoStyle)(DesistenciaForm)
